import { forwardRef } from "react"
import { Stack } from "@mui/material"

/**
 * Creates a horizontal flex box set to the width of its parent with items aligned centrally, useful for all lists
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{readonly children?: *}> &
 *     React.RefAttributes<unknown>>}
 */
export const ListItemBox = forwardRef(function ListItemBox({ children, ...props }, ref) {
    return (
        <Stack
            direction="row"
            spacing={0}
            ref={ref}
            alignItems="center"
            {...props}
            sx={{ lineHeight: 1.43, width: 1, ...props.sx }}
        >
            {children}
        </Stack>
    )
})
