import { Box, Link, Stack, Typography } from "@mui/material"

import AuthTemplate from "minimals-template/pages/auth/AuthTemplate"

import { RouterLink } from "lib/routes/router-link"
import useAuth from "minimals-template/components/@hooks/useAuth"
import { useLocation } from "react-router"
import { useDemand } from "lib/authorization/use-demand"
import { useTimedLoadingScreen } from "lib/@hooks/use-timed-loading-screen"
import LoadingScreen from "minimals-template/components/LoadingScreen"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import Paywall from "plugins/Paywall/Paywall"
import { WhoAmI } from "minimals-template/components/nav-section/vertical/who-am-i"

export function NoLicense({ children }) {
    const { logout } = useAuth()
    const { dontNeedLicense } = useBoundContext()
    const licensed = useDemand("licensed")
    const { clients } = useBoundContext()
    const user = useDemand("user")
    const viewer = useDemand("viewer")
    const sharing = useDemand("$sharing")
    const { pathname } = useLocation()

    const loading = useTimedLoadingScreen(1000)

    if (loading) {
        return <LoadingScreen description="No License" />
    }
    if (pathname.includes("logout")) {
        return <>{children}</>
    }

    if ((dontNeedLicense && user) || licensed || (sharing && viewer)) {
        return <>{children}</>
    }

    return (
        <AuthTemplate
            contentStyleOverrides={{
                maxWidth: "856px",
                paddingBottom: 0,
                paddingTop: "50px",
                position: "relative",
            }}
            headerComponent={
                <Typography variant="body2" sx={{ mt: { md: -2 } }}>
                    <Link variant="subtitle2" component={RouterLink} onClick={logout}>
                        Logout
                    </Link>
                </Typography>
            }
            pageTitle={"Unlicensed"}
        >
            {clients?.length > 1 && (
                <Box sx={{ mb: 4 }}>
                    <Stack
                        sx={{ position: "absolute", mb: 3, mr: "auto", ml: -2, mt: 2, left: 0, top: 0, zIndex: 10 }}
                        direction="row"
                    >
                        <WhoAmI />
                    </Stack>
                </Box>
            )}

            <Paywall />
        </AuthTemplate>
    )
}
