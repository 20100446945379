import useResponsive from "minimals-template/components/@hooks/useResponsive"
import { ToolbarUser } from "routes/@layouts/app/app-layout"
import { Box, Stack, Toolbar, useTheme } from "@mui/material"
import { HEADER } from "config"
import { isMobileApp } from "routes/@layouts/app/header/is-mobile-app"
import Logo from "minimals-template/components/Logo"
import { IconButtonAnimate } from "minimals-template/components/animate"
import Iconify from "minimals-template/components/Iconify"
import { NotOnSharing } from "lib/@components/sharing/not-sharing"
import { DashboardHeaderLeft, HeaderBarRight } from "slot-definitions"

export function AppDashboardHeader({ onOpenSidebar, verticalLayout = false }) {
    const isDesktop = useResponsive("up", "lg")
    const theme = useTheme()
    return (
        <>
            <ToolbarUser>
                <Box
                    sx={{
                        position: "fixed",
                        transform: "translate3d(0,0,0)",
                        background: theme.palette.background.paper + "ee",
                        zIndex: 200,
                        "-webkit-backdrop-filter": "blur(5px)",
                        backdropFilter: "blur(5px)",
                        left: 0,
                        top: 0,
                        right: 0,
                        height: HEADER.MOBILE_HEIGHT + (isMobileApp() ? 48 : 0),
                    }}
                >
                    {isMobileApp() && <Box height={32} />}
                    <Toolbar
                        sx={{
                            minHeight: "100% !important",
                            px: { lg: 5 },
                        }}
                    >
                        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}
                        {!isDesktop && (
                            <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: "text.primary" }}>
                                <Iconify icon="eva:menu-2-fill" />
                            </IconButtonAnimate>
                        )}

                        <NotOnSharing>
                            <DashboardHeaderLeft.Slot />
                        </NotOnSharing>
                        <Box sx={{ flexGrow: 1 }} />
                        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
                            <HeaderBarRight.Slot />
                        </Stack>
                    </Toolbar>
                </Box>
            </ToolbarUser>
        </>
    )
}
