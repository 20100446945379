// @mui
import { styled } from "@mui/material/styles"
import { Container, Divider, Grid, Link, Stack, Typography } from "@mui/material"
// routes
// components
import Logo from "minimals-template/components/Logo"
import SocialsButton from "minimals-template/components/SocialsButton"
import { RouterLink } from "lib/routes/router-link"

// ----------------------------------------------------------------------

const LINKS = []

const RootStyle = styled("div")(({ theme }) => ({
    position: "relative",
    backgroundColor: theme.palette.background.default,
}))

// ----------------------------------------------------------------------

export default function MainFooter() {
    return (
        <RootStyle>
            <Divider />

            <Container sx={{ pt: 10 }}>
                <Grid
                    container
                    justifyContent={{ xs: "center", md: "space-between" }}
                    sx={{ textAlign: { xs: "center", md: "left" } }}
                >
                    <Grid item xs={12} sx={{ mb: 3 }}>
                        <Logo sx={{ mx: { xs: "auto", md: "inherit" } }} />
                    </Grid>

                    <Grid item xs={8} md={3}>
                        <Typography variant="body2" sx={{ pr: { md: 5 } }}>
                            The industry standard for Planned Preventative Maintenance
                        </Typography>

                        <Stack
                            direction="row"
                            justifyContent={{ xs: "center", md: "flex-start" }}
                            sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
                        >
                            <SocialsButton sx={{ mx: 0.5 }} />
                        </Stack>
                    </Grid>

                    <Grid item xs={12} md={7}>
                        <Stack spacing={5} direction={{ xs: "column", md: "row" }} justifyContent="space-between">
                            {LINKS.map((list) => (
                                <Stack key={list.headline} spacing={2}>
                                    <Typography component="p" variant="overline">
                                        {list.headline}
                                    </Typography>

                                    {list.children.map((link) => (
                                        <Link
                                            to={link.href}
                                            key={link.name}
                                            color="inherit"
                                            variant="body2"
                                            component={RouterLink}
                                            sx={{ display: "block" }}
                                        >
                                            {link.name}
                                        </Link>
                                    ))}
                                </Stack>
                            ))}
                        </Stack>
                    </Grid>
                </Grid>

                <Typography
                    component="p"
                    variant="body2"
                    sx={{
                        mt: 10,
                        pb: 5,
                        fontSize: 13,
                        textAlign: { xs: "center", md: "left" },
                    }}
                >
                    © BESA Publications 2022
                </Typography>
            </Container>
        </RootStyle>
    )
}
