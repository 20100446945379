import { styled } from "@mui/material/styles"
import { Box, Container, Typography } from "@mui/material"

import Page from "minimals-template/components/Page"
import Image from "minimals-template/components/Image"
import useResponsive from "minimals-template/components/@hooks/useResponsive"
import { ListItemBox } from "lib/@components/ListItemBox"
import logo from "routes/@assets/PRIMARY LOGO _ LOCKUP _ REVERSED@3x.png"
import { AuthTemplateHeader } from "slot-definitions"

const RootStyle = styled("div")(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
        display: "flex",
    },
}))

const HeaderStyle = styled("header")(({ theme }) => ({
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: "100%",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    padding: theme.spacing(3),
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
        alignItems: "flex-start",
        padding: theme.spacing(7, 5, 0, 7),
    },
}))

const SectionStyle = styled(Box)(({ theme }) => ({
    width: "100%",
    height: "100vh",
    position: "fixed",
    px: 16,
    maxWidth: 464,
    backgroundColor: "#fff",
    backgroundImage: "url(/assets/overlay.svg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: theme.spacing(0, 0, 0, 0),
}))
const ContentContainer = styled(Container)(({ theme }) => ({
    width: "100%",
    minHeight: "100vh",
    [theme.breakpoints.up("md")]: {
        marginLeft: 464,
        marginRight: 0,
    },
}))
const ContentStyle = styled("div")(({ theme }) => ({
    maxWidth: 480,
    margin: "auto",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(12, 0),
}))

// ----------------------------------------------------------------------

export default function AuthTemplate({
    pageTitle,
    pageImage = logo,
    pageInformation,
    headerComponent,
    footerComponent,
    contentStyleOverrides = {},
    children,
}) {
    const smUp = useResponsive("up", "sm")
    const mdUp = useResponsive("up", "md")

    return (
        <Page title={pageTitle}>
            <RootStyle>
                <HeaderStyle>
                    {/*<Logo href="/" />*/}
                    <ListItemBox>
                        <AuthTemplateHeader.Slot>
                            <Box flex={1} />
                            {smUp && headerComponent && headerComponent}
                        </AuthTemplateHeader.Slot>
                    </ListItemBox>
                </HeaderStyle>
                {mdUp && (
                    <SectionStyle>
                        {pageInformation && (
                            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                                {pageInformation}
                            </Typography>
                        )}
                        {typeof pageImage === "string" ? (
                            <Image
                                sx={{ px: 8 }}
                                contains
                                visibleByDefault
                                disabledEffect
                                alt={pageTitle}
                                src={pageImage}
                            />
                        ) : (
                            pageImage
                        )}
                    </SectionStyle>
                )}
                <ContentContainer maxWidth={false}>
                    <ContentStyle style={contentStyleOverrides}>
                        {children}

                        {footerComponent && footerComponent}

                        {!smUp && headerComponent && headerComponent}
                    </ContentStyle>
                </ContentContainer>
            </RootStyle>
        </Page>
    )
}
