import { useNavigate } from "react-router-dom"
import { useSnackbar } from "notistack"
import { HardNavigate, Navigate, ShowSnackBar } from "event-definitions"
import { adjustPath } from "lib/routes/adjust-path"
import { isBusyVisible } from "lib/@components/busy"
import { omit } from "lib/omit"

export function Navigator() {
    const navigate = useNavigate()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    Navigate.useEvent((to, ...params) => {
        to = adjustPath(to)

        navigate(to, ...params)

        return false
    })
    HardNavigate.useEvent((to, ...params) => {
        navigate(to, ...params)
        return false
    })
    ShowSnackBar.useEvent((message, options) => {
        if (isBusyVisible()) {
            setTimeout(() => {
                ShowSnackBar.raise(message, options)
            }, 300)
        } else {
            const { forceClose = false, forceCloseDuration = 3000 } = options
            const snackbarKey = enqueueSnackbar(message, omit(options, "forceClose", "forceCloseDuration"))
            if (forceClose) {
                setTimeout(() => {
                    closeSnackbar(snackbarKey)
                }, forceCloseDuration)
            }
        }
    })
    return null
}
