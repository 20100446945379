import { useCallback, useRef, useState } from "react"
import { Outlet } from "react-router-dom"
// @mui
import { Box } from "@mui/material"
// hooks
// config
//
import { ErrorBoundary } from "ErrorBoundary"
import DashboardHeader from "routes/@layouts/app/header"
import NavbarVertical from "routes/@layouts/app/navbar/NavbarVertical"
import { AfterMainStyle, AfterRoute, BeforeMainStyle, BeforeRoute, MainStyleStart } from "slot-definitions"
import { Bound } from "lib/@components/binding/Bound"
import useCollapseDrawer from "minimals-template/components/@hooks/useCollapseDrawer"
import { MainStyle } from "routes/@layouts/app/main-style"

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function DashboardLayout() {
    const { collapseClick } = useCollapseDrawer()

    const [open, setOpen] = useState(false)
    const openSidebar = useCallback(() => setOpen(true), [])
    const closeSidebar = useCallback(() => setOpen(false), [])
    const ref = useRef()

    /* on or off */

    return (
        <Bound openSidebar={openSidebar} closeSidebar={openSidebar}>
            <Box ref={ref}>
                <Box
                    sx={{
                        display: { lg: "flex" },
                        minHeight: { lg: 1 },
                    }}
                >
                    <DashboardHeader onOpenSidebar={openSidebar} />
                    <NavbarVertical isOpenSidebar={open} onCloseSidebar={closeSidebar} />
                    <BeforeMainStyle.Slot />
                    <MainStyle collapseClick={collapseClick}>
                        <MainStyleStart.Slot />
                        <Box sx={{ display: "flex", flexDirection: "row", overflow: "auto", height: "100%" }}>
                            <BeforeRoute.Slot />
                            <ErrorBoundary>
                                <Outlet />
                            </ErrorBoundary>
                            <AfterRoute.Slot />
                        </Box>
                    </MainStyle>
                    <AfterMainStyle.Slot />
                </Box>
            </Box>
        </Bound>
    )
}
