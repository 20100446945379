import { useCallback, useState } from "react"

export function useYupContext() {
    const [yupContext] = useState({})
    const validateAllFields = useCallback(_validateAllFields, [yupContext])
    return [yupContext, validateAllFields]

    function _validateAllFields() {
        return Object.values(yupContext)
            .map((validate) => validate())
            .every((c) => !c)
    }
}
