import { Button } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { prevent } from "lib/prevent"
import { useState } from "react"
import { errorSnackbar } from "lib/snackbar/error-snackbar"

export function DownloadPDFButton({ onDownload, filename }) {
    const [downloading, setDownloading] = useState(false)
    return (
        <Button
            fullWidth
            size="small"
            onClick={prevent(handlePdfDownload)}
            startIcon={<Iconify icon="ic:round-download" />}
            endIcon={downloading && <Iconify icon="ic:round-hourglass-empty" className="iconSpin" />}
            disabled={downloading}
            data-cy="download-pdf-button"
        >
            Download PDF
        </Button>
    )

    async function handlePdfDownload() {
        try {
            setDownloading(true)
            const pdfString = await onDownload()
            downloadPDFObject(pdfString, filename)
        } catch (e) {
            errorSnackbar("Error downloading PDF")
        } finally {
            setDownloading(false)
        }
    }

    function downloadPDFObject(data, filename = "file.pdf") {
        const dlAnchorElem = document.createElement("a")
        dlAnchorElem.setAttribute("href", data)
        dlAnchorElem.setAttribute("download", filename)
        dlAnchorElem.click()
    }
}
