import { useSelection } from "routes/schedule/lib/useSelection"
import { SelectableChanged } from "event-definitions"

export function useSelectedId(id) {
    const [isSelected, select] = useSelection()
    return [
        isSelected(id),
        (value) => {
            if (value === undefined) {
                select(id, !isSelected(id))
            } else {
                select(id, value)
            }
            SelectableChanged.raise()
        },
        isSelected.absolute(id),
    ]
}
