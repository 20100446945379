import { createRange } from "lib/@components/editable/createRange"

export function setCurrentCursorPosition(element, chars) {
    if (chars >= 0) {
        const selection = window.getSelection()

        const range = createRange(element, { count: chars })

        if (range) {
            range.collapse(false)
            selection.removeAllRanges()
            selection.addRange(range)
        }
    }
}
