import { m } from "framer-motion"
// @mui
import { Box } from "@mui/material"
import { alpha } from "@mui/material/styles"
//
import { ReactComponent as FacilitiesLogo } from "routes/@assets/Icon - Facilities-iQ.svg"
import { useLayoutEffect } from "react"
import { BusyAdd, BusyRemoved, CaptureNow } from "event-definitions"
import { generate } from "library/guid"
import { Delayed } from "lib/@components/delayed"
import noop from "lib/noop"

// eslint-disable-next-line no-unused-vars
export default function LoadingScreen({ description, timeout, afterTimeout = noop, capture }) {
    useLayoutEffect(() => {
        const id = generate()
        const timerId = timeout ? setTimeout(afterTimeout, timeout) : undefined
        if (capture) {
            CaptureNow.raise()
        }
        BusyAdd.raise({ id, description })
        return () => {
            clearTimeout(timerId)
            return setTimeout(() => BusyRemoved.raise({ id }), 100)
        }
    }, [description, timeout, afterTimeout, capture])
    return null
}

export function RealLoadingScreen({ isDashboard, children, opacity = 0.5, ...other }) {
    return (
        <>
            {!isDashboard && (
                <Box
                    sx={{
                        right: 0,
                        bottom: 0,
                        zIndex: 99999,
                        "& g": {
                            zIndex: 100000000,
                        },
                        width: "100%",
                        height: "100%",
                        transition: "transform linear 0.5s, background-color linear 0.5s",
                        position: "fixed",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    {...other}
                >
                    <m.div
                        animate={{
                            scale: [1, 1.2, 1, 0.8, 0.8, 1, 1],
                            opacity: [1, 1, 1, 0.78, 0.78, 1, 1],
                            rotate: [360, 360, 360, 360, 0, 0, 0, 360],
                        }}
                        style={{
                            transformOrigin: "50% 49%",
                            overflow: "hidden",
                            height: 60,
                            width: 60,
                        }}
                        transition={{
                            zIndex: 10000000,
                            duration: 2,
                            ease: "easeInOut",
                            repeatDelay: 1,
                            repeat: Infinity,
                        }}
                    >
                        <FacilitiesLogo style={{ width: 60, zIndex: 151000 }} />
                    </m.div>

                    <Box
                        component={m.div}
                        animate={{
                            scale: [1.2, 1, 1, 1.2, 1.2],
                            rotate: [270, 0, 0, 270, 270],
                            opacity: [0.25, 1, 1, 1, 0.25],
                            borderRadius: ["25%", "25%", "50%", "50%", "25%"],
                        }}
                        transition={{ ease: "linear", duration: 3.2, repeat: Infinity }}
                        sx={{
                            zIndex: 10000000,
                            width: 100,
                            height: 100,
                            borderRadius: "25%",
                            position: "absolute",
                            border: (theme) => `solid 3px ${alpha(theme.palette.primary.dark, opacity)}`,
                        }}
                    />

                    <Box
                        component={m.div}
                        animate={{
                            scale: [1, 1.2, 1.2, 1, 1],
                            rotate: [0, 270, 270, 0, 0],
                            opacity: [1, 0.25, 0.25, 0.25, 1],
                            borderRadius: ["25%", "25%", "50%", "50%", "25%"],
                        }}
                        transition={{
                            ease: "linear",
                            duration: 3.2,
                            repeat: Infinity,
                        }}
                        sx={{
                            zIndex: 10000000,
                            width: 120,
                            height: 120,
                            borderRadius: "25%",
                            position: "absolute",
                            border: (theme) => `solid 8px ${alpha(theme.palette.primary.dark, opacity)}`,
                        }}
                    />
                </Box>
            )}

            <Box
                className="loadingScreen"
                sx={{ position: "fixed", zIndex: 1000000, bottom: 80, transform: "translateX(-50%)", left: "50%" }}
            >
                <Delayed>{children}</Delayed>
            </Box>
        </>
    )
}
