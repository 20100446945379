/**
 * A function that does nothing, a useful default
 * @returns {function} the noop function itself
 */
export function noop() {
    return noop
}

export function identity(v) {
    return v
}

export default noop
