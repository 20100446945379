import { useState } from "react"
import { Box, Tooltip } from "@mui/material"

/**
 * TruncatedTooltip is an alternative to Tooltip that truncates its popup text
 * Only truncates if text overflows its parent element
 * On mouse-hover, the tooltip popup extends to reveal the entire text
 *
 * A truncating css-style is added/removed depending on mouse position
 * **/
export function TruncatedTooltip({ children, sx = {}, title = "", ...props }) {
    const [hover, setHover] = useState(false)

    const truncatedStyle = {
        maxWidth: "200px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    }

    return (
        <Tooltip
            sx={{ ...sx }}
            title={
                <Box
                    data-cy="truncated-tooltip-title"
                    sx={hover ? undefined : truncatedStyle}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                >
                    {title}
                </Box>
            }
            {...props}
        >
            {children}
        </Tooltip>
    )
}
