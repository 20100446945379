import { BoundTextField } from "lib/@components/binding/bound-components"
import { Controller, useFormContext } from "react-hook-form"

export default function RHFTextField({ name, ...other }) {
    const { control } = useFormContext()

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <BoundTextField
                    {...field}
                    fullWidth
                    transformIn={(v) => (typeof v === "number" && v === 0 ? "" : v)}
                    error={!!error}
                    helperText={error?.message}
                    field={name}
                    {...other}
                    inputRef={ref}
                />
            )}
        />
    )
}
