import { Alert, Typography } from "@mui/material"

export function NotAuthorised() {
    return (
        <Alert severity="warning">
            You aren&apos;t authorised to do this
            <br />
            <Typography variant="caption">
                If you believe that you should be able to perform this action, please contact the person who administers
                your SFG20 system.
            </Typography>
        </Alert>
    )
}
