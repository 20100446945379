import { styled } from "@mui/material/styles"
import { HEADER, NAVBAR } from "config"
import { isMobileApp } from "routes/@layouts/app/header/is-mobile-app"

export const MainStyle = styled("main", {
    shouldForwardProp: (prop) => prop !== "collapseClick",
})(({ collapseClick, theme }) => ({
    flexGrow: 1,
    paddingTop: HEADER.MOBILE_HEIGHT + (isMobileApp() ? 60 : 0),
    paddingBottom: HEADER.MOBILE_HEIGHT,
    display: "flex",
    flex: 1,

    height: "100vh",
    overflow: "auto",
    flexDirection: "column",
    [theme.breakpoints.up("lg")]: {
        paddingLeft: 16,
        paddingRight: 16,

        paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT,
        paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT,
        width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
        transition: theme.transitions.create("margin-left", {
            duration: theme.transitions.duration.shorter,
        }),
        ...(collapseClick && {
            marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
        }),
    },
}))
