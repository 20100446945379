import { useContext } from "react"
import { Box, Button, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"
import { OkCancelContext } from "lib/@hooks/useDialog"

export function TagLimitAlert() {
    const okCancel = useContext(OkCancelContext)
    const { ok } = okCancel

    return (
        <>
            <DialogTitle>Unable to create new tag</DialogTitle>
            <DialogContent data-cy="tag-limit-alert">
                <Box sx={{ mt: 2 }}>
                    <Typography variant="body2">
                        You have reached the tag limit, please remove an existing tag before creating a new one.
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => ok(null)} color="primary" variant="contained">
                    Ok
                </Button>
            </DialogActions>
        </>
    )
}
