import PropTypes from "prop-types"
//
import SettingsDrawer from "minimals-template/components/settings/drawer"
//
import ThemeContrast from "minimals-template/components/settings/ThemeContrast"
import ThemeRtlLayout from "minimals-template/components/settings/ThemeRtlLayout"
import ThemeColorPresets from "minimals-template/components/settings/ThemeColorPresets"
import ThemeLocalization from "minimals-template/components/settings/ThemeLocalization"
import { useTheme } from "@mui/material"

// ----------------------------------------------------------------------

ThemeSettings.propTypes = {
    children: PropTypes.node.isRequired,
}

export default function ThemeSettings({ children }) {
    return (
        <ThemeColorPresets>
            <ThemeContrast>
                <ThemeLocalization>
                    <ThemeRtlLayout>
                        {children}
                        <SettingsDrawer />
                        <ThemeCapture />
                    </ThemeRtlLayout>
                </ThemeLocalization>
            </ThemeContrast>
        </ThemeColorPresets>
    )
}

function ThemeCapture() {
    window._theme = useTheme()
    return null
}
