import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"
import { mutate } from "lib/graphql/mutate"
import { query } from "lib/graphql/query"

export const getCurrentTermsAndConditions = api(async function getCurrentTermsAndConditions() {
    return query(
        gql`
            query getCurrentTermsAndConditions {
                getCurrentTermsAndConditions
            }
        `,
        {},
        { returns: "getCurrentTermsAndConditions" }
    )
})

export const getUpdatedTermsAndConditionsHash = api(async function getUpdatedTermsAndConditionsHash() {
    return query(
        gql`
            query getUpdatedTermsAndConditionsHash {
                getUpdatedTermsAndConditionsHash
            }
        `,
        {},
        { returns: "getUpdatedTermsAndConditionsHash" }
    )
})

export const confirmTermsAndConditionsAgreed = api(function confirmTermsAndConditionsAgreed(tcVersionHash) {
    return mutate(
        gql`
            mutation confirmTermsAndConditionsAgreed($tcVersionHash: String) {
                confirmTermsAndConditionsAgreed(tcVersionHash: $tcVersionHash)
            }
        `,
        { tcVersionHash },
        { returns: "confirmTermsAndConditionsAgreed" }
    )
})
