import { useEffect, useState } from "react"

export function useTimedLoadingScreen(time = 1000) {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false) // Set loading to false after 1 second
        }, time)

        return () => clearTimeout(timer) // Cleanup timer
    }, [time])

    return loading
}
