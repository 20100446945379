import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export const getSharingLink = api(async function getSharingLink(id) {
    return !id
        ? Promise.resolve(null)
        : query(
              gql`
                  query GetSharingLink($id: String!) {
                      getSharingLink(id: $id) {
                          _id
                          canDownloadExcel
                          canDownloadXml
                          canDownloadPDF
                          canDownloadSchedulePDF
                          canShareOnwards
                          classifications
                          client
                          creator
                          demands
                          description
                          enabled
                          endDate
                          groups
                          name
                          regime
                          rules
                          skills
                          startDate
                          unchecked
                          url
                      }
                  }
              `,
              { id },
              { returns: "getSharingLink" }
          )
})
