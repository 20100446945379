import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"

export const getCurrentUserClients = api(async function getCurrentUserClients() {
    return query(
        gql`
            query getCurrentUserClients {
                getCurrentUserClients {
                    city
                    id
                    name
                    photoURL
                    code
                    displayName
                    license
                }
            }
        `,
        {},
        { returns: "getCurrentUserClients" }
    )
})

export function useCurrentUserClients() {
    const userClients = getCurrentUserClients.useResults()
    return { loading: !userClients, error: null, userClients }
}
