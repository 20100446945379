import { generate } from "library/guid"

export function replaceAllWithPredicate(predicate, something, priority = 1000) {
    replacer.priority = priority
    replacer.$id = generate()
    predicate ??= () => true
    if (typeof predicate === "object") {
        if (typeof something === "function") {
            ;[something, predicate] = [predicate, something]
            console.trace("Wrong predicate")
        } else {
            something = predicate
            predicate = () => true
            console.trace("Wrong predicate")
        }
    }
    return replacer

    function replacer({ items, context }) {
        if (something?.props?.predicate) {
            if (!something.props.predicate(context)) {
                return
            }
        }
        for (let i = items.length - 1; i >= 0; i--) {
            if (predicate(items[i], context)) {
                items[i] = something
            }
        }
    }
}
