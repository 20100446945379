import React, { Fragment } from "react"
import { Bound } from "lib/@components/binding/Bound"
import * as Mui from "@mui/material"
import {
    BoundAutocomplete,
    BoundStandardSwitch,
    BoundSwitch,
    BoundTextField,
    BoundToggleButtonGroup,
} from "lib/@components/binding/bound-components"
import { useRefresh } from "lib/@hooks/useRefresh"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { bind } from "lib/@components/binding/bind"
import { handle, raise } from "library/local-events"
import { BoundTextEditor } from "minimals-template/components/editor"
import { navigate } from "lib/routes/navigate"

export const Framework = {
    React,
    c: React.createElement,
    Bound,
    useBoundContext,
    Mui,
    bind,
    BoundAutocomplete,
    BoundSwitch,
    BoundTextField,
    BoundTextEditor,
    BoundStandardSwitch,
    BoundToggleButtonGroup,
    Fragment,
    Debugger,
    useRefresh,
    raise,
    handle,
    navigate,
}

function Debugger({ children }) {
    return <>{children}</>
}

window.F = Framework
