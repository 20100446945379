// noinspection JSXUnresolvedComponent

import useAsync from "lib/@hooks/useAsync"
import { Box } from "@mui/material"
import { Frag } from "lib/@components/slot/frag"
import { checkPermission } from "library/authorization"
import LoadingScreen from "minimals-template/components/LoadingScreen"

const imported = {}

/**
 * Dynamically loads a component using the provided importer function and displays
 * various fallback states during the loading process.
 *
 * @param {Object} options Options for the Lazy function.
 * @param {string} options.$lazy Magic function to write the importer and extract the component using a string which is
 *     FILEPATH|EXTRACT
 * @param {function} options.importer Function to dynamically import the required module.
 * @param {string} options.id Identifier related to the component to be loaded.
 * @param {string} [options.extract="default"] The key of the exported component in the module.
 * @param {JSX.Element} [options.fallback=<Frag />] Fallback component to display during loading or error state.
 * @param {JSX.Element} [options.nullFallback=fallback] Fallback component to display if the extracted component is
 *     null.
 * @param {JSX.Element} [options.loadingScreen=<LoadingScreen />] Component to display while the module is being
 *     loaded.
 * @param {Array} [options.demands] List of demands/permissions required to load the component.
 * @param {boolean} [options.matchAllDemands=true] Whether all demands must be met (true) or just one (false).
 * @param {Object} props Additional properties to be passed to the imported component.
 *
 * @return {JSX.Element} The dynamically loaded component or a fallback component based on the loading state.
 *
 */
export function Lazy({
    importer,
    id,
    extract = "default",
    fallback = <Frag />,
    nullFallback = fallback,
    loadingScreen = <LoadingScreen />,
    demands,
    matchAllDemands = true,
    ...props
}) {
    const component = useAsync(
        async (update) => {
            if (!imported[importer.toString()]) {
                update(loadingScreen)
            }
            imported[importer.toString()] = true
            const module = await importer({ id, extract, fallback, ...props })
            const Component = module?.[extract || "default"]
            if (Component) {
                return <Component />
            }
            return nullFallback
        },
        fallback,
        [id, extract]
    )

    if (demands) {
        if (!checkPermission(demands, matchAllDemands)) {
            return fallback
        }
    }
    return <component.type {...component.props} {...props} />
}

export function makeLazy(importer, extract, loadingScreen) {
    return function makeLazy(props = {}) {
        return (
            <Lazy
                importer={importer}
                nullFallback={<Box>Not Loaded</Box>}
                extract={extract}
                loadingScreen={loadingScreen}
                {...props}
            />
        )
    }
}
