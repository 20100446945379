import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"
import { mutate } from "lib/graphql/mutate"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import { successSnackbar } from "lib/snackbar/success-snackbar"
import { handle } from "library/local-events"

export const createCustomerSupportEmail = api(function createCustomerSupportEmail(email) {
    const { subject, message, attachments } = email
    return mutate(
        gql`
            mutation CreateCustomerSupportEmail($subject: String!, $message: String!, $attachments: [JSONObject]) {
                createCustomerSupportEmail(subject: $subject, message: $message, attachments: $attachments)
            }
        `,
        { subject, message, attachments },
        { returns: "createCustomerSupportEmail" }
    )
})

handle("settings.update.live_support_email_enabled", async (setting) => {
    const result = await mutate(
        gql`
            mutation enableLiveSupportEmail($value: Boolean!) {
                enableLiveSupportEmail(value: $value)
            }
        `,
        { value: setting.value },
        { returns: "enableLiveSupportEmail" }
    ).catch((e) => {
        console.error(e)
        errorSnackbar(e.message)
    })

    if (result) {
        console.log("Updated email")
        successSnackbar("Updated customer support email address")
    }
})

handle("settings.update.live_support_email_address", async (setting) => {
    const result = await mutate(
        gql`
            mutation updateLiveSupportEmailAddress($email: String!) {
                updateLiveSupportEmailAddress(email: $email)
            }
        `,
        { email: setting.value },
        { returns: "updateLiveSupportEmailAddress" }
    ).catch((e) => {
        console.error(e)
        errorSnackbar(e.message)
    })

    if (result) {
        console.log("Updated email")
        successSnackbar("Updated customer support email address")
    }
})
