import { Box, DialogContent, DialogTitle, Stack } from "@mui/material"
import { PropertyBox } from "lib/@components/property-box"
import { OkCancel } from "lib/@components/okCancel"

export function DialogBox({
    Actions = OkCancel,
    actions,
    sx,
    spacing = 1,
    title,
    className = "dialogBox",
    children,
    ...props
}) {
    return (
        <Stack sx={{ overflow: "hidden", ...sx }} className={className} spacing={spacing}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <PropertyBox>{children}</PropertyBox>
            </DialogContent>
            <Box flex={1} />
            <Actions {...props} children={actions} />
        </Stack>
    )
}
