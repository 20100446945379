import { Box } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { COLOR_FROM_CLASSIFICATION, ICON_FROM_CLASSIFICATION } from "library/SFG20/constants"

export function Classification({ classification, children }) {
    return (
        <Box
            className={`classification ${classification}`}
            display="inline-flex"
            alignItems="center"
            sx={{ lineHeight: 0 }}
        >
            <Box>
                <Iconify
                    sx={{ color: COLOR_FROM_CLASSIFICATION[classification], width: 24, height: 24 }}
                    icon={ICON_FROM_CLASSIFICATION[classification]}
                    data-cy="classification-icon"
                />
            </Box>
            {children}
        </Box>
    )
}
