import { useState } from "react"
import { gql } from "@apollo/client"
import {
    Box,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItemButton,
    TextField,
    Typography,
} from "@mui/material"
import { setFromEvent } from "lib/setFromEvent"
import { get } from "fast-levenshtein"
import { OkCancel } from "lib/@components/okCancel"
import { mutate } from "lib/graphql/mutate"
import { getAllTags } from "routes/schedule/plugins/baskets/tags/useTagsAndAllPossibleTags"
import { HasInvalidated, RefreshSchedules } from "event-definitions"
import { TagLimitAlert } from "./tag-limit-alert"
import { useDebouncedValue } from "lib/@hooks/useDebouncedValue"

const CREATE_BASKET = "I should create a basket!"
const TAG_LIMIT = 1000

HasInvalidated("itemBaskets").handleOnce(() => RefreshSchedules.raise())

export function CreateNewTagDialog({ suggestedTag = "", type = "Tag" }) {
    const [name, setName, debouncedName] = useDebouncedValue(suggestedTag)
    const [id, setId] = useState(null)
    const allTags = getAllTags.useResults()
    const tags = (allTags?.baskets ?? [])
        .map((tag) => ({
            ...tag,
            distance: get(tag.name.toLowerCase(), debouncedName.toLowerCase()),
        }))
        .sortBy("distance")
        .filter(({ distance }) => distance < 5)

    const canAddTag = allTags?.baskets?.length < TAG_LIMIT

    return canAddTag ? (
        <>
            <DialogTitle>Create a new tag</DialogTitle>
            <DialogContent>
                <Box sx={{ mt: 2 }}>
                    <TextField
                        fullWidth
                        autoFocus
                        value={name}
                        onChange={setFromEvent((v) => {
                            setName(v)
                            setId(null)
                        })}
                        label={"Tag"}
                    />
                </Box>
                {!!tags.length && (
                    <Box sx={{ mt: 2, maxHeight: "30vh", overflow: "auto" }}>
                        <Typography variant="caption" gutterBottom>
                            Existing tags you might want to use:
                        </Typography>
                        <List>
                            {tags.slice(0, 100).map((tag) => (
                                <ListItemButton selected={id === tag.id} onClick={() => setId(tag.id)} key={tag.id}>
                                    {tag.name}
                                </ListItemButton>
                            ))}
                        </List>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <OkCancel
                    beforeOk={createTagIfNecessary}
                    value={
                        (allTags?.baskets ?? []).find((t) => t.name.toLowerCase() === name.toLowerCase())
                            ? false
                            : id || CREATE_BASKET
                    }
                    okCaption={id ? `Select "${tags.find((t) => t.id === id).name}"` : "Create"}
                />
            </DialogActions>
        </>
    ) : (
        <TagLimitAlert />
    )

    function createTagIfNecessary(value) {
        if (value === CREATE_BASKET) {
            return mutate(
                gql`
                    mutation CreateTag($name: String!, $type: String!) {
                        createBasket(name: $name, type: $type) {
                            id
                        }
                    }
                `,
                { name, type },
                { returns: "createBasket.id" }
            )
        }
        return value
    }
}
