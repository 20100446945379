import { IconButton, useMediaQuery, useTheme } from "@mui/material"
import { raise } from "library/local-events"
import { resolveAsFunction } from "lib/resolve-as-function"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import Iconify from "minimals-template/components/Iconify"

export function OnMobile({ children, isMobile = () => false }) {
    const mobile = useMobile(isMobile)
    return mobile && children
}

export function NotOnMobile({ children, isMobile = () => false }) {
    const mobile = useMobile(isMobile)
    return !mobile && children
}

export function useMobile(isMobile = () => false) {
    const theme = useTheme()
    const { isPDF } = useBoundContext()
    const mobile = useMediaQuery(theme.breakpoints.down("lg"))
    isMobile = resolveAsFunction(isMobile)
    return !isPDF && (mobile || isMobile())
}

export function OpenMenu(props) {
    const theme = useTheme()
    return (
        <OnMobile>
            <IconButton
                {...props}
                sx={{ mr: 1, color: theme.palette.primary.contrastText, ...props.sx }}
                onClick={open}
            >
                <Iconify icon="mdi:menu" />
            </IconButton>
        </OnMobile>
    )

    function open() {
        raise("OpenMenu", true)
    }
}
