import { useState } from "react"
import { StartEditor } from "event-definitions"

export function useEditing(initialValue = false) {
    const [editing, setEditing] = useState(initialValue)
    StartEditor.useEvent(() => setEditing(false))
    return [editing, set]

    function set(value = true) {
        StartEditor.raise() // Turn off other editors
        setEditing(value)
    }
}
