import { server } from "lib/app-server"

let additional = `${window.location.protocol}//${window.location.host}`

async function start() {
    const response = await server("/url-add")
    if (response.ok) {
        const newAdditional = await response.text()

        if (newAdditional === "USE_SERVER") {
            additional = `${window.appProtocol || process.env.REACT_APP_PROTOCOL}://${
                window.appServerLocation || process.env.REACT_APP_SERVER
            }`
        } else {
            if (newAdditional) {
                additional = newAdditional
                setTimeout(start, 20000)
            }
        }
    }
}

start().catch(console.error)

export function scopeUrl(url) {
    if (url.startsWith("/")) {
        return `${additional}${url}`
    }
    return url
}
