import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export const getAllSkillCodes = api(function getAllSkillCodes() {
    return query(
        gql`
            query GetSkillCodes {
                getAllSkillCodes {
                    CoreSkillingID
                    Skilling
                    SkillingCode
                }
            }
        `,
        {},
        { returns: "getAllSkillCodes" }
    )
})
