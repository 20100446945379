import { ensureArray } from "lib/ensure-array"
import noop from "lib/noop"
import { noChange, useRefresh } from "lib/@hooks/useRefresh"
import { handle, raise } from "library/local-events"
import { Fragment, startTransition, useCallback, useEffect, useLayoutEffect, useMemo, useRef } from "react"
import { debounce } from "lib/debounce"

export function useEvents(eventNames, handler, deps = []) {
    for (const event of eventNames) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEvent(event, handler, deps)
    }
}

// Shelve

export function UpdateOnEvent({ event, children }) {
    const events = ensureArray(event)
    const refresh = useRefresh()
    useEffect(() => {
        const remove = []
        for (const event of events) {
            remove.push(handle(event, refresh))
        }
        return () => remove.forEach((f) => f())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...events, refresh])
    return <Fragment key={refresh.id}>{children}</Fragment>
}

export function useEvent(eventName, handler, deps = []) {
    const refresh = useRefresh(noChange)
    const mounted = useRef()
    const mountFn = useRef(noop)
    const remove = useRef(noop)
    handler = handler || refresh
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const innerHandler = useCallback(readyHandler, [...deps, eventName])

    innerHandler.priority = handler.priority
    useMemo(() => {
        remove.current()
        remove.current = handle(eventName, innerHandler)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps, eventName])
    useEffect(() => {
        mounted.current = true
        mountFn.current()
        return () => {
            if (remove.current) {
                remove.current()
                remove.current = noop
            }
            if (mounted.current) {
                mounted.current = false
            }
        }
    }, [eventName])
    return handler

    function readyHandler(...params) {
        const self = this
        if (mounted.current) {
            return handler.call(self, ...params)
        }
        const existing = mountFn.current
        mountFn.current = () => {
            try {
                existing()
            } catch (e) {
                //
            }
            handler.call(self, ...params)
        }
        return undefined
    }
}

/**
 * Event handler for "DecorateEvent" that extends the EventObject definition with additional methods.
 * These methods are being assigned to the EventObject definition within the handler.
 */
handle("DecorateEvent", (def) => {
    Object.assign(def, {
        /**
         * Hooks into a specified event, reacting whenever the event is raised.
         *
         * @function
         * @name EventObject#useEvent
         * @param {Function} handler - The event handler function.
         * @param {Array} [deps=[]] - List of dependencies for the event handler.
         * @returns {Function} A function to deregister the event handler.
         */
        useEvent(handler, deps = []) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            return useEvent(def.eventName, handler, deps)
        },

        /**
         * Hooks into a refresh mechanism, reacting whenever a refresh is needed.
         *
         * @function
         * @name EventObject#useRefresh
         * @param {...*} params - Parameters for the refresh hook.
         * @returns {Function} The refresh function.
         */
        useRefresh(...params) {
            const refresh = useRefresh(...params)
            useEvent(def.eventName, refresh)
            return refresh
        },

        /**
         * A React component that provides a refresh mechanism, reacting to specified events.
         *
         * @function
         * @name EventObject#Refresh
         * @param {Object} props - The properties for the component.
         * @param {ReactNode|Function} props.children - The children of the component, or a function to render children.
         * @returns {ReactNode} The rendered children.
         */
        Refresh({ children }) {
            const refresh = useRefresh(noChange)
            useEvent(def.eventName, refresh)
            return typeof children === "function" ? children() : children
        },

        /**
         * Fires an event within a transition context.
         *
         * @function
         * @name EventObject#fireInTransition
         * @param {...*} params - The parameters to pass to the event.
         */
        fireInTransition(...params) {
            startTransition(() => {
                raise(def.eventName, ...params)
            })
        },
    })
})

export function useBrowserEvent(eventName, handler) {
    const innerHandler = useCallback((e) => handler(e, ...(e._parameters || [])), [handler])
    useLayoutEffect(() => {
        window.addEventListener(eventName, innerHandler)
        return () => window.removeEventListener(eventName, innerHandler)
    }, [eventName, innerHandler])
}

export function useDebouncedBrowserEvent(eventName, handler, wait = 1, options = {}) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const innerHandler = useMemo(
        () => debounce((e) => handler(e, ...(e._parameters || [])), wait, options),
        [handler, wait, options]
    )
    useLayoutEffect(() => {
        window.addEventListener(eventName, innerHandler)
        return () => {
            window.removeEventListener(eventName, innerHandler)
        }
    }, [eventName, innerHandler])
}
