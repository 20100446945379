import { handleNamed } from "library/local-events"
import { omit } from "lib/omit"
import React from "react"
import { RunMe } from "lib/@components/slot/index"
import { getId } from "lib/@components/slot/get-id"
import { SlotLabel } from "lib/@components/slot/slot-label"

/**
 * Supply a component to plug into a slot
 * @param {string} type - the slot type
 * @param {object} component - an instance of the component to use
 */
export function plug(type, component) {
    if (typeof component !== "object" || !component.type) {
        if (typeof component !== "function") {
            throw new Error("plug should be passed an instance of a component like <MyComponent />")
        }
    }
    handleNamed(`${type}${getId(component)}`, `Slot.${type}`, ({ add, ...props }) => {
        if (typeof component === "function") {
            add({ [RunMe]: true, fn: (newProps) => component(newProps) })
        } else if (component.props.if) {
            try {
                if (component.props.if(props.context)) {
                    add(<component.type {...omit(component.props, "if")} />)
                }
            } catch (e) {
                //
            }
        } else {
            add(component)
        }
    })
}

plug("**", <SlotLabel if={({ _showLabels }) => !!_showLabels} />)
