/* eslint-disable react/no-danger */
import { Box, Button, Dialog, DialogActions, DialogContent } from "@mui/material"
import useTermsAndConditions from "./useTermsAndConditions"
import { getCurrentTermsAndConditions } from "./controller/terms-and-conditions-controller"

export function TermsAndConditions({ children }) {
    const [showTermsAndConditions, acceptTermsAndConditions] = useTermsAndConditions()

    if (showTermsAndConditions) {
        return <TermsAndConditionsDialog ok={acceptTermsAndConditions} />
    }

    return children
}

/* eslint-disable react/no-danger */
function TermsAndConditionsDialog({ ok }) {
    const currentTermsAndConditions = getCurrentTermsAndConditions.useResults()

    return (
        <Dialog open={true} fullScreen>
            <Box
                pt="env(safe-area-inset-top)"
                minHeight="80vh"
                display="flex"
                flexDirection="column"
                justifyContent="center"
            >
                <DialogContent id="1" style={{ height: 10000 }}>
                    <div
                        id="importedTC"
                        style={{ overflowY: "scroll" }}
                        dangerouslySetInnerHTML={{ __html: currentTermsAndConditions }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button id="accept-tc" onClick={ok}>
                        I agree to be Bound by the Terms and Conditions of use
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}
