import { useSelectedItemIds } from "routes/schedule/lib/useSelectedItems"
import Iconify from "minimals-template/components/Iconify"
import { mutate } from "lib/graphql/mutate"
import { gql } from "@apollo/client"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import { successSnackbar } from "lib/snackbar/success-snackbar"
import { Button } from "@mui/material"
import { CreateNewTagDialog } from "../create-new-tag-dialog"
import { useDialog } from "lib/@hooks/useDialog"

export function CreateTagWithItems() {
    const items = useSelectedItemIds()
    const openNewTagDialog = useDialog(<CreateNewTagDialog />)

    return (
        !!items.length && (
            <Button onClick={createUsing} variant="contained" startIcon={<Iconify icon={"ic:round-add"} />}>
                Create A New Tag Containing {items.length} Selected {"schedule".pluralize(items.length)}{" "}
            </Button>
        )
    )

    async function createUsing() {
        const tagId = await openNewTagDialog()
        if (tagId) {
            try {
                await mutate(
                    gql`
                        mutation AddScheduleToTag($basketId: String!, $items: [BasketItemInput!]!) {
                            addItemsToBasket(id: $basketId, items: $items)
                        }
                    `,
                    {
                        basketId: tagId,
                        items: items.map((id) => ({ id, referenceId: id })),
                    }
                )
                successSnackbar("Tag created")
            } catch (e) {
                errorSnackbar(e.message)
            }
        }
    }
}
