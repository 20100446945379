import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export const getStripeSubscription = api(async function getStripeSubscription(id) {
    return query(
        gql`
            query GetStripeSubscription($id: String!) {
                getStripeSubscription(id: $id) {
                    amount
                    email
                    expiry
                    period
                    product_id
                    status
                    mode
                    modified
                    portalUrl
                    cancelled
                }
            }
        `,
        { id },
        { returns: "getStripeSubscription" }
    )
})
