import { ProcessVariable } from "event-definitions"
import { makeBusyFunction } from "lib/@components/busy/make-busy-function"
import { LOADING, REMOVE_VARIABLE } from "lib/graphql/api"

export class Busy {
    constructor(message, predicate = () => true) {
        this.message = message
        this.predicate = predicate
        this.delayTime = 0
    }

    message = ""

    delay(amount) {
        this.delayTime = amount
        return this
    }

    when(predicate) {
        this.predicate = predicate
        return this
    }
}

export function withBusyMessage(message, onlyFirstTime) {
    return new Busy(message, onlyFirstTime ? (v) => v === LOADING : undefined)
}

ProcessVariable.handleOnce(({ variable, queryRunner, currentValue }) => {
    if (variable instanceof Busy) {
        if (variable.predicate(currentValue)) {
            return {
                queryRunner: makeBusyFunction(queryRunner, variable.message, undefined, variable.delayTime),
                variable: REMOVE_VARIABLE,
            }
        }
        return { variable: REMOVE_VARIABLE }
    }
    return undefined
})
