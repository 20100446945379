import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"

export const downloadRegime = api(function downloadRegime(regimeId, shareId, userName) {
    return query(
        gql`
            query DownloadRegime($regimeId: String!, $shareId: String!, $userName: String!) {
                downloadRegime(regimeId: $regimeId, shareId: $shareId, userName: $userName)
            }
        `,
        { regimeId, shareId, userName },
        { returns: "downloadRegime" }
    )
})
