import { FieldUpdate } from "event-definitions"
import { useCurrentState } from "lib/@hooks/useCurrentState"
import { noChange, useRefresh } from "lib/@hooks/useRefresh"
import { resolveValue } from "lib/resolve-as-function"
import { set } from "lib/set"
import { useEffect, useRef } from "react"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function useFieldUpdated(field, defaultValue) {
    const refresh = useRefresh(noChange)
    const { target } = useBoundContext()
    const value = useRef()
    value.current = Object.get(target, field, true) ?? resolveValue(defaultValue)
    FieldUpdate(field).useEvent(() => {
        if (value.current !== Object.get(target, field, true)) {
            refresh()
        }
    }, [target, field])
    if (!field) return []
    return [
        value.current,
        {
            set: (v, notify) => {
                set(target, field, v)
                if (notify) {
                    FieldUpdate(field).raise(Object.get(target, field, true), target)
                }
                return v
            },
            onChanged: () => FieldUpdate(field).raise(Object.get(target, field, true), target),
        },
    ]
}

export function useFieldsUpdated(fields = []) {
    const [additional, setAdditional] = useCurrentState([])
    const track = [...fields, ...additional]
    const refresh = useRefresh(noChange)
    useEffect(() => {
        const removeHandlers = []
        update(removeHandlers).catch(console.warn)
        return () => removeHandlers.forEach((f) => f())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(track), refresh.id])
    return { id: refresh.id, add }

    function add(...fields) {
        setAdditional(fields.flat(Infinity))
    }

    async function update(removeHandlers) {
        for (const field of track) {
            removeHandlers.push(FieldUpdate(field).handle(refresh))
        }
    }
}
