import { clean } from "lib/@components/slot/clean"
import React from "react"

export function getSlotContentElementsWithWrapper(items, props, containerProps, Type) {
    return items
        .filter((item) => !!item?.props)
        .sortBy((item) => item.props?.priority || 100)
        .map((item, index) =>
            !Type || item.props.noContainer ? (
                { ...item, key: item.key ?? index, props: { ...item.props, ...props } }
            ) : (
                <Type {...containerProps} {...clean(item.props)} key={item.key ?? index}>
                    {{ ...item, key: item.key ?? index, props: { ...item.props, ...props } }}
                </Type>
            )
        )
}
