/**
 * Ensures that a passed in value is returned as a function.  If the  value
 * is already a function it is returned otherwise it is wrapped in a function to return it
 * @param {any} value
 * @returns {(function(): *)} a function to retrieve a value
 */
export function resolveAsFunction(value) {
    if (typeof value === "function") {
        return value
    }
    return () => value
}

export function resolveValue(value) {
    return resolveAsFunction(value)()
}
