import { UserAuthenticated, UserLoggedOut, WindowReload } from "event-definitions"
import { server } from "lib/app-server"
import { instanceId } from "lib/graphql/instance-id"
import { isCapacitor } from "routes/@layouts/app/is-capacitor"
import { makeBusyFunction } from "lib/@components/busy/make-busy-function"

const sfg20AccessTokenKey = "sfg20AccessToken"
const INTERVAL = 1000 * 60 * 15 // 15 minutes

let currentLocalUser = null
let currentLocalToken = null
let refreshIntervalId

async function authRequest(params, endpoint, { method = "POST", token = currentSession(), namespace = "test" } = {}) {
    const options = {
        method,
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
            Instance: instanceId,
        },
    }

    if (token) {
        options.headers.Authorization = `Bearer ${token}`
    }

    if (window.Cypress) {
        options.headers.namespace = namespace
    }

    if (method !== "GET") {
        options.body = typeof params === "string" ? params : JSON.stringify(params)
    }

    try {
        const response = await server(endpoint, options)

        if (response.ok) {
            return await response.json()
        } else {
            const error = new Error(response.statusText)
            error.status = response.status
            throw error
        }
    } catch (error) {
        console.error(`Request failed:${JSON.stringify(params)} - ${error.status} ${error.message}`)
        throw error
    }
}

export const signOut = makeBusyFunction(async function signOut() {
    try {
        await authRequest({}, "/auth/logout")
    } catch (e) {
        console.error(e)
    }
})

export const requestSignInLink = makeBusyFunction(async function requestSignInLink(email, firstName, lastName) {
    try {
        await authRequest({ email, firstName, lastName }, "/auth/request")
    } catch (e) {
        if (e?.message?.includes("Unauthorized") || e?.status === 401) {
            throw e
        } else {
            throw new Error("An error occurred. Unable to send passcode email.")
        }
    }
}, "Requesting Link")

export async function verifyUser(email, passcode) {
    return authRequest({ email, passcode }, "/auth/verify")
}

export async function refreshToken() {
    return authRequest({}, "/auth/refresh")
}

export function setCurrentSession(token) {
    currentLocalToken = token
    localStorage.setItem(sfg20AccessTokenKey, token)
}

export function currentSession() {
    if (currentLocalToken) return currentLocalToken

    const tokenFromLocalStorage = localStorage.getItem(sfg20AccessTokenKey)
    if (tokenFromLocalStorage) {
        currentLocalToken = tokenFromLocalStorage
        return currentLocalToken
    }
    return undefined
}

export async function currentUser() {
    if (currentLocalUser) return currentLocalUser

    const token = await currentSession()
    if (!token) throw new Error("No valid session found...")

    const userFromServer = await authRequest({}, "/auth/currentUser", { method: "GET", token })
    if (userFromServer) {
        currentLocalUser = userFromServer
    } else {
        throw new Error("Unable to retrieve user...")
    }

    return currentLocalUser
}

export async function startTokenRefresh() {
    if (refreshIntervalId) {
        clearInterval(refreshIntervalId)
    }

    refreshIntervalId = setInterval(async () => {
        // TODO: Removed due to cookies being primary auth, delete when validated
        // const currentToken = currentSession()
        // if (!currentToken) {
        //     UserLoggedOut.raise()
        //     return
        // }
        try {
            await refreshToken()
            // if (newTokenObject && newTokenObject.token !== currentToken) {
            //     setCurrentSession(newTokenObject.token)
            // }
        } catch (error) {
            //AD: FIXME Try a few times here, then give up
            console.error("Error refreshing token:", error)
            // UserLoggedOut.raise()
        }
    }, INTERVAL)
}

export function stopTokenRefresh() {
    console.log("Stopping token refresh...")
    if (refreshIntervalId) {
        clearInterval(refreshIntervalId)
    }
}

UserAuthenticated.after.handleOnce(() => {
    startTokenRefresh().catch(console.error)
    console.log("Starting token refresh...")
})

UserLoggedOut.handleOnce(() => {
    stopTokenRefresh()
    currentLocalToken = null
    currentLocalUser = null
    localStorage.removeItem(sfg20AccessTokenKey)
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
    WindowReload.raise(isCapacitor ? "/sfg20" : "/app")
})
