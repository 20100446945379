import { useTheme } from "@emotion/react"
import { Tooltip, Typography } from "@mui/material"
import { useMeasurement } from "lib/@hooks/useMeasurement"
import { useLayoutEffect, useRef, useState } from "react"

function useSpacingInPixels(spacingUnit) {
    const theme = useTheme()
    const spacingValue = theme.spacing(spacingUnit)

    // Create a temporary element
    const tempElement = document.createElement("div")
    tempElement.style.position = "absolute"
    tempElement.style.visibility = "hidden"
    tempElement.style.height = spacingValue

    // Append to the body to calculate the computed style
    document.body.appendChild(tempElement)
    const pixelValue = window.getComputedStyle(tempElement).height
    document.body.removeChild(tempElement)

    return parseFloat(pixelValue) // Returns the computed pixel value
}

export function TruncatedTypography({ children, sx = {}, ...props }) {
    const textRef = useRef(null)
    const walkerRef = useRef(null)
    const [isHovered, setIsHovered] = useState(false)
    const [isOverflowing, setIsOverflowing] = useState(true)
    const margin = useSpacingInPixels(1)
    const [parentSize, attach] = useMeasurement(textRef)

    const elideTextStyle = {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "100%",
    }

    useLayoutEffect(() => {
        walkerRef.current = textRef.current
        let node = walkerRef.current
        while (node) {
            node = node.parentElement
            const style = window.getComputedStyle(node)
            if (style.display === "flex" || style.display === "block") {
                walkerRef.current = node.parentElement
                node.style.overflow = "hidden"
                break
            }
        }
        return () => {
            if (walkerRef.current) {
                walkerRef.current.style.overflow = ""
            }
        }
    }, [])

    useLayoutEffect(() => {
        if (textRef.current) {
            const parentWidth = parentSize.width
            const marginValue = parseFloat(margin)
            const totalWidth = parentWidth + marginValue
            const isTextOverflowed = textRef.current.scrollWidth > totalWidth
            setIsOverflowing(isTextOverflowed)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parentSize.width, margin, JSON.stringify(children)])

    const content = (
        <Typography
            sx={isOverflowing ? { ...elideTextStyle, ...sx } : { ...sx }}
            ref={attach}
            component="div"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            {...props}
        >
            {children}
        </Typography>
    )

    return isOverflowing ? (
        <Tooltip title={children} open={isHovered} placement="top">
            {content}
        </Tooltip>
    ) : (
        content
    )

    function handleMouseEnter() {
        setIsHovered(true)
    }

    function handleMouseLeave() {
        setIsHovered(false)
    }
}
