import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
// config
import { defaultLang } from "config"
//
import enLocales from "minimals-template/locales/en"

// ----------------------------------------------------------------------

// eslint-disable-next-line import/no-named-as-default-member
i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: { translations: enLocales },
        },
        lng: localStorage.getItem("i18nextLng") || defaultLang.value,
        fallbackLng: defaultLang.value,
        debug: false,
        ns: ["translations"],
        defaultNS: "translations",
        interpolation: {
            escapeValue: false,
        },
    })
    .catch(console.error)

export default i18n
