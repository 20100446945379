import { generate } from "library/guid"
import { BusyAdd, BusyRemoved, CaptureNow } from "event-definitions"

export const CONFIGURABLE_BUSY_TIMEOUT = 10000

export async function busyWhileWithDelay(fn, delay = 300, description = undefined, extra = () => true) {
    const id = generate()
    let timer
    try {
        if (delay) {
            timer = setTimeout(() => {
                BusyAdd.raise({ id, description })
                if (window.Cypress) {
                    setTimeout(BusyRemoved.raise({ id }), CONFIGURABLE_BUSY_TIMEOUT)
                }
            }, delay)
        } else {
            BusyAdd.raise({ id, description })
            if (window.Cypress) {
                setTimeout(BusyRemoved.raise({ id }), CONFIGURABLE_BUSY_TIMEOUT)
            }
        }

        const result = await (fn.then ? fn : fn())
        // noinspection ES6RedundantAwait
        await extra()
        return result
    } catch (error) {
        console.error(`Error in ${fn.name ?? "busyWhile"}:`, error)
        throw error
    } finally {
        clearTimeout(timer)
        BusyRemoved.raise({ id })
    }
}

busyWhileWithDelay.capture = function busyWhileWithDelayCapture(...params) {
    CaptureNow.raise()
    return busyWhileWithDelay(...params)
}
