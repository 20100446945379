// ----------------------------------------------------------------------
import { Alert } from "@mui/material"
import React from "react"

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    //
    // // noinspection JSUnusedLocalSymbols
    // // eslint-disable-next-line no-unused-vars
    // // noinspection JSUnusedLocalSymbols
    // // eslint-disable-next-line no-unused-vars
    // static getDerivedStateFromError(error) {
    //     // Update state so the next render will show the fallback UI.
    //     return { hasError: true }
    // }
    //
    // componentDidCatch(error, errorInfo) {
    //     // You can also log the error to an error reporting service
    //     console.error(error, errorInfo)
    // }

    render() {
        const { hasError } = this.state
        const { children } = this.props
        if (hasError) {
            // You can render any custom fallback UI
            return <Alert severity="error">An error occurred</Alert>
        }

        return children
    }
}
