import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"

export const getUserByEmail = api(async function getUserByEmail(email) {
    if (!email) return null
    return query(
        gql`
            query getUserByEmail($email: String!) {
                getUserByEmail(email: $email) {
                    displayName
                    email
                    client
                    company
                    photoURL
                    jobTitle
                    metadata
                    numberOfClients
                    isOverridingClient
                }
            }
        `,
        { email },
        { returns: "getUserByEmail" }
    )
})

export function useUserByEmail(email) {
    const user = getUserByEmail.useResults(email)
    return { loading: !user, error: null, user }
}
