import { Stack } from "@mui/material"
import { ListItemBox } from "lib/@components/ListItemBox"
import noop from "lib/noop"
import { useUserByIdOrEmail } from "lib/user/use-user"
import { UserAvatar } from "minimals-template/components/user-avatar"
import { useEffect } from "react"
import { UserClientSwitcher } from "./user-client-switcher"
import { TruncatedTypography } from "lib/@components/truncated-typography"

export function User({ id, email, rendered = noop, hideDetails, allowClientSwitching, children, sx }) {
    const user = useUserByIdOrEmail(id, email)
    useEffect(() => {
        rendered(!!user)
    }, [rendered, user])

    if (!user || Object.isEmpty(user)) return null

    return (
        !!user &&
        !user.__NO_USER && (
            <>
                <ListItemBox display="inline-flex" sx={sx}>
                    <UserAvatar sx={{ mr: 1 }} user={user} />
                    {!hideDetails && (
                        <Stack spacing={0}>
                            <TruncatedTypography variant="body2" data-cy="user-display-name" noWrap>
                                {user.metadata?.displayName ?? user.email ?? "User"}
                            </TruncatedTypography>
                            <TruncatedTypography
                                sx={{ color: "text.secondary" }}
                                variant="body2"
                                data-cy="user-job-title"
                                noWrap
                            >
                                {user?.metadata?.jobTitle ?? null}
                            </TruncatedTypography>
                            <UserClientSwitcher
                                user={user}
                                allowClientSwitching={allowClientSwitching}
                                adoptingClient={user.isOverridingClient}
                            />
                        </Stack>
                    )}
                </ListItemBox>
                {children}
            </>
        )
    )
}
