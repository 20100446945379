import { useState } from "react"
import { Bound } from "lib/@components/binding/Bound"
import { Dialog } from "@mui/material"
import { RemoveDialog } from "event-definitions"
import { OkCancelContext } from "lib/@hooks/useDialog"
import useSettings from "minimals-template/components/@hooks/useSettings"
import { useMobile } from "lib/@components/mobile"

export function RenderedDialogBox({
    pass,
    resolve,
    displayIndex,
    dialogId,
    initialProps = {},
    content = null,
    context,
    ...props
}) {
    const [open, setOpen] = useState(true)
    const item = typeof content === "function" ? { type: content, props: {} } : content
    const { onOkCancel, ...dialogProps } = props?.dialogProps ?? {}
    const settings = useSettings()
    onOkCancel?.({ ok, cancel })
    const zIndex = (dialogProps.boost ? 5100 : 1207) + displayIndex
    const isMobile = useMobile()
    return (
        <Bound {...context} inDialog={true}>
            <OkCancelContext.Provider value={{ ok, cancel }}>
                <Dialog
                    {...dialogProps}
                    sx={{
                        zIndex,
                        pt: isMobile ? 8 : 0,
                        "& .MuiDialog-paper": { m: 0, ...dialogProps.sx },
                    }}
                    open={open}
                    PaperProps={{ className: dialogProps.className, sx: { zIndex, position: "relative" } }}
                    onClose={cancel}
                    className={`styled-scrollbars ${settings.themeContrast === "bold" ? "high-contrast-mode" : ""}`}
                >
                    <item.type {...initialProps} {...props} {...item.props} {...pass} ok={ok} cancel={cancel} />
                </Dialog>
            </OkCancelContext.Provider>
        </Bound>
    )

    function ok(value) {
        resolve(value)
        close()
    }

    function cancel() {
        resolve(undefined)
        close()
    }

    function close() {
        setOpen(false)
        setTimeout(() => RemoveDialog.raise(dialogId), 600)
    }
}
