import useSettings from "minimals-template/components/@hooks/useSettings"
import { Box, Divider } from "@mui/material"
import { Bound } from "lib/@components/binding/Bound"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function ToolbarBox({ divider = true, forceWidth, children, ...props }) {
    const { themeStretch } = useSettings()
    const { inToolbarBox } = useBoundContext()
    return (
        <Bound inToolbarBox={true}>
            <Box
                width={1}
                {...props}
                sx={{
                    p: 0,
                    m: 0,
                    ...props.sx,
                    mx: "auto",
                    maxWidth: !themeStretch || forceWidth ? "1152px !important" : undefined,
                }}
            >
                {children}
                {divider && !inToolbarBox && <Divider sx={{ mt: 1 }} />}
            </Box>
        </Bound>
    )
}
