// ----------------------------------------------------------------------

export default function CssBaseline() {
    return {
        MuiCssBaseline: {
            styleOverrides: {
                "*": {
                    margin: 0,
                    padding: 0,
                    boxSizing: "border-box",
                },
                html: {
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    overflow: "visible",
                    overscrollBehavior: "none",
                },
                body: {
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    overscrollBehavior: "none",
                    // paddingTop: "env(safe-area-inset-top)",
                    // paddingBottom: "env(safe-area-inset-bottom)",
                    // paddingLeft: "env(safe-area-inset-left)",
                    // paddingRight: "env(safe-area-inset-right)",
                },
                "#root": {
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                    overflow: "auto",
                },
                input: {
                    "&[type=number]": {
                        MozAppearance: "textfield",
                        "&::-webkit-outer-spin-button": {
                            margin: 0,
                            WebkitAppearance: "none",
                        },
                        "&::-webkit-inner-spin-button": {
                            margin: 0,
                            WebkitAppearance: "none",
                        },
                    },
                },
                img: {
                    display: "block",
                    maxWidth: "100%",
                },
            },
        },
    }
}
