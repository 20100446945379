import { setContext } from "@apollo/client/link/context"
import { getCurrentClientId } from "minimals-template/components/contexts/NognitoContext"
import { instanceId } from "lib/graphql/instance-id"
import { currentSession } from "minimals-template/components/contexts/passport"
import StackTrace from "stacktrace-js"

export const authLink = setContext(async () => {
    try {
        const params = Object.fromEntries(new URLSearchParams(window.location.search).entries())
        let capturedContext
        if (process.env.REACT_APP_DEBUG_STACK) {
            capturedContext = globalThis.compactContext()
            if (capturedContext.stack) {
                capturedContext.stack = await StackTrace.fromError(capturedContext.stack).then(function (stackframes) {
                    return stackframes
                        .slice(3)
                        .map(function (sf) {
                            return sf.toString()
                        })
                        .filter((s) => !s.includes("react-dom"))
                        .join("\n")
                })
            }
        }
        if (params.alttoken) {
            return {
                headers: Object.fromEntries(
                    Object.entries({
                        authorization: `Bearer ${params.alttoken}`,
                        source: params.share,
                        debugcontext: JSON.stringify(capturedContext),
                        clientid: getCurrentClientId(),
                        href: window.location.href,
                        instance: instanceId,
                    }).filter(([, v]) => !!v)
                ),
            }
        }

        const jwtToken = currentSession()

        return {
            headers: Object.fromEntries(
                Object.entries({
                    authorization: jwtToken !== "undefined" && jwtToken ? `Bearer ${jwtToken}` : undefined,
                    source: params.share,
                    debugcontext: JSON.stringify(capturedContext),
                    clientid: getCurrentClientId(),
                    href: window.location.href,
                    instance: instanceId,
                }).filter(([, v]) => !!v)
            ),
        }
    } catch (e) {
        console.log(e)
        return { headers: { authorization: "No Token" } }
    }
})
