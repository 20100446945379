export function adjustPath(to) {
    if (!to) return to
    const current = Object.fromEntries(new URLSearchParams(window.location.search).entries())
    const next = !to?.includes("?") ? {} : Object.fromEntries(new URLSearchParams(`?${to.split("?").at(-1)}`).entries())
    if (next.share === "clear") {
        delete current.share
        delete next.share
    }
    if (current.share && !next.share) {
        next.share = current.share
    }
    return `${to?.split("?").at(0)}?${Object.entries(next)
        .map((a) => a.join("="))
        .join("&")}`
}
