import { makeStyles } from "@mui/styles"

export const buttonStyles = makeStyles({
    uploadButton: {
        width: 0,
        height: 0,
        display: "none",
        position: "absolute",
    },
})
