import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export const getStripePrices = api(async function getStripePrices() {
    return query(
        gql`
            query GetStripePrices {
                getStripePrices {
                    id
                    recurring {
                        interval_count
                        interval
                    }
                    unit_amount
                    product {
                        description
                        id
                        metadata {
                            interval
                            duration
                        }
                        name
                    }
                }
            }
        `,
        { returns: "getStripePrices" }
    )
})
