import PropTypes from "prop-types"
// form
import { FormProvider as Form } from "react-hook-form"
import { useState } from "react"
import { Bound } from "lib/@components/binding/Bound"

// ----------------------------------------------------------------------

FormProvider.propTypes = {
    children: PropTypes.node.isRequired,
    methods: PropTypes.object.isRequired,
    onSubmit: PropTypes.func,
}

export default function FormProvider({ children, onSubmit, methods, id }) {
    const [form] = useState({})
    return (
        <Bound
            target={form}
            onChange={() => {
                const { setValue } = methods
                Object.entries(form).map(([k, v]) => setValue(k, v))
            }}
        >
            <Form {...methods}>
                <form onSubmit={onSubmit} id={id}>
                    {children}
                </form>
            </Form>
        </Bound>
    )
}
