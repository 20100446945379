import { useReference } from "lib/@hooks/use-reference"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function useScheduleSettings() {
    const [
        {
            tasks: { showDetail, onePage },
        },
        save,
    ] = useReference({ tasks: { showDetail: true, onePage: false } }, "settings")
    const { isPDF } = useBoundContext()

    return [{ showDetail: isPDF ? true : showDetail, onePage: isPDF ? true : onePage }, set]

    function set({ showDetail, onePage }) {
        save({ tasks: { showDetail, onePage } })
    }
}
