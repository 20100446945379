import { useEffect, useState } from "react"

import {
    confirmTermsAndConditionsAgreed,
    getUpdatedTermsAndConditionsHash,
} from "./controller/terms-and-conditions-controller"
import { infoSnackbar } from "lib/snackbar/info-snackbar"

if (sessionStorage.getItem("deferAcceptanceDueToError")) {
    sessionStorage.removeItem("deferAcceptanceDueToError")
}

let deferAcceptanceDueToError = false

function setTermsTimeout(cb, delay = 1000 * 60 * 60) {
    deferAcceptanceDueToError = true
    sessionStorage.setItem("deferAcceptanceDueToError", true)
    setTimeout(() => {
        deferAcceptanceDueToError = false
        sessionStorage.removeItem("deferAcceptanceDueToError")
        cb()
    }, delay)
}

export default function useTermsAndConditions() {
    let termsAndConditionsHash = null

    termsAndConditionsHash = getUpdatedTermsAndConditionsHash.useResults()

    const [accepted, setAccepted] = useState(null)

    useEffect(() => {
        setAccepted(null)
    }, [termsAndConditionsHash])

    if (window.Cypress) {
        let enforceTermsAndConditions = false
        try {
            enforceTermsAndConditions = JSON.parse(sessionStorage.getItem("enforceTermsAndConditions"))
        } catch (e) {
            enforceTermsAndConditions = false
        }
        if (!enforceTermsAndConditions) {
            return [false, acceptTermsAndConditions]
        }
    }

    deferAcceptanceDueToError = sessionStorage.getItem("deferAcceptanceDueToError")

    if (deferAcceptanceDueToError === "true") {
        return [false, acceptTermsAndConditions]
    }

    if (typeof termsAndConditionsHash !== "string") {
        return [false, acceptTermsAndConditions]
    }

    return [termsAndConditionsHash !== accepted, acceptTermsAndConditions]

    async function acceptTermsAndConditions({ defer = false }) {
        if (defer) {
            console.log("Terms and conditions deferred")
            return problemDetected()
        }

        const returnValue = await confirmTermsAndConditionsAgreed(termsAndConditionsHash)
        if (returnValue?.includes("Failed") || returnValue?.includes("Error")) return problemDetected()

        infoSnackbar("Terms and conditions accepted.")
        setAccepted(termsAndConditionsHash)
        return undefined

        function problemDetected() {
            setAccepted(termsAndConditionsHash)
            setTermsTimeout(reset, 1000 * 60 * 60)
        }
    }

    function reset() {
        setAccepted(null)
    }
}
