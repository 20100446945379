import { useSnackbar } from "notistack"
import { useState } from "react"

// @mui
import { Box, Divider, MenuItem, Stack, Typography } from "@mui/material"
import { alpha } from "@mui/material/styles"
// routes
import { IconButtonAnimate } from "minimals-template/components/animate"
import MenuPopover from "minimals-template/components/MenuPopover"
import MyAvatar from "minimals-template/components/MyAvatar"
import { PATH_AUTH, PATH_DASHBOARD } from "routes/paths"
// hooks
import { RouterLink } from "lib/routes/router-link"
import useAuth from "minimals-template/components/@hooks/useAuth"
import useIsMountedRef from "minimals-template/components/@hooks/useIsMountedRef"
import { useCurrentUser } from "lib/store/use-current-user"
import { NotOnSharing } from "lib/@components/sharing/not-sharing"
import { navigate } from "lib/routes/navigate"
// components

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
    {
        label: "Home",
        linkTo: PATH_DASHBOARD.root ?? "/app",
    },
]

// ----------------------------------------------------------------------

export default function AccountPopover() {
    const { logout } = useAuth()
    const user = useCurrentUser()

    const isMountedRef = useIsMountedRef()

    const { enqueueSnackbar } = useSnackbar()

    const [open, setOpen] = useState(null)

    const handleOpen = (event) => {
        setOpen(event.currentTarget)
    }

    const handleClose = () => {
        setOpen(null)
    }

    const handleLogout = async () => {
        try {
            await logout()
            navigate(PATH_AUTH.login, { replace: true })

            if (isMountedRef.current) {
                handleClose()
            }
        } catch (error) {
            console.error(error)
            enqueueSnackbar("Unable to logout!", { variant: "error" })
        }
    }

    return (
        <>
            <IconButtonAnimate
                onClick={handleOpen}
                id="account-menu"
                sx={{
                    p: 0,
                    ...(open && {
                        "&:before": {
                            zIndex: 1,
                            content: "''",
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                            position: "absolute",
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                        },
                    }),
                }}
            >
                <MyAvatar data-cy="account-popover-avatar" />
            </IconButtonAnimate>

            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{
                    p: 0,
                    mt: 1.5,
                    ml: 0.75,
                    "& .MuiMenuItem-root": {
                        typography: "body2",
                        borderRadius: 0.75,
                    },
                }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant="subtitle2" noWrap>
                        {user?.metadata?.displayName}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
                        {user?.email}
                    </Typography>
                </Box>

                <Divider sx={{ borderStyle: "dashed" }} />

                <NotOnSharing>
                    <Stack sx={{ p: 1 }}>
                        {MENU_OPTIONS.map((option) => (
                            <MenuItem
                                key={option.label}
                                to={option.linkTo}
                                component={RouterLink}
                                onClick={handleClose}
                            >
                                {option.label}
                            </MenuItem>
                        ))}
                        <MenuItem onClick={handleAccount} data-cy="account-popover-account-menu-item">
                            Profile
                        </MenuItem>
                    </Stack>

                    <Divider sx={{ borderStyle: "dashed" }} />
                </NotOnSharing>

                <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
                    Logout
                </MenuItem>
            </MenuPopover>
        </>
    )

    function handleAccount() {
        handleClose()
        navigate("/app/account")
    }
}
