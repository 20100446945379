import uniqolor from "uniqolor"
import { getContrastingColor } from "lib/get-contrasting-color"

function hashCode(s) {
    let i
    let h
    for (i = 3, h = 0; i < s.length; i += 3) h = (Math.imul(31, h) + s.charCodeAt(i)) | 0
    return h
}

export function tagColor(tag) {
    const { color } = uniqolor(hashCode(tag?.name + tag?.id), {
        saturation: [45, 89],
        lightness: [20, 50],
    })

    return [color, getContrastingColor(color)]
}
