import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export const getStripePublishableKey = api(async function getStripePublishableKey() {
    return query(
        gql`
            query GetStripePublishableKey {
                getStripePublishableKey
            }
        `,
        { returns: "getStripePublishableKey" }
    )
})
