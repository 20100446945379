import { gql } from "@apollo/client"
import { mutate } from "lib/graphql/mutate"

export async function updateAdoptClientRestrictionAdopt({ clientId, adopt }) {
    return mutate(
        gql`
            mutation UpdateAdoptClientRestrictionAdopt($clientId: String!, $adopt: Boolean!) {
                updateAdoptClientRestrictionAdopt(clientId: $clientId, adopt: $adopt) {
                    _id
                    start
                    end
                    reason
                    lastSessionCreatedBy
                    beingAdopted
                }
            }
        `,
        { clientId, adopt },
        { returns: "updateAdoptClientRestriction" }
    )
}
