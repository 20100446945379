import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export const getMyShareLinks = api(async function getMyShareLinks(search, take, skip) {
    return query(
        gql`
            query GetMyShareLinks($search: String, $take: Int, $skip: Int) {
                getMyShareLinks(skip: $skip, take: $take, search: $search) {
                    total
                    links {
                        client
                        creator
                        endDate
                        name
                        outOfDate
                        startDate
                        url
                        when
                    }
                    outOfDateLinks
                }
            }
        `,
        { take, skip, search },
        { returns: "getMyShareLinks" }
    )
})
