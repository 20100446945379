import { busyWhile } from "lib/@components/busy/busy-while"
import { busyWhileWithDelay } from "lib/@components/busy/busy-while-with-delay"

export function makeBusyFunction(fn, description, extra = () => true, delay = 250) {
    return async function run(...params) {
        return delay
            ? busyWhileWithDelay(() => fn(...params), delay, description, extra)
            : busyWhile(() => fn(...params), description, extra)
    }
}
