import { Box, Divider, FormControlLabel, Radio, Typography } from "@mui/material"
import VertcialCenterGridItem from "./VertcialCenterGridItem"
import LicenseCard from "./LicenseCard"
import { useTheme } from "@mui/styles"
import { ReactComponent as BuildingGraphic } from "./building.svg"

const SubscriptionCard = ({ selected }) => {
    const theme = useTheme()
    const subscribeColor = theme.palette.license.subscription
    return (
        <VertcialCenterGridItem>
            <label>
                <FormControlLabel value="subscription" sx={{ m: 0, display: "none" }} control={<Radio />} />
                <LicenseCard borderColor={subscribeColor} selected={selected}>
                    <Box
                        sx={{
                            alignItems: "flex-start",
                            display: "flex",
                            flexDirection: "column",
                            gap: "16px",
                        }}
                    >
                        <Typography variant="h4">SFG20 Facilities-iQ Subscription</Typography>
                        <BuildingGraphic />
                        <Typography variant="body2">
                            This subscription gives you full access to SFG20 Facilities iQ
                        </Typography>
                        <Divider flexItem sx={{ borderStyle: "dashed" }} />
                        <Typography variant="subtitle1">Features:</Typography>
                        <Typography variant="body2">
                            Access to SFG20 Core schedule library with optional additional Specialist Sets
                        </Typography>
                        <Typography variant="body2">
                            Manage Maintenance Regimes making sure you&apos;re not over maintaining assets or falling
                            out of conformity.
                        </Typography>
                        <Typography variant="body2">Multiple licences and licence type.</Typography>
                        <Typography variant="body2">
                            Create share links to allow collaboration with internal and external partners.
                        </Typography>
                        <Typography variant="body2">
                            Keep informed of SFG20 schedule updates and how this affects you.
                        </Typography>
                        <Typography variant="body2">Integrates into 3rd party software such as CAFM</Typography>
                    </Box>
                </LicenseCard>
            </label>
        </VertcialCenterGridItem>
    )
}
export default SubscriptionCard
