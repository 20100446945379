import { Navigate } from "react-router-dom"
// hooks
import { PATH_DASHBOARD } from "routes/paths"
import useAuth from "minimals-template/components/@hooks/useAuth"
import LoadingScreen from "../LoadingScreen"
import { useRetrieveAndDeleteSearchParams } from "lib/@hooks/use-retrieve-and-delete-search-param"
import { useEffect } from "react"
import { isMobileApp } from "routes/@layouts/app/header/is-mobile-app"
// routes
// components

export default function GuestGuard({ children }) {
    const { isAuthenticated, isInitialized } = useAuth()

    const { requiredClient } = useRetrieveAndDeleteSearchParams("requiredClient")
    useEffect(() => {
        if (!isMobileApp()) return () => {}

        document.documentElement.addEventListener("focusin", scrollIn)
        return () => {
            document.documentElement.removeEventListener("focusin", scrollIn)
        }
    }, [])

    if (isAuthenticated) {
        if (requiredClient && requiredClient !== "null" && requiredClient !== null) {
            return <Navigate to={`${PATH_DASHBOARD.root}?requiredClient=${requiredClient}`} />
        }
        return <Navigate to={`${PATH_DASHBOARD.root}`} />
    }

    if (!isInitialized) {
        return <LoadingScreen description="Guest Guard" />
    }

    return <>{children}</>

    function scrollIn({ target }) {
        target?.scrollIntoView({ block: "center", behavior: "smooth" })
    }
}
