import { yupResolver } from "@hookform/resolvers/yup"
import { LoadingButton } from "@mui/lab"
import { Box, Link, Stack, Typography } from "@mui/material"

import { convertToLowercaseEmail } from "lib/case-helper"
import { RouterLink } from "lib/routes/router-link"
import useAuth from "minimals-template/components/@hooks/useAuth"
import { FormProvider, RHFTextField } from "minimals-template/components/hook-form"

import AuthTemplate from "minimals-template/pages/auth/AuthTemplate"
import { useForm } from "react-hook-form"

import { PATH_AUTH } from "routes/paths"

import * as yup from "yup"
import PrivacyAndTerms from "./PrivacyAndTerms"
import { useSearchParams } from "react-router-dom"
import logo from "routes/@assets/PRIMARY LOGO _ LOCKUP _ REVERSED@3x.png"
import { errorSnackbar } from "lib/snackbar/error-snackbar"

const schema = yup.object().shape({
    firstName: yup.string().required("First name required"),
    lastName: yup.string().required("Last name required"),
    email: yup.string().email("Email must be a valid email address").required("Email is required"),
})

export default function PasswordlessRegister() {
    const { register } = useAuth()
    const [searchParams] = useSearchParams()

    const name = searchParams.get("name")
    let requiredClient = searchParams.get("requiredClient")
    let email = searchParams.get("email")
    email = email ? decodeURIComponent(email) : ""

    if (!requiredClient) {
        requiredClient = "register"
    }
    const defaultValues = {
        firstName: name?.split(" ")[0] || "",
        lastName: name?.split(" ")[1] || "",
        email: email || "",
    }

    const methods = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur",
        resolver: yupResolver(schema),
        defaultValues,
    })

    const { handleSubmit } = methods

    const onSubmit = async (data) => {
        try {
            await register(data.email, data.firstName, data.lastName, requiredClient, searchParams.toString())
        } catch (error) {
            errorSnackbar("We were unable to register you.")
        }
    }

    const isInviteLink = name && email && requiredClient

    return (
        <AuthTemplate
            pageTitle={"Register"}
            pageImage={logo}
            headerComponent={
                <Typography variant="body2" align="center" sx={{ mt: { md: -2 } }}>
                    Already have an account? {""}
                    <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.login}>
                        Login
                    </Link>
                </Typography>
            }
        >
            <Stack>
                <Typography variant="h4">{isInviteLink ? "Accept your invite" : "Register an account"}</Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    Register below and we will send you a passcode to sign in to SFG20.
                </Typography>
                <Box sx={{ mt: 2, mb: 2 }}>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={2}>
                            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                                <RHFTextField
                                    autoFocus
                                    name="firstName"
                                    label="First name"
                                    defaultValue={defaultValues.firstName}
                                    autoComplete="off"
                                />
                                <RHFTextField
                                    name="lastName"
                                    label="Last name"
                                    defaultValue={defaultValues.lastName}
                                    autoComplete="off"
                                />
                            </Stack>
                            <RHFTextField
                                transformOut={convertToLowercaseEmail}
                                name="email"
                                label="* Email address"
                                defaultValue={defaultValues.email}
                                autoComplete="off"
                            />

                            <LoadingButton fullWidth size="large" type="submit" variant="contained">
                                {name && email ? "Accept Invite" : "Send Link"}
                            </LoadingButton>
                        </Stack>
                    </FormProvider>
                    <PrivacyAndTerms />
                </Box>
            </Stack>
        </AuthTemplate>
    )
}
