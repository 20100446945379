import { useState } from "react"
import ContactPage from "./pages/ContactPage"
import LicenceSelectPage from "./pages/LicenceSelectPage"
import PurchasePage from "./pages/PurchasePage"

const pageComponents = {
    licenceSelect: LicenceSelectPage,
    contact: ContactPage,
    purchase: PurchasePage,
}

function Paywall() {
    const [page, setPage] = useState("licenceSelect")
    const [selectedLicense, setSelectedLicense] = useState("collaborate")
    const Page = pageComponents[page]

    return <Page setPage={setPage} selectedLicense={selectedLicense} setSelectedLicense={setSelectedLicense} />
}

export default Paywall
