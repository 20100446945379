const colorCache = {}

function colorToRgb(color) {
    // Check the cache first
    if (colorCache[color]) {
        return colorCache[color]
    }

    const tempEl = document.createElement("div")
    tempEl.style.color = color
    document.body.appendChild(tempEl)

    // Get computed style
    const computedColor = getComputedStyle(tempEl).color

    document.body.removeChild(tempEl)

    // Extract RGB components from the computed style
    const [r, g, b] = computedColor.match(/\d+/g).map(Number)

    // Cache the result
    colorCache[color] = [r, g, b]

    return [r, g, b]
}

export function getContrastingColor(color) {
    const [r, g, b] = colorToRgb(color)
    const brightness = (r * 299 + g * 587 + b * 114) / 1000

    return brightness > 128 ? "black" : "white"
}
