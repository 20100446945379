import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export const getLastSelectedClient = api(async function getLastSelectedClient() {
    return query(
        gql`
            query GetLastSelectedClient {
                getLastSelectedClient
            }
        `,
        {},
        { returns: "getLastSelectedClient" }
    )
})
