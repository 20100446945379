/**
 * A wrapper function for event handlers that
 * ensure the event does not bubble.  Very
 * useful when clicks might trigger a higher
 * level click when using an embedded button.
 * @example
 * <Button onClick={prevent(handleClick)}/>
 * @param {function} fn - the event handler that should be allowed to run
 * @param {boolean} [defaultOnly] - whether only preventDefault should be called, otherwise stopPropagation is also called
 * @returns {function}
 */
export function prevent(fn, defaultOnly) {
    return (e, ...params) => {
        if (e) {
            try {
                e.preventDefault()
            } catch (e) {
                //
            }
        }
        if (!defaultOnly && e) e.stopPropagation()
        fn(e, ...params)
    }
}

export function preventPropagation(fn) {
    return (e, ...params) => {
        if (e) {
            e.stopPropagation()
        }
        fn(e, ...params)
    }
}
