// @mui
import { Tooltip } from "@mui/material"
import { alpha } from "@mui/material/styles"
// utils
//
import { IconButtonAnimate } from "minimals-template/components/animate"
import Iconify from "minimals-template/components/Iconify"

// ----------------------------------------------------------------------

export default function ToggleButton({ onToggle }) {
    return (
        <Tooltip title="Settings">
            <IconButtonAnimate
                onClick={onToggle}
                sx={{
                    color: "text.primary",
                    p: 1.25,
                    transition: (theme) => theme.transitions.create("all"),
                    "&:hover": {
                        color: "primary.main",
                        bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
                    },
                }}
            >
                <Iconify icon="eva:options-2-fill" width={20} height={20} />
            </IconButtonAnimate>
        </Tooltip>
    )
}
