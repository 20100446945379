import { useEffect, useMemo, useRef, useState } from "react"
import { useEvent } from "lib/@hooks/useEvent"
import { Box, Portal, useTheme } from "@mui/material"
import { diff3MergeRegions } from "node-diff3"
import { assembleBuffer, color, createHTMLComparisonArray } from "routes/schedule/compare/lib/diff3/utilities"

export function Comparison({ text, original, other, getContent = () => null }) {
    const theme = useTheme()
    const [content, setContent] = useState(null)
    const [position, setPosition] = useState({ x: 0, y: 0 })
    const output = useRef()
    useEvent("MergeOver", (event, type) => {
        switch (type) {
            case "yours": {
                const userContent = getContent("yours", text, original, other)
                if (userContent) {
                    setPosition({ x: event.pageX, y: event.pageY })
                    setContent(userContent)
                }
                break
            }
            case "imported": {
                const userContent = getContent("remote", text, original, other)
                if (userContent) {
                    setPosition({ x: event.pageX, y: event.pageY })
                    setContent(userContent)
                }
                break
            }

            default: {
                const userContent = getContent("conflict", text, original, other)
                if (userContent) {
                    setPosition({ x: event.pageX, y: event.pageY })
                    setContent(userContent)
                }

                break
            }
        }
    })
    useEvent("MergeOut", () => setContent(null))
    const [, reasons] = useMemo(() => compare(text, original, other), [text, other, original])
    useEffect(() => {
        if (!output.current) {
            return
        }
        output.current.innerHTML = reasons
    }, [reasons])
    let tip = null
    if (content) {
        tip = (
            <Box
                sx={{
                    position: "absolute",
                    width: 350,
                    fontSize: "90%",
                    wordBreak: "break-word",
                    left: position.x - 100,
                    top: position.y + 20,
                    bgcolor: "background.default",
                    borderRadius: 1,
                    border: "1px solid",
                    borderColor: "action.disabled",
                }}
                width={200}
            >
                <Box p={2}>{content}</Box>
            </Box>
        )
    }
    return (
        <>
            <Portal>{tip}</Portal>
            <Box
                sx={{
                    ".text-difference": {
                        color: `${theme.palette.primary.main} !important`,
                        backgroundColor: theme.palette.action.hover,
                    },
                }}
                style={{
                    overflow: "visible",
                    wordBreak: "break-word",
                }}
                ref={output}
            />
        </>
    )
}

function events(...params) {
    const command = `raise("MergeOver", event, ${params
        .map((p) => `\`${p.toString().replaceAll("'", "&apos;")}\``)
        .join(", ")})`
    return `onmouseover='${command}' onmouseout='raise("MergeOut")'`
}

function compare(a, o, b) {
    const regions = diff3MergeRegions(
        createHTMLComparisonArray(a),
        createHTMLComparisonArray(o),
        createHTMLComparisonArray(b)
    )

    const combined = []
    const reasons = []

    for (const region of regions) {
        if (region.stable) {
            combined.push(region.bufferContent.join(" "))
            if (region.bufferContent.length === 1 && region.bufferContent[0] === "\n") region.buffer = "o"
            switch (region.buffer) {
                case "o":
                    reasons.push(assembleBuffer(region.bufferContent, "INSERTHERE", true))
                    break
                case "a":
                    reasons.push(
                        assembleBuffer(
                            region.bufferContent,
                            `<span class="text-difference" ${color(window.currentTheme.palette.primary.main)} ${events(
                                "yours",
                                region.bufferContent.join(" ")
                            )}>INSERTHERE</span>`
                        )
                    )

                    break
                default:
                    reasons.push(
                        assembleBuffer(
                            region.bufferContent,
                            `<span class="text-difference" ${color(window.currentTheme.palette.success.main)} ${events(
                                "imported",
                                region.bufferContent.join(" ")
                            )}>INSERTHERE</span>`
                        )
                    )

                    break
            }
        } else {
            combined.push(region.aContent.join(" "))
            reasons.push(
                assembleBuffer(
                    region.aContent,
                    `<span class="text-difference" ${color(
                        region.aContent.join(" ") !== region.bContent.join(" ")
                            ? window.currentTheme.palette.warning.main
                            : window.currentTheme.palette.primary.main
                    )} ${events(
                        region.aContent.join(" ") !== region.bContent.join(" ") ? "conflict" : "yours",
                        region.bContent.join(" ")
                    )} >INSERTHERE</span>`
                )
            )
        }
    }
    return [combined.join(" "), reasons.join(" ")]
}

export function ImportedChange({ change }) {
    return (
        <Box>
            <Box fontWeight="bold" mb={1}>
                This is a change to the original that did not conflict with any changes and so it has been included.
            </Box>
            <Box sx={{ maxHeight: 300, overflow: "auto" }} dangerouslySetInnerHTML={{ __html: change }} />{" "}
        </Box>
    )
}

export function YourChange({ change }) {
    return (
        <Box>
            <Box mb={1} fontWeight="bold">
                This is a change to the original
            </Box>
            <Box sx={{ maxHeight: 300, overflow: "auto" }} dangerouslySetInnerHTML={{ __html: change }} />
        </Box>
    )
}

export function ConflictChange({ change }) {
    return (
        <Box>
            <Box sx={{ fontWeight: "bold", mb: 1 }}>This change was selected, but it overwrote another change</Box>
            <Box mt={1}>Other change:</Box>
            <Box sx={{ fontStyle: "italic" }}>{change}</Box>
        </Box>
    )
}
