import { RootPlugs } from "slot-definitions"
import { useEffect } from "react"
import { keyBy } from "lib/keyBy"
import { pick } from "lib/pick"
import { getAllSkillCodes } from "plugins/skill-codes/controller/get-all-skill-codes"
import { useSkills } from "routes/@lib/skills"

RootPlugs.plug(<SkillCodes />)

function SkillCodes() {
    const allSkillCodes = getAllSkillCodes.useResults()
    const [, setSkillCodes] = useSkills()
    useEffect(() => {
        if (allSkillCodes) {
            setSkillCodes(keyBy(allSkillCodes, pick("CoreSkillingID")))
        }
    }, [allSkillCodes, setSkillCodes])
    return null
}
