import noop from "lib/noop"
import ReactDOM from "react-dom/client"
import { useCallback, useEffect, useRef, useState } from "react"
import { BoundContext } from "lib/@components/binding/use-bound-context"

const node = document.createElement("div")
const root = ReactDOM.createRoot(node)

/**
 * Renders a react component as an HTML string
 * @param content
 * @param context
 * @param {function} cb - callback for the end of rendering
 * @returns {Promise<string>} promise for the HTML of the component
 */
export function renderAsHtml(content, context, cb = noop) {
    let resolver = noop

    // eslint-disable-next-line no-async-promise-executor
    return new Promise((resolve) => {
        resolver = () => {
            const { innerHTML } = node

            resolve(innerHTML)
            cb(innerHTML, node)
        }
        root.render(
            <BoundContext.Provider value={context}>
                <RenderComponent content={content} resolver={resolver} />
            </BoundContext.Provider>
        )
    })
}

function RenderComponent({ content, resolver }) {
    useEffect(() => {
        setTimeout(resolver, 500)
    }, [resolver])
    return content
}

/**
 * Renders a react component as a text string
 * @param Component
 * @param {function} cb - callback for the end of rendering
 * @returns {Promise<string>} promise for the text of the component
 */
export function renderAsText(Component, cb = noop) {
    const node = document.createElement("div")
    const root = ReactDOM.createRoot(node)
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
        root.render(Component, () => {
            const { innerText } = node
            resolve(innerText)
            cb(innerText, node)
        })
    })
}

export function RenderIfHasContent({ children, content }) {
    const [show, setShow] = useState(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const checkSize = useCallback(_checkSize, [content])
    return (
        <>
            <ContentRender content={content} onHasSize={checkSize} />
            {!!show && children}
        </>
    )

    function _checkSize(width, height) {
        setShow(width !== 0 && height !== 0)
    }
}

export function RenderIfHasNoContent({ children, content }) {
    const [show, setShow] = useState(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const checkSize = useCallback(_checkSize, [content])
    return (
        <>
            <ContentRender content={content} onHasSize={checkSize} />
            {!show && children}
        </>
    )

    function _checkSize(width, height) {
        setShow(width !== 0 && height !== 0)
    }
}

function ContentRender({ content, onHasSize = noop }) {
    const ref = useRef()
    useEffect(() => {
        if (ref.current) {
            const rect = ref.current.getBoundingClientRect()
            onHasSize(rect.width, rect.height)
        }
    }, [content, onHasSize])
    return (
        <div
            ref={ref}
            style={{
                position: "absolute",
                visibility: "hidden",
                pointerEvents: "none",
            }}
        >
            {content}
        </div>
    )
}
