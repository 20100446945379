import { ErrorBoundary } from "lib/@components/busy/error-boundary"
import React from "react"
import { BusyComponent } from "lib/@components/busy/index"
import { generate } from "library/guid"
import { BusyAdd, BusyRemoved, Navigate } from "event-definitions"

export const Busy = () => (
    <ErrorBoundary>
        <BusyComponent />
    </ErrorBoundary>
)

Navigate.before.handleOnce(() => {
    const id = generate()
    BusyAdd.raise({ id })
    setTimeout(() => {
        BusyRemoved.raise({ id })
    }, 250)
})
