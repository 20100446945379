import { AnimatePresence, m } from "framer-motion"
import { useEffect, useState } from "react"
// @mui
import { Backdrop, Box, Divider, IconButton, Stack, Typography } from "@mui/material"
import { alpha, styled } from "@mui/material/styles"
// hooks
import useSettings from "minimals-template/components/@hooks/useSettings"
// utils
import cssStyles from "minimals-template/components/utils/cssStyles"
// config
import { defaultSettings, NAVBAR } from "config"
//
import { varFade } from "minimals-template/components/animate"
import Iconify from "minimals-template/components/Iconify"
//
import { useAsync } from "lib/@hooks/useAsync"
import SettingContrast from "minimals-template/components/settings/drawer/SettingContrast"
import SettingFullscreen from "minimals-template/components/settings/drawer/SettingFullscreen"
import SettingMode from "minimals-template/components/settings/drawer/SettingMode"
import SettingStretch from "minimals-template/components/settings/drawer/SettingStretch"
import ToggleButton from "minimals-template/components/settings/drawer/ToggleButton"
import { createPortalPair } from "lib/portals"
import { HeaderBarRight } from "slot-definitions"
import { clientInfo } from "lib/version/client-info"
import { serverVersion } from "lib/version/server-version"
import { DATE_FORMATS } from "lib/@constants/date-format"

// ----------------------------------------------------------------------

const RootStyle = styled(m.div)(({ theme }) => ({
    ...cssStyles(theme).bgBlur({ color: theme.palette.background.paper, opacity: 0.92 }),
    top: "env(safe-area-inset-top)",
    right: 0,
    bottom: 0,
    display: "flex",
    position: "fixed",
    overflow: "hidden",
    width: NAVBAR.BASE_WIDTH,
    flexDirection: "column",
    margin: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    zIndex: theme.zIndex.drawer + 3,
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    boxShadow: `-24px 12px 32px -4px ${alpha(
        theme.palette.mode === "light" ? theme.palette.grey[500] : theme.palette.common.black,
        0.16
    )}`,
}))

// ----------------------------------------------------------------------

export const [SettingsButtonPortal, SettingsButtonComponent] = createPortalPair()
HeaderBarRight.plug(<SettingsButtonPortal priority={50} />)

export default function SettingsDrawer() {
    const { themeMode, themeLayout, themeStretch, themeContrast, themeDirection, themeColorPresets, onResetSetting } =
        useSettings()

    const [open, setOpen] = useState(false)

    const notDefault =
        themeMode !== defaultSettings?.themeMode ||
        themeLayout !== defaultSettings?.themeLayout ||
        themeStretch !== defaultSettings?.themeStretch ||
        themeContrast !== defaultSettings?.themeContrast ||
        themeDirection !== defaultSettings?.themeDirection ||
        themeColorPresets !== defaultSettings?.themeColorPresets

    const varSidebar =
        themeDirection !== "rtl"
            ? varFade({
                  distance: NAVBAR.BASE_WIDTH,
                  durationIn: 0.32,
                  durationOut: 0.32,
              }).inRight
            : varFade({
                  distance: NAVBAR.BASE_WIDTH,
                  durationIn: 0.32,
                  durationOut: 0.32,
              }).inLeft

    useEffect(() => {
        if (open) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = ""
        }
    }, [open])

    const handleToggle = () => {
        setOpen((prev) => !prev)
    }

    const handleClose = () => {
        setOpen(false)
    }

    // eslint-disable-next-line react/no-unstable-nested-components
    const Version = () => {
        // serverVersion()
        const sv = useAsync(serverVersion, "...")
        const info = clientInfo()
        return (
            <Box m={1} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                <Stack spacing={1}>
                    <Typography variant="caption">Client version: {info.version}</Typography>
                    <Typography variant="caption" gutterBottom sx={{ color: "text.secondary" }}>
                        Built at: {new Date(clientInfo().launchDate).format(DATE_FORMATS.long)}
                    </Typography>
                    <Typography variant="caption">Server version: {sv?.version}</Typography>
                    {sv?.startTime && (
                        <Typography variant="caption" gutterBottom sx={{ color: "text.secondary" }}>
                            Deployed at: {new Date(sv?.startTime).format(DATE_FORMATS.long)}
                        </Typography>
                    )}
                </Stack>
            </Box>
        )
    }

    return (
        <>
            <Backdrop
                open={open}
                onClick={handleClose}
                sx={{ background: "transparent", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            />

            <SettingsButtonComponent>
                <ToggleButton open={open} notDefault={notDefault} onToggle={handleToggle} />
            </SettingsButtonComponent>

            <AnimatePresence>
                {open && (
                    <>
                        <RootStyle {...varSidebar}>
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{ py: 2, pr: 1, pl: 2.5 }}
                            >
                                <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                                    Settings
                                </Typography>

                                <IconButton onClick={onResetSetting}>
                                    <Iconify icon={"ic:round-refresh"} width={20} height={20} />
                                </IconButton>

                                <IconButton onClick={handleClose} data-cy="settings-close">
                                    <Iconify icon={"eva:close-fill"} width={20} height={20} />
                                </IconButton>
                            </Stack>

                            <Divider sx={{ borderStyle: "dashed" }} />

                            <Stack spacing={3} sx={{ p: 3 }}>
                                <Stack spacing={1.5}>
                                    <Typography variant="subtitle2">Mode</Typography>
                                    <SettingMode />
                                </Stack>

                                <Stack spacing={1.5}>
                                    <Typography variant="subtitle2">Contrast</Typography>
                                    <SettingContrast />
                                </Stack>

                                <Stack spacing={1.5}>
                                    <Typography variant="subtitle2">Stretch</Typography>
                                    <SettingStretch />
                                </Stack>

                                <SettingFullscreen />
                            </Stack>

                            <Version />
                        </RootStyle>
                    </>
                )}
            </AnimatePresence>
        </>
    )
}
