import { nanoid } from "nanoid"
import { useContext, useMemo } from "react"
import { BoundContext } from "lib/@components/binding/use-bound-context"

export const dontChange = Symbol("Don't Change")

/**
 * Binds data in the context to properties passed in,
 * `target` is the most common parameter that is used by all
 * other bound components to find an object to modify
 * @type {React.FunctionComponent}
 */
export function Bound({ children, ...props }) {
    const context = useContext(BoundContext)
    context.target = context.target || {}
    const id = useMemo(() => nanoid(), [])
    if (props.onChange && context.onChange) {
        const fn = props.onChange
        props.onChange = () => {
            // noinspection JSIncompatibleTypesComparison,JSVoidFunctionReturnValueUsed
            if (fn() === false) return
            context.onChange()
        }
    }
    if (props.refresh) {
        props.parentRefresh = context.refresh
    }
    return <BoundContext.Provider value={{ ...context, ...props, id }}>{children}</BoundContext.Provider>
}
