import { Stack } from "@mui/material"
import { BoundTextField } from "lib/@components/binding/bound-components"
import { BoundTextEditor } from "minimals-template/components/editor"

export function ContactForm() {
    return (
        <Stack spacing={1} sx={{ mt: 2 }}>
            <BoundTextField field="subject" name="subject" label="Subject" disabled />
            <BoundTextEditor
                className="contactUsMessageEditor"
                simple={true}
                sx={{ flex: 1, "& .jodit-workplace": { height: "300px !important" } }}
                field="message"
                name="message"
                label="Message"
            />
        </Stack>
    )
}
