import { Backdrop, Box, IconButton, Menu, Portal } from "@mui/material"
import { useRef, useState } from "react"
import { Bound } from "lib/@components/binding/Bound"
import Iconify from "minimals-template/components/Iconify"
import { BlockClicks } from "lib/@components/block-clicks"
import noop from "lib/noop"
import { prevent } from "lib/prevent"
import { CloseMenu, OpenDialog, RemoveDialog } from "event-definitions"
import { createSlot } from "lib/@components/slot/create-slot"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export const ContextMenu = createSlot("ContextMenu")

export function ContextMenuBar({ type, children, ...props }) {
    const { autoExpandMenu } = useBoundContext()
    const ref = useRef()
    const items = ContextMenu.useTypedSlot(type, undefined, props)
    const [anchor, setAnchor] = useState(null)
    OpenDialog.after.useEvent(() => close())
    RemoveDialog.after.useEvent(() => close())
    CloseMenu.useEvent(close)
    if (!items.length && !children) return null
    return autoExpandMenu ? (
        <Box>
            {children}
            {items.sort(inPriorityOrderByProperty)}
        </Box>
    ) : (
        <Bound
            closeMenu={close}
            close={(fn) => () => {
                close()
                fn()
            }}
            contextMenuItems={items.length}
        >
            <BlockClicks>
                <IconButton
                    className={`context-menu-bar ${type}`}
                    sx={{
                        mx: 0.4,
                        width: 32,
                        height: 32,
                        color: (theme) => theme.palette.text.primary,
                        background: (theme) => `${theme.palette.background.default}40`,
                        backdropFilter: "blur(8px)",
                    }}
                    ref={ref}
                    size="small"
                    onClick={open}
                >
                    <Iconify icon="carbon:overflow-menu-vertical" />
                </IconButton>
                <Portal>
                    {Boolean(anchor) && (
                        <Backdrop
                            onClick={prevent(close)}
                            onMouseDown={prevent(noop)}
                            onTouchStart={prevent(noop)}
                            sx={{ zIndex: 2000 }}
                            open={Boolean(anchor)}
                        >
                            <BlockClicks>
                                <Menu
                                    onClose={close}
                                    sx={{ zIndex: 2001, "& .MuiMenuItem-root": { fontSize: "0.8rem !important" } }}
                                    anchorEl={anchor}
                                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                                    open={Boolean(anchor)}
                                    className="context-menu"
                                >
                                    {children}
                                    {items.sort(inPriorityOrderByProperty)}
                                </Menu>
                            </BlockClicks>
                        </Backdrop>
                    )}
                </Portal>
            </BlockClicks>
        </Bound>
    )

    function open() {
        setAnchor(ref.current)
    }

    function close() {
        setAnchor(null)
    }
}

function inPriorityOrderByProperty(a, b) {
    const p1 = a.props.priority ?? 100
    const p2 = b.props.priority ?? 100
    return p1 - p2
}
