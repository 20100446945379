import { useCallback, useEffect, useRef, useState } from "react"

import { getLastSelectedClient } from "lib/user/get-last-selected-client"
import { useSearchParams } from "react-router-dom"
import { getCurrentUserClients } from "lib/user/use-user-clients"
import { resetActiveUserClient, setActiveUserClient } from "lib/user/set-user-active-client"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import useAuth from "minimals-template/components/@hooks/useAuth"
import { useAsyncWithStatus } from "lib/@hooks/useAsync"

export function useSelectedClient() {
    const { isAuthenticated } = useAuth()
    const [searchParams, setSearchParams] = useSearchParams()
    const { loading: clientsLoading, data: clients } = getCurrentUserClients.useResults.status()
    const requiredClientFromParams = searchParams.get("requiredClient")
    const [selectedClient, setSelectedClient] = useState(requiredClientFromParams)
    useEffect(() => {
        if (!isAuthenticated) return
        if (requiredClientFromParams) {
            searchParams.delete("requiredClient")
            setSearchParams(searchParams)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, requiredClientFromParams, setSearchParams])

    const {
        loading,
        error,
        data: lastSelectedClient,
    } = useAsyncWithStatus(
        async () => {
            if (!isAuthenticated) return null
            if (selectedClient) return selectedClient
            return getLastSelectedClient()
        },
        "uninitialised",
        isAuthenticated
    )

    let [selectedClientInitialised, setInitialised] = useState(false)
    if (!loading && !error && lastSelectedClient !== "uninitialised" && selectedClientInitialised === false) {
        setInitialised(true)
    }

    if (!loading) {
        if (clients && clients.length > 0) {
            if (
                selectedClientInitialised &&
                selectedClient &&
                !clients.find((client) => client.id === selectedClient)
            ) {
                setSelectedClient(null)
            }
        }
    }

    const executionId = useRef()
    useEffect(() => {
        executionId.current = Date.now()

        if (!isAuthenticated || loading || error) return

        const currentExecutionId = executionId.current

        if (!selectedClient && !requiredClientFromParams && lastSelectedClient) {
            setSelectedClient(lastSelectedClient)
            return
        }

        const shouldSetClientTo = requiredClientFromParams || lastSelectedClient

        if (!shouldSetClientTo) return

        setActiveUserClient(shouldSetClientTo)
            .then((success) => {
                if (executionId.current !== currentExecutionId) return
                if (success) {
                    setSelectedClient(shouldSetClientTo)
                } else {
                    console.error("Failed to set active client (1)")
                    setSelectedClient(null)
                }
            })
            .catch(() => {
                console.error("Failed to set active client (2)")
                setSelectedClient(null)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, lastSelectedClient, requiredClientFromParams, loading, error])

    const setClient = useCallback(
        async function setClientHandler(client) {
            if (!isAuthenticated) return
            try {
                const success = await setActiveUserClient(client)
                if (success) {
                    setSelectedClient(client)
                } else {
                    errorSnackbar("Failed to set active client")
                }
            } catch (e) {
                errorSnackbar("Failed to set active client")
            }
        },
        [isAuthenticated]
    )

    const resetSelectedClient = useCallback(async function resetSelectedClientHandler() {
        if (!isAuthenticated) return
        try {
            const success = await resetActiveUserClient()
            if (success) {
                setSelectedClient(null)
            } else {
                errorSnackbar("Failed to reset active client")
            }
        } catch (e) {
            errorSnackbar("Failed to reset active client")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        selectedClientInitialised,
        loading,
        clientsLoading,
        clients,
        setSelectedClient: setClient,
        selectedClient,
        resetSelectedClient,
    }
}
