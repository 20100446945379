import { gql } from "@apollo/client"
import { mutate } from "lib/graphql/mutate"
import { errorSnackbar } from "lib/snackbar/error-snackbar"

export async function addScheduleToTag(basketId, referenceId) {
    try {
        await mutate(
            gql`
                mutation AddScheduleToTag($basketId: String!, $referenceId: String!) {
                    addItemsToBasket(id: $basketId, items: [{ referenceId: $referenceId, id: $referenceId }])
                }
            `,
            {
                basketId,
                referenceId,
            }
        )
    } catch (e) {
        errorSnackbar(e.message)
    }
}
