import { useCurrentUser } from "lib/store/use-current-user"
import { Alert, Box, Button, Divider, Grid, Stack, Typography } from "@mui/material"
import Iconify from "../../../minimals-template/components/Iconify"
import { getMyShareLinks } from "routes/shared-with-me/controller/get-my-share-links"
import { loadStripe } from "@stripe/stripe-js"
import { getNewStripeSession } from "../controller/get-new-stripe-session"
import { getStripePrices } from "../controller/get-stripe-prices"
import { useEffect, useState } from "react"
import { getStripePublishableKey } from "../controller/get-stripe-publishable-key"
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js"
import { displayIntervalLookup, durationInSeconds, pageNames } from "../paywall-constants"
import { useUserMetadata } from "lib/store/user"
import { formatDurationStrict } from "../paywall-utils"

const colours = ["#38939B", "#E48632", "#EDBA41"]

export default function PurchasePage({ setPage }) {
    const userobj = useCurrentUser()
    const meta = useUserMetadata()
    const { id } = meta || {}

    const { data: getStripePublishableKeyData } = getStripePublishableKey.useResults.status()
    const { getStripePublishableKey: stripe_publishable_key } = getStripePublishableKeyData || {}

    const { data: getStripePricesData } = getStripePrices.useResults.status()
    const links = getMyShareLinks.useResults()
    const hasLinksToView = links?.total > 0

    const [stripeSessionSecret, setStripeSessionSecret] = useState(null)
    const [stripePromise, setStripePromise] = useState(null)

    const { getStripePrices: prices } = getStripePricesData || {}

    const showCheckout = stripeSessionSecret && stripePromise

    const showPrices = prices?.slice(0, 3).sort((a, b) => a.unit_amount - b.unit_amount)

    useEffect(() => {
        if (stripe_publishable_key) {
            setStripePromise(loadStripe(stripe_publishable_key))
        }
    }, [stripe_publishable_key])

    return isPersonalUser(userobj) ? (
        <Stack spacing={2} sx={{ mt: 2 }}>
            <Typography variant="h4">Purchase a collaborate licence</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Select one of the available plans below, both one off and subscription access is available
            </Typography>
            <Divider flexItem sx={{ borderStyle: "dashed" }} />

            {!showCheckout ? (
                <>
                    <Box
                        sx={{
                            display: "flex",
                            gap: 2,
                            justifyContent: "space-between",
                            flexDirection: { lg: "row", xs: "column" },
                            alignItems: "center",
                        }}
                    >
                        {showPrices?.map((price, index) => {
                            const { id, unit_amount, recurring, product } = price
                            const { metadata } = product || {}
                            let interval, duration, formattedDuration

                            if (recurring) {
                                formattedDuration = `${recurring.interval_count} ${recurring.interval}`
                                ;({ interval } = recurring)
                                duration = recurring.interval_count * durationInSeconds[recurring.interval]
                            } else if (metadata?.interval) {
                                ;({ interval } = metadata)
                                ;({ duration } = metadata)
                                formattedDuration = formatDurationStrict(duration)
                            }
                            const displayInterval = displayIntervalLookup[interval]

                            const displayPrice = (unit_amount / 100).toLocaleString("en-gb", {
                                style: "currency",
                                currency: "GBP",
                            })

                            const paymentPeriod = recurring ? `Billed ${displayInterval}` : "One off payment"
                            return (
                                <Box
                                    key={id}
                                    sx={{
                                        display: "inline-flex",
                                        gap: 1,
                                        flexDirection: "column",
                                        width: { xs: "250px", lg: "250px" },
                                    }}
                                >
                                    <Typography variant="h4" sx={{ textTransform: "capitalize" }}>
                                        {formattedDuration} Access
                                    </Typography>
                                    <Box
                                        sx={{
                                            alignItems: "center",
                                            display: "inline-flex",
                                            gap: "10px",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: colours[index],
                                                fontSize: "48px",
                                                fontWeight: "700",
                                                lineHeight: "36px",
                                            }}
                                        >
                                            {displayPrice}
                                        </Typography>
                                        <Typography variant="body2" sx={{ maxWidth: "80px", minHeight: "50px" }}>
                                            {recurring && "Subscription /"}
                                            {displayInterval}
                                        </Typography>
                                    </Box>
                                    <Typography variant="body2">{paymentPeriod}</Typography>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        sx={{ bgcolor: colours[index] }}
                                        onClick={() => handlePurchaseButton(id)}
                                    >
                                        Purchase
                                    </Button>
                                </Box>
                            )
                        })}
                    </Box>
                </>
            ) : (
                <>
                    {!hasLinksToView && (
                        <Alert severity="warning">
                            No valid links are shared with you. Buying a license will not give you access to any links.
                        </Alert>
                    )}
                    <div data-cy="stripe-checkout">
                        <EmbeddedCheckoutProvider
                            stripe={stripePromise}
                            options={{ clientSecret: stripeSessionSecret }}
                        >
                            <EmbeddedCheckout />
                        </EmbeddedCheckoutProvider>
                    </div>
                </>
            )}
            <Grid container sx={{ mt: 2, mb: 6, gap: 1, flexDirection: "row-reverse" }}>
                <Button
                    variant="contained"
                    startIcon={<Iconify icon="ic:round-keyboard-backspace" />}
                    sx={{
                        whiteSpace: "nowrap",
                    }}
                    onClick={() => setPage(pageNames.licenceSelect)}
                >
                    Back
                </Button>
                {showCheckout && (
                    <Button
                        variant="contained"
                        startIcon={<Iconify icon="ic:round-cancel" />}
                        sx={{
                            whiteSpace: "nowrap",
                        }}
                        onClick={() => setStripeSessionSecret(null)}
                    >
                        Cancel
                    </Button>
                )}
            </Grid>
        </Stack>
    ) : null

    async function handlePurchaseButton(priceId) {
        try {
            setStripeSessionSecret(false)
            const recurring = prices.find((p) => p.id === priceId)?.recurring ? true : false
            const result = await getNewStripeSession(priceId, recurring, id)
            setStripeSessionSecret(result)
        } catch (e) {
            console.error("handlePurchaseButton error: ", e)
        }
    }
}
function isPersonalUser(user) {
    return user?.client?.split(":")[0] === user.id
}
