import { isChildOf } from "lib/@components/editable/isChildOf"

export function getCurrentCursorPosition(parentId) {
    const selection = window.getSelection()
    let charCount = -1
    let node

    if (selection.focusNode) {
        if (isChildOf(selection.focusNode, parentId)) {
            node = selection.focusNode
            charCount = selection.focusOffset

            while (node) {
                if (node.id === parentId) {
                    break
                }

                if (node.previousSibling) {
                    node = node.previousSibling
                    charCount += node.textContent.length
                } else {
                    node = node.parentNode
                    if (node === null) {
                        break
                    }
                }
            }
        }
    }

    return charCount
}
