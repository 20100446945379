import { EnsureTree, GroupExpanded, RefreshScheduleItem } from "event-definitions"
import { useRefresh } from "lib/@hooks/useRefresh"
import { getTreeId } from "routes/schedule/lib/getTreeId"
import { useTreeItemIndex } from "routes/schedule/lib/useTreeItemIndex"
import { useRef } from "react"
import { resolveAsFunction } from "lib/resolve-as-function"

export function useTreeItem(id, alwaysRefreshOrFn) {
    const alwaysRefresh = resolveAsFunction(alwaysRefreshOrFn)()
    const result = useRef()
    const tree = getTreeId(id)

    if (tree) {
        EnsureTree.raise(tree)
    }
    const refresh = useRefresh()
    RefreshScheduleItem(id).useEvent(() => {
        if (!result.current || alwaysRefresh) refresh()
    })
    const treeIndex = useTreeItemIndex(!result.current || alwaysRefresh)

    result.current = treeIndex.retrieve(id)

    GroupExpanded.useEvent(() => {
        if (!result.current) {
            refresh()
        }
    })
    return result.current
}

//here
