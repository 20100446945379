import { Outlet } from "react-router-dom"
// @mui
import { styled } from "@mui/material/styles"
// components
import Logo from "minimals-template/components/Logo"

// ----------------------------------------------------------------------

const HeaderStyle = styled("header")(({ theme }) => ({
    top: "env(safe-area-inset-top)",
    left: 0,
    lineHeight: 0,
    width: "100%",
    position: "absolute",
    padding: theme.spacing(3, 3, 0),
    [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(5, 5, 0),
    },
}))

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
    return (
        <>
            <HeaderStyle>
                <Logo href="/" />
            </HeaderStyle>
            <Outlet />
        </>
    )
}
