import { PreventReload, WindowReload } from "event-definitions"

WindowReload.after.handle((url) => {
    const shouldPreventReload = PreventReload.call()
    if (shouldPreventReload) {
        return
    }
    if (url) {
        const ensureReload = new Date().getTime()
        const newLocation = url.includes("?") ? `${url}&_=${ensureReload}` : `${url}?_=${ensureReload}`
        window.location.href = newLocation
    } else {
        window.location.reload(true)
    }
})
