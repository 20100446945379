import { useEffect, useMemo, useRef, useState } from "react"
import { useCurrentState } from "lib/@hooks/useCurrentState"

export const CancelAsync = Symbol("CancelAsync")
export const Terminated = Symbol("Terminated")

export class TerminatedError extends Error {
    constructor(message = "Terminated") {
        super(message)
        this.name = this.constructor.name
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, this.constructor)
        }
    }
}

export function useAsync(
    promiseProducer,
    defaultValue = null,
    refId = "standard",
    check = () => false,
    logKey = false
) {
    promiseProducer = typeof promiseProducer !== "function" ? async () => promiseProducer : promiseProducer

    const executionId = useRef()
    const key = typeof refId === "object" ? JSON.stringify(refId) : refId
    const value = useRef(defaultValue)
    const [, setResult] = useCurrentState(0)
    useEffect(
        () => () => {
            executionId.current = Terminated
        },
        []
    )
    useMemo(() => {
        executionId.current = key
        if (logKey) console.log("Running", key)
        run()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key])
    return value.current

    function run() {
        runMe().catch(console.error)

        async function runMe() {
            if (key !== executionId.current) {
                //console.log("Not starting async", key, executionId.current)
                return
            }
            try {
                const result = await promiseProducer(update, key, value.current)
                if (key !== executionId.current) {
                    //console.log("Not using async result", key, executionId.current)
                    return
                }
                if (result === CancelAsync) {
                    // console.log("Cancelling async", key)
                    return
                }

                if (
                    !check(value.current, result) &&
                    !!result &&
                    Object.isEqual(value.current, result || defaultValue || result)
                ) {
                    return
                }
                value.current = result === null ? null : result ?? defaultValue ?? result

                setResult((prev) => prev + 1)
            } catch (e) {
                if (!(e instanceof TerminatedError)) {
                    console.error(e)
                    value.current = e
                    setResult((prev) => prev + 1)
                }
            }
        }
    }

    function update(result) {
        if (executionId.current === Terminated) throw new TerminatedError()
        value.current = result
        setResult((prev) => prev + 1)
    }
}

/**
 * A custom React hook to fetch asynchronous data with loading and error status.
 *
 * @param {Function|Promise} promiseProducer - The async function or promise to run.
 * @param {any} [defaultValue=null] - Default value to return while loading or on error.
 * @param {string|object} [refId="standard"] - A reference ID or object to differentiate different usages.
 * @param {Function} [check=() => false] - A function that returns a boolean to perform additional conditional checks.
 *
 * @param {Boolean} logKey - Whether to log the keys
 * @returns {Object} - Returns an object with `data`, `error`, and `loading` properties.
 *
 * @example
 * const { data, error, loading } = useAsyncWithStatus(fetchData, null, 'fetchData')
 * if (loading) return <Loader />
 * if (error) return <ErrorComponent message={error.message} />
 * return <DataComponent data={data} />
 */

export function useAsyncWithStatus(
    promiseProducer,
    defaultValue = null,
    refId = "standard",
    check = () => false,
    logKey = false
) {
    promiseProducer = typeof promiseProducer !== "function" ? async () => promiseProducer : promiseProducer

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const data = useAsync(
        async (...producerParams) => {
            try {
                setError(null)
                setLoading(true)
                const result = await promiseProducer(...producerParams)
                setError(null)
                return result
            } catch (e) {
                setError(e)
                return null
            } finally {
                setLoading(false)
            }
        },
        defaultValue,
        refId,
        check,
        logKey
    )
    return { data, loading, error }
}

useAsync.bind = function bindAsync(fn, defaultValue = null, refId = "standard") {
    return function useBinding(...params) {
        return useAsync(async () => fn(...params), defaultValue, refId)
    }
}

export default useAsync
