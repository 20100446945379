import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { inTransition, useRefresh } from "lib/@hooks/useRefresh"
import { FieldUpdate } from "event-definitions"
import { get } from "lib/get"
import { resolveValue } from "lib/resolve-as-function"
import { useCallback, useRef } from "react"
import { set } from "lib/set"
import noop from "lib/noop"

export function useBoundValue(path = "", defaultValue = undefined, options = {}) {
    const { target = {}, readOnly, onChange = noop } = useBoundContext()
    const localRefresh = useRefresh(inTransition)
    FieldUpdate(`**`).useEvent(updateIfNecessary, [target, path])

    const value = get(target, path, resolveValue(defaultValue))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const setValueFn = useCallback(setValue, [target, path])
    const lastValue = useRef()
    lastValue.current = value
    return [value, setValueFn]

    function setValue(v) {
        if (readOnly) return
        if (typeof v === "function") {
            v = v(get(target, path, resolveValue(defaultValue)))
        }
        if (v !== get(target, path, resolveValue(defaultValue))) {
            if (v === undefined && !path.includes(".")) {
                delete target[path]
            } else {
                set(target, path, v)
            }
            if (!options.noFieldUpdate) FieldUpdate(path).raise(v, target)
            if (!options.noChangeNotification) onChange()
        }
    }

    function updateIfNecessary(v, owner) {
        if (owner === target) {
            if (path.startsWith(this.event.slice(13))) {
                const currentValue = get(target, path, resolveValue(defaultValue))
                if (currentValue !== lastValue.current) {
                    localRefresh()
                }
            }
        }
    }
}
