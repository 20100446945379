import PropTypes from "prop-types"
// @mui
import { Box, List } from "@mui/material"
// hooks
import useLocales from "minimals-template/components/@hooks/useLocales"
//
import NavList from "minimals-template/components/nav-section/vertical/NavList"
import { ListSubheaderStyle } from "minimals-template/components/nav-section/vertical/style"
import { WhoAmI } from "minimals-template/components/nav-section/vertical/who-am-i"

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
    isCollapse: PropTypes.bool,
    navConfig: PropTypes.array,
}

export default function NavSectionVertical({ navConfig, isCollapse, ...other }) {
    const { translate } = useLocales()

    return (
        <Box {...other}>
            <WhoAmI />
            {navConfig.map((group) => (
                <List
                    data-cy={`navItem-group-${group.subheader?.toLowerCase()}`}
                    key={group.subheader}
                    disablePadding
                    sx={{ px: 2 }}
                >
                    {group.subheader && (
                        <ListSubheaderStyle
                            sx={{
                                ...(isCollapse && {
                                    opacity: 0,
                                }),
                            }}
                        >
                            {translate(group.subheader)}
                        </ListSubheaderStyle>
                    )}

                    {group.items.map((list) => (
                        <NavList
                            key={list.title + list.path}
                            data={list}
                            depth={1}
                            hasChildren={!!list.children}
                            isCollapse={isCollapse}
                        />
                    ))}
                </List>
            ))}
        </Box>
    )
}
