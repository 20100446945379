import { Box, Link, Menu, MenuItem, Typography } from "@mui/material"
import { ErrorBoundary } from "ErrorBoundary"
import { AdjustStack, GetCrumbLink } from "event-definitions"
import noop from "lib/noop"
import { resolveAsFunction } from "lib/resolve-as-function"
import { StandardBreadcrumbs } from "lib/@components/standardBreadcrumbs"
import { StandardLastBreadcrumb } from "lib/@components/standardLastBreadcrumb"
import { SCHEDULE } from "library/constants"
import { splitId } from "library/database/split-id"
import { TreeRoot } from "library/tree"
import { useRef, useState } from "react"
import { useStack } from "routes/schedule/lib/useStack"
import { useTreeItem } from "routes/schedule/lib/useTreeItem"
import { RouterLink } from "lib/routes/router-link"
import { navigate } from "lib/routes/navigate"
import { createPortalPair } from "lib/portals"
import {
    Breadcrumbs,
    SelectorTitle,
    SelectorTitleExtras,
    SelectorTitleRight,
    SelectorTitleText,
} from "slot-definitions"
import { ListItemBox } from "lib/@components/ListItemBox"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { TruncatedTypography } from "lib/@components/truncated-typography"

const [Header, HeaderContent] = createPortalPair("Header")

export { HeaderContent }

export function ScheduleBreadcrumbs({ current, sx }) {
    // console.log("ScheduleBreadcrumbs", current)
    const stack = useStack(current) // here
    const { schedule } = useBoundContext()
    // console.log("stack", stack)
    const { group } = AdjustStack.call({ current, group: [...stack].reverse() })
    // console.log("group", group)
    const currentItem = useTreeItem(current)
    // console.log("currentItem", currentItem)
    const [anchor, setAnchor] = useState(null)
    const parentList = stack.length > 0 ? stack[0] : undefined
    const parentItem = useTreeItem(parentList)
    const parent = useRef()

    const list = resolveAsFunction(parentItem?.children?.filter((c) => c?.type === currentItem?.type))() ?? []

    return (
        <ErrorBoundary>
            <Typography variant="h4" component="div" gutterBottom>
                <Header>
                    <SelectorTitle.Slot>
                        <ListItemBox spacing={1}>
                            <SelectorTitleText.Slot>
                                <TruncatedTypography variant="h4">
                                    {cleanState(currentItem?.label ?? schedule?.title)}
                                </TruncatedTypography>
                            </SelectorTitleText.Slot>
                            <SelectorTitleExtras.Slot>
                                {schedule?.code ?? currentItem?.code ? (
                                    <Typography sx={{ whiteSpace: "nowrap" }} variant="body2" color="text.secondary">
                                        {" "}
                                        #{schedule?.code ?? currentItem?.code}
                                    </Typography>
                                ) : null}
                            </SelectorTitleExtras.Slot>
                            <Box flex={1} />
                            <SelectorTitleRight.Slot />
                        </ListItemBox>
                    </SelectorTitle.Slot>
                </Header>
            </Typography>
            <Breadcrumbs.Slot>
                <StandardBreadcrumbs sx={{ m: 0, ...sx }}>
                    {group.map((item, index) =>
                        item?.type ? item : <BreadCrumb item={item} key={index} parents={group.slice(0, index)} />
                    )}

                    <StandardLastBreadcrumb
                        ref={parent}
                        onClick={open}
                        caption={currentItem?.label}
                        hasList={!currentItem?.[TreeRoot] && !!parentItem && list.length}
                    />
                </StandardBreadcrumbs>
                <Menu anchorEl={anchor} open={Boolean(anchor)} onClose={() => setAnchor(null)}>
                    {list.map((item, index) => (
                        <MenuBreadCrumb
                            key={item.id ?? index}
                            sx={{ m: -1, p: 1 }}
                            item={item.id}
                            parents={stack}
                            onClick={() => setAnchor(null)}
                        />
                    ))}
                </Menu>
            </Breadcrumbs.Slot>
        </ErrorBoundary>
    )

    function open() {
        if (!parentItem) return
        setAnchor(parent.current)
    }
}

export function MenuBreadCrumb({ item, onClick = noop }) {
    const part = useTreeItem(item)
    const link = getBreadcrumbLink(part)
    return (
        link && (
            <MenuItem
                sx={{ color: "primary.dark", fontWeight: part.type !== SCHEDULE ? "bold" : undefined }}
                onClick={go}
            >
                {part.label}
            </MenuItem>
        )
    )

    function go(e) {
        onClick(e)
        navigate(link)
    }
}

function getBreadcrumbLink(item) {
    if (!item) return null
    const { link } = GetCrumbLink.call({
        item,
        link:
            !item.isAlias && item.hasChildren !== false
                ? `/app/schedules?${serializeParameters({ id: item.id })}`
                : `/app/schedules/${splitId(item.id).id}`,
    })
    return link
}

function BreadCrumb({ item, onClick }) {
    const part = useTreeItem(item)
    const link = getBreadcrumbLink(part)
    return (
        !!part &&
        link && (
            <Link
                className="breadcrumb"
                sx={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    maxWidth: "10vw",
                    whiteSpace: "nowrap",
                    display: "block",
                }}
                onClick={onClick}
                component={RouterLink}
                to={link}
            >
                {cleanState(part.label)}
            </Link>
        )
    )
}

export function cleanState(label = "") {
    return label.replace("Ready:", "").replace("Live:", "").replace("Publish:", "")
}

function serializeParameters(params) {
    return Object.entries(params)
        .map(([name, value]) => {
            if (typeof value === "object") {
                value = encodeURIComponent(JSON.stringify(value))
            }
            return `${name}=${value}`
        })
        .join("&")
}
