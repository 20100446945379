import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router"

/**
 * Hook that retrieves the values of specified search parameters and then removes them from the current URL.
 * The browser's address bar and the router's context are updated to reflect this change.
 *
 * @param {string|string[]} keys - A search parameter key or an array of keys to be retrieved and removed.
 * @returns {Object} An object with each key associated with its removed search parameter value,
 *                   or null if the key was not found.
 */
export function useRetrieveAndDeleteSearchParams(keys) {
    const navigate = useNavigate()
    const location = useLocation()

    const keysArray = Array.isArray(keys) ? keys : [keys]

    const currentSearchParams = new URLSearchParams(location.search)

    const [retrievedValues] = useState(function keys() {
        return keysArray.reduce((accum, key) => {
            accum[key] = currentSearchParams.get(key)
            return accum
        }, {})
    })

    useEffect(() => {
        let hasChanges = false
        for (const key of keysArray) {
            if (retrievedValues[key]) {
                hasChanges = true
                currentSearchParams.delete(key)
            }
        }

        if (hasChanges) {
            navigate(
                {
                    ...location,
                    search: currentSearchParams.toString(),
                },
                { replace: true }
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return retrievedValues
}
