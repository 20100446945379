import { useContext } from "react"
import { CollapseDrawerContext, CollapseRefresh } from "minimals-template/components/contexts/CollapseDrawerContext"

// ----------------------------------------------------------------------

const useCollapseDrawer = () => {
    CollapseRefresh.useRefresh()
    return useContext(CollapseDrawerContext)
}

export default useCollapseDrawer
