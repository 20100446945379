import { PageBreadCrumbs, TreeToolbar } from "slot-definitions"
import { Box, Stack, Typography } from "@mui/material"
import { PageContainer } from "lib/@components/page-container"
import { TopBox } from "lib/@components/topBox"
import { ToolbarBox } from "lib/@components/toolbarBox"
import { StandardBreadcrumbs } from "lib/@components/standardBreadcrumbs"
import { ListItemBox } from "lib/@components/ListItemBox"
import { BackButton } from "routes/schedule/tree/schedules/upDirectory"
import { ensureArray } from "lib/ensure-array"

export function PageBreadcrumbContainer({
    children,
    parentCrumbs = [],
    title,
    heading = title,
    crumb = heading,
    backButton = <BackButton />,
    downloadButton,
    ...props
}) {
    parentCrumbs = ensureArray(parentCrumbs)
    const crumbs = PageBreadCrumbs.useSlot(
        <Box
            sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "15vw",
                whiteSpace: "nowrap",
                display: "block",
            }}
        />
    )
    return (
        <PageContainer title={title} {...props}>
            <Box mx={0} width={1} height={1} display="flex" flexDirection="column">
                <TopBox>
                    <ToolbarBox>
                        <Stack spacing={1}>
                            <Typography variant="h4" component="div">
                                {heading}
                            </Typography>
                            <StandardBreadcrumbs
                                sx={{
                                    mx: 0,
                                    "& li:last-child": {
                                        fontWeight: "bold",
                                    },
                                }}
                            >
                                {parentCrumbs.map((c, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            maxWidth: "15vw",
                                            whiteSpace: "nowrap",
                                            display: "block",
                                        }}
                                    >
                                        {c}
                                    </Box>
                                ))}
                                <Box
                                    sx={{
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        maxWidth: "15vw",
                                        whiteSpace: "nowrap",
                                        display: "block",
                                    }}
                                >
                                    {crumb}
                                </Box>
                                {crumbs}
                            </StandardBreadcrumbs>
                            <ListItemBox mb={1} pt={1}>
                                {backButton}
                                <Box
                                    whiteSpace="nowrap"
                                    flex={1}
                                    justifyContent="flex-end"
                                    display="flex"
                                    alignItems="center"
                                    width="max-content"
                                />
                                {downloadButton}
                                <TreeToolbar.Slot />
                            </ListItemBox>
                        </Stack>
                    </ToolbarBox>
                </TopBox>
                {children}
            </Box>
        </PageContainer>
    )
}
