import { useLayoutEffect, useRef, useState } from "react"
import { resolveAsFunction } from "lib/resolve-as-function"

export function useCurrentState(initial) {
    const [value, setValue] = useState(initial)
    const mounted = useRef(false)
    const mountValue = useRef()
    useLayoutEffect(() => {
        mounted.current = true
        if (mountValue.current) {
            setValue(mountValue.current)
            mountValue.current = undefined
        }
        return () => {
            mounted.current = false
        }
    }, [])
    return [
        mountValue.current,
        (v) => {
            mountValue.current = value
            if (!mounted.current) {
                return (mountValue.current = resolveAsFunction(v)(mountValue.current))
            }
            return setValue(v)
        },
    ]
}
