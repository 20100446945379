import { RoutesChanged, RoutesGet } from "event-definitions"
import { getAllRoutes } from "ioc"
import { registerChildRoute } from "lib/routes/register-child-route"

function inPriorityOrder(a, b) {
    return +(a?.priority ?? 100) - +(b?.priority ?? 100)
}

export function register(path, element, children = null) {
    if (!path || typeof path !== "string") {
        throw new Error("Path must be a string")
    }
    const result = RoutesGet.handleOnce(({ routes, parent = "" }) => {
        if (parent !== "") return
        registerRoute(element, children, path, routes)
    })
    result.register = function registerSubRoute(...params) {
        return registerChildRoute(path, ...params)
    }
    RoutesChanged.raiseOnce()
    return result
}

export function registerRoute(element, children, path, routes, fullPath = path) {
    if (typeof element === "object" && element) {
        return add({
            ...element.props,
            children,
            path,
            element,
        })
    }
    throw new Error("Invalid element, should be a <ReactNode />")

    function add(item) {
        const childRoutes = getAllRoutes([], fullPath)
        if (childRoutes.length) {
            childRoutes.sort(inPriorityOrder)
            item.children = childRoutes
        }
        routes.push(item)
        routes.sort(inPriorityOrder)
        return () => {
            const idx = routes.indexOf(item)
            if (idx >= 0) routes.splice(idx, 1)
            RoutesChanged.raiseOnce()
        }
    }
}
