const div = document.createElement("div")

/**
 * Strips all of the tags from HTML and returns the text content with block elements replaced by spaces
 * @param {string} html - the html to strip
 * @returns {string}
 */
export function htmlToText(html) {
    if (!html || html === "undefined") return ""
    div.innerHTML = html

    // Replace block elements (like <p>, <div>, etc.) with newlines
    div.querySelectorAll("p, div, br").forEach((el) => el.insertAdjacentText("afterend", "\n"))

    const result = div.innerText.replace(/\n+/g, " | ").trim()
    if (result.endsWith("|")) {
        return result.slice(0, -2)
    }
    return result
}
