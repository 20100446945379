import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { noChange } from "lib/@hooks/useRefresh"
import { raise } from "library/local-events"
import { RunMe } from "lib/@components/slot/index"
import { Frag } from "lib/@components/slot/frag"
import { Fragment, useMemo, useRef } from "react"
import { RefreshSlot } from "event-definitions"

export function useItems(type, items = []) {
    items = items.slice(0)
    // If the "items" contains a function then we cannot cache the results as it may
    // change without notification.  In that case rerun will be incremented and a future
    // redraw of the items will have the useMemo run again.  If there are no functions
    // then the rerun will not increment and the caching can continue under normal
    // conditions.
    const rerun = useRef(0)
    const context = useBoundContext()
    const refresh = RefreshSlot(type).useRefresh(noChange)

    return useMemo(() => {
        raise(`Slot.${type}`, {
            items,
            context,
            add(item) {
                if (item.type) {
                    if (item.type === Fragment) {
                        items.push(<Frag {...item.props} __type={type} />)
                    } else {
                        items.push(<item.type {...item.props} __type={type} />)
                    }
                } else {
                    items.push(item)
                }
            },
        })
        let toRun = items.filter(Boolean).sortBy((i) => i?.props?.priority ?? i?.priority ?? 100)
        let toExecute
        do {
            toExecute = toRun.find((i) => i[RunMe])
            if (toExecute) {
                rerun.current += 1
                const upToHere = toRun.indexOf(toExecute)
                let before = toRun.slice(0, upToHere)
                const after = toRun.slice(upToHere)
                const props = { items: before, type, context }
                const result = toExecute.fn(props)
                before = props.items
                toRun = [...before, ...after]
                const idx = toRun.indexOf(toExecute)
                if (idx !== -1) {
                    if (result) {
                        toRun[idx] = result
                    } else {
                        toRun.splice(idx, 1)
                    }
                }
            }
        } while (toExecute)
        return toRun.filter(Boolean)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, refresh.id, ...items, context, rerun.current])
}
