// hooks
import { UserAvatar } from "minimals-template/components/user-avatar"
import { useCurrentUser } from "lib/store/use-current-user"
// utils

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
    const user = useCurrentUser()
    return <UserAvatar {...other} user={user} />
}
