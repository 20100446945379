import { useEvent } from "lib/@hooks/useEvent"
import { useEffect } from "react"
import { RefreshSlot } from "event-definitions"

/**
 * Use a plug for a slot while the caller is mounted
 * @param {string} type - the type opf slot to plug into
 * @param {object} component - an instance of the component to use
 * @param {Array.<any>} deps - any dependencies for remounting
 */
export function usePlug(type, component, deps = []) {
    useEvent(
        `Slot.${type}`,
        ({ add = () => {}, ...props }) => {
            if (!component) return
            if (typeof component === "function") {
                const result = component(props)
                if (result) {
                    add(result)
                }
            } else if (component.props?.if) {
                try {
                    const { if: condition, ...other } = component.props
                    if (condition(props.context)) {
                        add({ ...component, props: other })
                    }
                } catch (e) {
                    //
                }
            } else {
                add(component)
            }
        },
        [type, ...deps]
    )
    useEffect(() => {
        RefreshSlot(type).raiseOnce()

        return () => {
            RefreshSlot(type).raiseOnce()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, ...deps])
}
