import { HeaderBarRight } from "slot-definitions"
import { Box } from "@mui/material"
import AccountPopover from "routes/@layouts/app/header/AccountPopover"
import { NotOnMobile } from "lib/@components/mobile"
import Logo from "minimals-template/components/Logo"

HeaderBarRight.plug(
    <Box pl={1} priority={10000}>
        <AccountPopover />
    </Box>
)
HeaderBarRight.plug(
    <NotOnMobile priority={30000}>
        <Logo />
    </NotOnMobile>
)
