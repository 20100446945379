import { Typography, Link } from "@mui/material"
import { PATH } from "routes/paths"

function PrivacyAndTerms() {
    return (
        <Typography variant="body2" align="center" sx={{ color: "text.secondary", mt: 3 }}>
            By proceeding, I agree to SFG20&nbsp;
            <Link underline="always" color="text.primary" href={PATH.TOS}>
                Terms of Service
            </Link>{" "}
            and{" "}
            <Link underline="always" color="text.primary" href={PATH.PRIVACY_POLICY}>
                Privacy Policy
            </Link>
            .
        </Typography>
    )
}

export default PrivacyAndTerms
