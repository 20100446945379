import { useEffect } from "react"

export function useInterval(fn, timeout, deps = []) {
    useEffect(() => {
        const timer = setInterval(fn, timeout)
        return () => {
            clearInterval(timer)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fn, timeout, ...deps])
}
