import { Box } from "@mui/material"
import { useMobile } from "lib/@components/mobile"
import useSettings from "minimals-template/components/@hooks/useSettings"

export function TopBox({ children, inContainer }) {
    const isMobile = useMobile()
    const amount = inContainer ? 0 : 2
    const { themeStretch } = useSettings()
    return !inContainer ? (
        <Box data-cy="topBox" margin="auto" width={1} maxWidth={!themeStretch ? 1120 : undefined}>
            <Box
                sx={{
                    ml: -2,
                    mr: -2,
                    width: "calc(100% + 32px)",
                    pl: isMobile ? amount : 1,
                    pr: isMobile ? amount : 2,
                }}
            >
                {children}
            </Box>
        </Box>
    ) : (
        <Box
            data-cy="topBox"
            sx={{ ml: 0, mr: 0, width: "calc(100%)", pl: isMobile ? amount : 1, pr: isMobile ? amount : 2 }}
        >
            {children}
        </Box>
    )
}
