import { lazy, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react"
import { Navigate, useLocation, useRoutes } from "react-router-dom"
// @layouts
import MainLayout from "routes/@layouts/main"
import LogoOnlyLayout from "routes/@layouts/LogoOnlyLayout"
// guards
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { Loadable } from "lib/@components/loadable"
import { Navigated, RefreshRouter, Routing } from "event-definitions"
import { getAllRoutes } from "ioc"
import { createEvent } from "library/local-events"

// components

// ----------------------------------------------------------------------

const LAST_PRIORITY = 20000

export function RouteBroadcast() {
    const location = useLocation()
    useEffect(() => {
        Navigated.raiseOnce()
    }, [location.pathname, location.search])
    return null
}

const PathChanged = createEvent("PathChanged")

export function RouterLocation() {
    const path = useRef(window.location.pathname)
    const location = useLocation()
    useLayoutEffect(() => {
        if (path.current !== location.pathname) {
            path.current = location.pathname
            PathChanged.raise(location)
        }
    }, [location])
    return null
}

export default function Router() {
    RefreshRouter.useRefresh()
    const [location, setLocation] = useState(window.location)
    PathChanged.useEvent(setLocation)

    const routes = useMemo(() => {
        Routing.raise(location)
        return getAllRoutes([
            // Main Routes
            {
                priority: LAST_PRIORITY,
                path: "*",
                element: <LogoOnlyLayout />,
                children: [
                    { path: "500", element: <Page500 /> },
                    { path: "404", element: <Page404 /> },
                    { path: "403", element: <Page403 /> },
                    { path: "*", element: <Navigate to="/404" replace /> },
                ],
            },
            {
                path: "/",
                priority: LAST_PRIORITY + 1,
                element: <MainLayout />,
                children: [{ element: <HomePage />, index: true }],
            },
            { path: "*", priority: LAST_PRIORITY * 10, element: <Navigate to="/404" replace /> },
        ])
    }, [location])

    return useRoutes(routes)
}

// MAIN
const HomePage = Loadable(lazy(() => import("../minimals-template/pages/Home")))
const Page500 = Loadable(lazy(() => import("../minimals-template/pages/Page500")))
const Page403 = Loadable(lazy(() => import("../minimals-template/pages/Page403")))
const Page404 = Loadable(lazy(() => import("../minimals-template/pages/Page404")))
