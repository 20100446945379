import PropTypes from "prop-types"
import { forwardRef } from "react"
import { useTheme } from "@mui/material/styles"
import { Avatar as MUIAvatar } from "@mui/material"

// ----------------------------------------------------------------------

const Avatar = forwardRef(function Avatar({ color = "default", children, sx, ...other }, ref) {
    const theme = useTheme()

    if (color === "default") {
        return (
            <MUIAvatar ref={ref} sx={sx} {...other}>
                {children}
            </MUIAvatar>
        )
    }

    return (
        <MUIAvatar
            ref={ref}
            sx={{
                fontSize: "95%",
                fontWeight: theme.typography.fontWeightLight,
                color: "#333",
                backgroundColor: color,
                ...sx,
            }}
            {...other}
        >
            {children}
        </MUIAvatar>
    )
})

Avatar.propTypes = {
    children: PropTypes.node,
    sx: PropTypes.object,
    color: PropTypes.oneOf(["default", "primary", "secondary", "info", "success", "warning", "error"]),
}

export default Avatar
