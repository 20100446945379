import { useEditable } from "ioc"

export function Editing({ field, editor = null, display = null, children = null }) {
    const isEditable = useEditable(field)
    return isEditable ? (
        editor
    ) : (
        <>
            {display}
            {children}
        </>
    )
}
