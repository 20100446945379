import { decamelize } from "library/decamelize"

export function makeLabelFrom(fieldName) {
    if (!fieldName) return undefined
    fieldName = fieldName.split(".").slice(-1)[0]
    return decamelize(fieldName, " ")
        .split(" ")
        .map((w) => w[0].toUpperCase() + w.slice(1))
        .join(" ")
}
