import noop from "lib/noop"
import Iconify from "minimals-template/components/Iconify"
import { Navigation } from "event-definitions"

export function registerConditionalPageNavigation(predicate, path, title, icon, subPaths) {
    predicate ??= noop
    if (typeof icon === "string") {
        icon = <Iconify icon={icon} />
    }

    Navigation.handleOnce(({ navigation }) => {
        if (predicate()) {
            navigation.push({ path, title, icon, subPaths })
        }
    })
}
