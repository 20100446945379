import { raise } from "library/local-events"
import { instanceId } from "lib/graphql/instance-id"
import { useEffect, useState } from "react"

import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { getCurrentClientId, getCurrentToken } from "minimals-template/components/contexts/NognitoContext"
import { Connected, ConnectionLost, ServerOnline } from "event-definitions"
import useAuth from "minimals-template/components/@hooks/useAuth"

const MAX_RETRIES = 20
const QUICK_RETRY_INTERVAL = 400
const MAX_QUICK_RETRY_DURATION = 5000

export function useServerEvents() {
    const auth = useAuth()

    const [loading, setLoading] = useState(true)
    const { currentClient } = useBoundContext()
    useEffect(() => {
        console.log("Establish connection for", currentClient)
        const source = connectEvents()
        return () => {
            try {
                source.then((s) => s?.close()).catch(console.error)
            } catch (e) {
                console.error("Error closing SSE", e)
            }
        }
    }, [currentClient, auth.isLoggingIn])

    Connected.useEvent(() => setLoading(false))

    return { loading }
}

async function connectEvents() {
    let hasOpened = false
    let retryDelay = QUICK_RETRY_INTERVAL
    let retryCount = 0
    let totalQuickRetryTime = 0
    let timeout
    const token = await getCurrentToken()
    const instance = instanceId
    let currentEventSource = openEventSource()
    const currentClientId = getCurrentClientId()

    return {
        close: () => {
            console.log("Close events for", currentClientId)
            return currentEventSource?.close()
        },
    }

    function openEventSource() {
        const params = Object.fromEntries(new URLSearchParams(window.location.search).entries())
        const source = new EventSource(
            `${window.appProtocol || process.env.REACT_APP_PROTOCOL}://${
                window.appServerLocation || process.env.REACT_APP_SERVER
            }/messages?token=${token}&instance=${instance}&client=${getCurrentClientId()}&source=${
                params.share ?? ""
            }&href=${encodeURIComponent(window.location.href)}`,
            { withCredentials: true }
        )
        source.addEventListener("error", handleError)
        source.addEventListener("open", handleOpen)
        source.addEventListener("message", handleMessage)
        return source

        function handleError(e) {
            console.log("Event Error for", getCurrentClientId())
            console.log("Event Error", JSON.stringify(e))
            source.close()
            if (retryCount >= MAX_RETRIES && e.target.readyState === EventSource.CLOSED) {
                console.error("Could not reach the message endpoint after max retries.")
                ConnectionLost.raise()
                return
            }
            if (hasOpened) {
                if (timeout) {
                    clearTimeout(timeout)
                }

                if (totalQuickRetryTime + QUICK_RETRY_INTERVAL <= MAX_QUICK_RETRY_DURATION) {
                    retryDelay = QUICK_RETRY_INTERVAL
                    totalQuickRetryTime += QUICK_RETRY_INTERVAL
                } else if (retryCount < MAX_RETRIES) {
                    retryDelay *= 2
                    retryCount++
                }

                timeout = setTimeout(() => {
                    currentEventSource = openEventSource()
                }, retryDelay)
            }
        }

        function handleOpen() {
            console.log("Events Opened for", getCurrentClientId())
            hasOpened = true
            retryDelay = QUICK_RETRY_INTERVAL
            retryCount = 0
            totalQuickRetryTime = 0
            if (timeout) {
                clearTimeout(timeout)
            }
            setTimeout(() => ServerOnline.raiseOnce(), 500)
        }

        function handleMessage(message) {
            Connected.raise()
            const data = JSON.parse(message.data)
            if (data.restart) {
                console.log("RESTARTING", instanceId)
                source.close()
                currentEventSource = openEventSource()
                return
            }
            const packet = data.packet ?? {}
            runInContext(() => {
                if (currentContext) currentContext.serverContext = packet.currentContext

                if (packet.event) {
                    raise(packet.event, ...packet.params)
                }
            })

            if (packet.ack) {
                fetch(
                    `${window.appProtocol || process.env.REACT_APP_PROTOCOL}://${
                        window.appServerLocation || process.env.REACT_APP_SERVER
                    }/ack?token=${token}&instance=${instance}&ack=${packet.ack}`,
                    { credentials: "include" }
                ).catch(console.error)
            }
        }
    }
}
