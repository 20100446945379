import { DecorateTreeIndex, RefreshSchedules } from "event-definitions"
import { noChange, useRefresh } from "lib/@hooks/useRefresh"
import { getTreeIndex } from "routes/schedule/lib/getTreeIndex"
import { useRef } from "react"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function useTreeItemIndex(shouldRefresh = true) {
    const refresh = useRefresh(noChange)
    const result = useRef()
    result.current = getTreeIndex()
    RefreshSchedules.useEvent(() => {
        if (shouldRefresh || !result.current) {
            refresh()
        }
    }, [shouldRefresh])
    DecorateTreeIndex.raise({ index: result.current, context: useBoundContext() })
    return result.current
}
