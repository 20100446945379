import { Box, Button } from "@mui/material"
import { server } from "lib/app-server"
import { showNotification } from "lib/@components/notifications"
import { prevent } from "lib/prevent"
import { useRef } from "react"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import { noop } from "lib/noop"
import { buttonStyles } from "lib/@components/button-styles"

export function UploadButton({
    onFile = noop,
    onRawFiles = noop,
    onRaw = noop,
    Component = Button,
    accept = "*",
    children,
    multiple,
    ...props
}) {
    const classes = buttonStyles()
    const inputHolder = useRef()
    const fileInput = useRef()

    return (
        <>
            <Component {...props} onClick={prevent(selectFile)}>
                {children}
            </Component>
            <Box
                className="upload-button-input"
                ref={inputHolder}
                style={{
                    width: 0,
                    height: 0,
                    display: "none",
                    position: "absolute",
                }}
            >
                <input
                    ref={fileInput}
                    className={`file-input ${classes.uploadButton}`}
                    onChange={gotFile}
                    type="file"
                    accept={accept}
                    multiple={multiple}
                />
            </Box>
        </>
    )

    async function gotFile(e) {
        const { target: { files = [] } = {} } = e
        if (await onRawFiles(files, accept)) {
            return
        }
        for (const file of files) {
            // eslint-disable-next-line no-await-in-loop
            if (await onRaw(file, accept, files.length)) {
                continue
            }
            if (file) {
                const reader = new FileReader()

                reader.onload = (e) => {
                    let file = e.target.result
                    try {
                        file = JSON.parse(file)
                    } catch (e) {
                        // Wasn't JSON
                    }
                    onFile(file)
                }
                reader.readAsText(file, "utf8")
            }
        }
        clearInputFile(fileInput.current)
    }

    function selectFile() {
        const input = document.createElement("input")
        input.type = "file"
        input.multiple = multiple
        input.accept = accept
        input.style.display = "none"
        input.onchange = gotFile
        document.body.appendChild(input)
        setTimeout(() => {
            input.click()
            input.value = ""
        }, 200)
    }

    function clearInputFile(f) {
        if (!f) return
        f.value = ""
        // For older browsers if above doesn't work
        if (f.value) {
            f.type = "text"
            f.type = "file"
        }
    }
}

export async function uploadFiles(
    files,
    responseHandler = noop,
    { transformer = (v) => v, includeMetadata = false, publicFile = true } = {}
) {
    files = files.length ? files : [files]

    for (const file of files) {
        try {
            const body = new FormData()

            // eslint-disable-next-line no-await-in-loop
            body.append("upload", await transformer(file))
            body.append("includeMetadata", includeMetadata.toString())

            let result
            // eslint-disable-next-line no-await-in-loop
            const response = await server(publicFile ? "/upload?publicFile=true" : "/upload", { method: "POST", body })
            try {
                // eslint-disable-next-line no-await-in-loop
                result = includeMetadata ? await response?.json() : await response?.text()
            } catch (error) {
                console.error("Error parsing response:", error)
                // eslint-disable-next-line no-await-in-loop
                result = await response?.text()
            }

            if (response.ok) {
                responseHandler(result, file)
            } else {
                errorSnackbar(`Upload failed: ${file?.name}`, result)
            }
        } catch (e) {
            showNotification("Upload failed", { severity: "error" })
        }
    }
}
