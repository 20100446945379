import PropTypes from "prop-types"

import useAuth from "minimals-template/components/@hooks/useAuth"
import LoadingScreen from "minimals-template/components/LoadingScreen"

import { TermsAndConditions } from "minimals-template/components/terms-and-conditions"
import PasswordlessLogin from "minimals-template/pages/auth/PasswordlessLogin"
import { ClientSelect } from "minimals-template/pages/auth/ClientSelect"
import { NoLicense } from "minimals-template/pages/auth/NoLicense"
import { useSelectedClient } from "minimals-template/pages/auth/use-selected-client"
import { Bound } from "lib/@components/binding/Bound"
import { useEffect } from "react"
import { isMobileApp } from "routes/@layouts/app/header/is-mobile-app"

AuthGuard.propTypes = {
    children: PropTypes.node,
}

export default function AuthGuard({ children }) {
    const user = useAuth()
    const {
        clients,
        setSelectedClient,
        selectedClient,
        resetSelectedClient,
        clientsLoading,
        loading,
        selectedClientInitialised,
    } = useSelectedClient()

    useEffect(() => {
        if (!isMobileApp()) return () => {}
        document.documentElement.addEventListener("focusin", scrollIn)
        return () => {
            document.documentElement.removeEventListener("focusin", scrollIn)
        }
    }, [])

    const { isAuthenticated, isInitialized } = user

    if (!isInitialized || clientsLoading || loading) {
        return <LoadingScreen description="Auth Guard" />
    }

    if (!isAuthenticated) {
        return <PasswordlessLogin />
    }

    return (
        <Bound
            clients={clients}
            selectedClient={selectedClient}
            setSelectedClient={setSelectedClient}
            resetSelectedClient={resetSelectedClient}
            selectedClientInitialised={selectedClientInitialised}
        >
            <ClientSelect>
                <TermsAndConditions>
                    <NoLicense>{children}</NoLicense>
                </TermsAndConditions>
            </ClientSelect>
        </Bound>
    )

    function scrollIn({ target }) {
        target?.scrollIntoView({ block: "center", behavior: "smooth" })
    }
}
