import { useState } from "react"
import { OpenDialog, RemoveDialog } from "event-definitions"
import { setDialogOpen } from "lib/@dialogs/dialogOpen"
import { RenderedDialogBox } from "lib/@dialogs/renderedDialogBox"

export function Dialogs() {
    const [dialogs, setDialogs] = useState([])
    OpenDialog.useEvent((dialog) => setDialogs((dialogs) => [...dialogs, dialog]))
    RemoveDialog.useEvent((dialogId) => setDialogs((dialogs) => dialogs.filter((d) => d.dialogId !== dialogId)))
    setDialogOpen(!!dialogs.length)
    return dialogs.map((d, index) => <RenderedDialogBox key={d.dialogId} {...d} displayIndex={index} />)
}
