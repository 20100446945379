import { useItems } from "lib/@components/slot/use-items"
import { getSlotContentElementsWithWrapper } from "lib/@components/slot/get-slot-content-elements-with-wrapper"
import { useMemo } from "react"
import { usePropsObject } from "lib/@hooks/use-props-object"

const dummyParams = Object.seal(Object.freeze({}))

export function useSlot(type, Container, props = dummyParams) {
    const Type = Container?.type
    const containerProps = Container?.props
    const items = useItems(type)
    const memoProps = usePropsObject(props)
    return useMemo(
        () => getSlotContentElementsWithWrapper(items, memoProps, containerProps, Type),
        [items, memoProps, containerProps, Type]
    )
}
