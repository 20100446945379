import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"

export const getTags = api(async (referenceId, type) => {
    if (!referenceId) return null
    return query(
        gql`
            query TagsAndPossible($referenceId: String!, $type: String!) {
                itemBaskets(referenceId: $referenceId, type: [$type]) {
                    name
                    id
                    item(id: $referenceId) {
                        id
                        added
                    }
                }
            }
        `,
        { referenceId, type },
        { returns: "itemBaskets" }
    )
})

export const getAllTags = api(() =>
    query(
        gql`
            query Baskets {
                baskets(type: "Tag") {
                    baskets {
                        id
                        name
                        canEdit
                    }
                }
            }
        `,
        {},
        { returns: "baskets" }
    )
)

export function useTagsAndAllPossibleTags(referenceId, type) {
    const tags = getTags.useResults(referenceId, type) ?? false
    const allTags = getAllTags.useResults() ?? false
    return { tags, allTags, loading: tags === false || allTags === false }
}
