// ----------------------------------------------------------------------

export { default as NavSectionVertical } from "minimals-template/components/nav-section/vertical"

export function isExternalLink(path) {
    return typeof path === "function" ? false : path.includes("http")
}

export function getActive(path, pathname, subPaths, location) {
    const active = subPaths?.isActive?.({ pathname, location, path, subPaths })
    // eslint-disable-next-line no-nested-ternary
    return (
        active ||
        (active !== false &&
            (path ? (subPaths === true || subPaths?.subPaths ? pathname.startsWith(path) : path === pathname) : false))
    )
}
