import PropTypes from "prop-types"
// @mui
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles"
// hooks
import useLocales from "minimals-template/components/@hooks/useLocales"
import { omit } from "lib/omit"

// ----------------------------------------------------------------------

ThemeLocalization.propTypes = {
    children: PropTypes.node.isRequired,
}

export default function ThemeLocalization({ children }) {
    const defaultTheme = useTheme()

    const { currentLang } = useLocales()

    const theme = createTheme(omit(defaultTheme, "vars"), currentLang.systemValue)

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
