import { lazy } from "react"
import { Loadable } from "lib/@components/loadable"
import GuestGuard from "minimals-template/components/guards/GuestGuard"
import { register } from "lib/routes/register"
import PasswordlessLogin from "./PasswordlessLogin"
import PasswordlessRegister from "./PasswordlessRegister"

const Invited = Loadable(lazy(() => import("minimals-template/pages/auth/Invited")))
const ResetPassword = Loadable(lazy(() => import("minimals-template/pages/auth/ResetPassword")))
const NewPassword = Loadable(lazy(() => import("minimals-template/pages/auth/NewPassword")))
const VerifyCode = Loadable(lazy(() => import("minimals-template/pages/auth/VerifyCode")))

register(
    "/login",
    <GuestGuard>
        <PasswordlessLogin />
    </GuestGuard>
)
register(
    "/register",
    <GuestGuard>
        <PasswordlessRegister />
    </GuestGuard>
)
register(
    "/invited",
    <GuestGuard>
        <Invited />
    </GuestGuard>
)
register(
    "/reset-password",
    <GuestGuard>
        <ResetPassword />
    </GuestGuard>
)
register(
    "/new-password",
    <GuestGuard>
        <NewPassword />
    </GuestGuard>
)
register(
    "/verify",
    <GuestGuard>
        <VerifyCode />
    </GuestGuard>
)
