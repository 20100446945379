import { RoutesChanged, RoutesGet } from "event-definitions"
import { registerRoute } from "lib/routes/register"

export function registerChildRoute(parentPath, path, element, children = null) {
    if (typeof path !== "string") {
        throw new Error("Path must be a string")
    }
    const result = RoutesGet.handle(({ routes, parent = "" }) => {
        if (parent !== parentPath) return
        registerRoute(element, children, path, routes, `${parentPath}/${path}`)
    })
    result.register = function registerSubRoute(...params) {
        return registerChildRoute(`${parentPath}/${path}`, ...params)
    }
    RoutesChanged.raiseOnce()
    return result
}
