import { useEffect, useState } from "react"
// @mui
import { Button } from "@mui/material"
import { alpha } from "@mui/material/styles"
//
import Iconify from "minimals-template/components/Iconify"

// ----------------------------------------------------------------------

export default function SettingFullscreen() {
    const [fullscreen, setFullscreen] = useState(Boolean(document.fullscreenElement))

    useEffect(() => {
        function onFullscreenChange() {
            setFullscreen(Boolean(document.fullscreenElement))
        }
        document.addEventListener("fullscreenchange", onFullscreenChange)
        return () => document.removeEventListener("fullscreenchange", onFullscreenChange)
    }, [])

    return (
        <Button
            fullWidth
            size="large"
            variant="outlined"
            color={fullscreen ? "primary" : "inherit"}
            startIcon={<Iconify icon={fullscreen ? "ic:round-fullscreen-exit" : "ic:round-fullscreen"} />}
            onClick={toggleFullScreen}
            sx={{
                fontSize: 14,
                ...(fullscreen && {
                    bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
                }),
            }}
        >
            {fullscreen ? "Exit Fullscreen" : "Fullscreen"}
        </Button>
    )

    function toggleFullScreen() {
        if (fullscreen) {
            document.exitFullscreen().catch(console.error)
        } else {
            document.documentElement.requestFullscreen().catch(console.error)
        }
    }
}
