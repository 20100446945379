import { useState } from "react"
import { Backdrop, useTheme } from "@mui/material"
import { BackdropClick, OpenDialog, RemoveDialog, ShowBackdrop } from "event-definitions"
import { isDialogOpen } from "lib/@dialogs/dialogOpen"

export function BackdropHolder() {
    const [show, setShow] = useState({})
    const theme = useTheme()
    ShowBackdrop.useEvent(showList)
    OpenDialog.after.useRefresh()
    RemoveDialog.after.useRefresh()
    const shouldShow = !isDialogOpen() && !!Object.values(show).filter(Boolean).length
    if (theme.palette.mode === "light") {
        return (
            !!shouldShow && (
                <Backdrop
                    sx={{ zIndex: 1205, background: `#fffb !important` }}
                    open={true}
                    onClick={BackdropClick.raise}
                />
            )
        )
    }
    return !!shouldShow && <Backdrop sx={{ zIndex: 1205 }} open={true} onClick={BackdropClick.raise} />

    function showList(visible, id) {
        setShow((show) => ({ ...show, [id]: visible }))
    }
}
