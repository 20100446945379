import { makeLazy } from "lib/make-lazy"

export function getIndexOf(index, schedule) {
    let output = 0
    for (let i = 0; i < index; i++) {
        // eslint-disable-next-line no-continue
        if (schedule.tasks[i].isHeader) continue
        output++
    }
    return output
}

export const Task = makeLazy(() => import("routes/schedule/tasks/taskImpl"), "TaskImpl")
