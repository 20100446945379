const HookedEvents = require("library/hooked-events")
const { ensureArray } = require("library/ensure-array")

let events = (global.events = global.events || new HookedEvents())

function resetAllEvents() {
    events = global.events = global.events || new HookedEvents()
}

/**
 * Raises an event
 * @param {string} event - the name of the event
 * @param {...*} params - the parameters to pass to the event
 * @returns {[]} the parameters passed in
 */
function raise(event, ...params) {
    events.emit(event, ...params)
    return params
}

/**
 * Raises an event with early and late phases
 * @param {string} event - the name of the event
 * @param {...*} params - the parameters to pass to the event
 * @returns {[]} the parameters passed in
 */
function raiseExtended(event, ...params) {
    events.emitExtended(event, ...params)
    return params
}

/**
 * Raises an event asynchronously, waiting for all handlers to respond
 * @param {string} event - the name of the event
 * @param {...*} params - the parameters to pass to the event
 * @returns {Promise<[]>} the parameters passed in
 */
async function raiseAsync(event, ...params) {
    // noinspection ES6RedundantAwait
    await events.emitAsync(event, ...params)
    return params
}

/**
 * Raises an event asynchronously, waiting for all handlers to respond and firing early and late versions
 * @param {string} event - the name of the event
 * @param {...*} params - the parameters to pass to the event
 * @returns {Promise<[]>} the parameters passed in
 */
async function raiseAsyncExtended(event, ...params) {
    // noinspection ES6RedundantAwait
    await events.emitAsyncExtended(event, ...params)
    return params
}

/**
 * Waits for an event to be raised
 * @param {string} event - the name of the event
 * @param {number} timeout - the amount of time to wait
 * @returns {Promise} a promise for the event being raised
 */
function waitForEvent(event, timeout = 1000 * 60 * 60 * 24) {
    return new Promise((resolve) => {
        events.once(event, resolve)
        setTimeout(resolve, timeout)
    })
}

// eslint-disable-next-line no-undef
const reference = globalThis

/**
 * Handles an event, removing any former handler that has
 * the same name
 * @param {string} identifier - the unique name for the handler
 * @param {string} event - the event to raise
 * @param {Function} handler - the handler to call
 * @returns {Function} a function to remove the handler
 */
function handleId(identifier, event, handler) {
    identifier = `${identifier}_${event}`
    reference.$$events = reference.$$events || {}
    if (reference.$$events[identifier]) {
        reference.$$events[identifier]()
    }
    reference.$$events[identifier] = handle(event, handler)
    return () => {
        reference.$$events[identifier]()
        delete reference.$$events[identifier]
    }
}

function firstParam(result) {
    return result[0]
}

const eventTemplate = {}

/**
 * @typedef {Object} EventObject
 *
 * @property {string} eventName - The name of the event.
 *
 * @property {function(...string): EventObject} subEvent - Creates a sub-event.
 *
 * @property {function(...*): *} call - Calls the event with the specified parameters.
 *
 * @property {function(...*): Promise<*>} callAsync - Calls the event asynchronously with the specified parameters.
 *
 * @property {function(...Function): Function} on - Adds a handler for the event.
 *
 * @property {function(string, ...Function): Function} handle - Adds a handler for the event.
 *
 * @property {function(string, ...Function): Function} handleId - Adds a handler for the event with a unique
 *     identifier.
 *
 * @property {function(...Function): Function} handleOnce - Adds a handler that will be called only once.
 *
 * @property {function(...*): *} raise - Raises the event with the specified parameters.
 *
 * @property {function(...*): Promise<*>} raiseAsync - Raises the event asynchronously with the specified parameters.
 *
 * @property {function(...Function): Function} once - Adds a handler that will be called only once.
 *
 * @property {function(...*): void} raiseLater - Raises the event with the specified parameters after the current main
 *     loop.
 *
 * @property {function(...*): void} raiseOnce - Raises the event with the specified parameters, deduplicated on the
 *     event name.
 *
 * @property {function(number, ...*): void} raiseOnceDelay - Raises the event with the specified parameters after a
 *     delay, deduplicated on the event name.
 *
 * @property {function(Function, ...*): void} raiseOnceDedupe - Raises the event, deduplicated by a key and the event
 *     name.
 *
 * @property {function(Function): void} iterateEvents - Iterates through events, invoking a handler for each.
 *
 * @property {function(number): Promise} wait - Waits for an event to be raised.
 *
 * @property {Object} invoke - Creates a function that raises an event passing the parameters.
 *
 * @property {EventObject} after - Creates an event that will be raised after the main event.
 *
 * @property {EventObject} before - Creates an event that will be raised before the main event.
 */

/**
 * Creates an event object with a set of helper methods for event handling,
 * raising, and waiting for events.
 *
 * @param {string} event - The name of the event.
 * @param {Object|Function} [options={}] - Configuration options or an extractor function.
 * @param {Function} [options.extract] - Function to extract desired values from the raised event's parameters.
 * @param {boolean} [options.isAsync] - Indicates if the event should be raised asynchronously.
 * @param {Object} [options.expose] - Options to expose certain functionalities under a different name or transform.
 *
 * @returns {EventObject} - An object with methods for event handling.
 *
 * @example
 * const userEvent = createEvent('user');
 * userEvent.on((user) => console.log(user));
 * userEvent.raise({name: 'John Doe'});
 */
function createEvent(event, options = {}) {
    if (typeof options === "function") {
        options = { extract: options }
    }
    const { extract = firstParam, isAsync, expose, parameters = (v) => v, extended } = options
    const subEvents = {}
    const asyncRaiser = extended ? raiseAsyncExtended : raiseAsync
    const raiser = extended ? raiseExtended : raise

    const eventDefinition = {
        eventName: event,
        subEvent: (...subEvent) => {
            const key = `${event}.${subEvent
                .filter((s) => typeof s === "string")
                .map((s) => s.toString())
                .join(".")}`
            return subEvents[key] ?? (subEvents[key] = createEvent(key, options))
        },
        call(...params) {
            return isAsync ? asyncRaiser(event, ...params).then(extract) : extract(raiser(event, ...params))
        },
        async callAsync(...params) {
            return extract(await asyncRaiser(event, ...params))
        },
        on(...params) {
            return handle(event, ...params)
        },
        handle(...params) {
            return handle(event, ...params)
        },
        handleId(identifier, ...params) {
            return handleId(identifier, event, ...params)
        },
        handleOnce(...params) {
            return handle(event, ...params)
        },
        raise(...params) {
            return isAsync ? asyncRaiser(event, ...params) : raiser(event, ...params)
        },
        async raiseAsync(...params) {
            return asyncRaiser(event, ...params)
        },
        once(...params) {
            return once(event, ...params)
        },
        raiseLater(...params) {
            return raiseLater(event, ...params)
        },
        raiseOnce(...params) {
            return raiseOnce(event, ...params)
        },
        raiseOnceDelay(delay, ...params) {
            return raiseOnceDelay(delay, event, ...params)
        },
        raiseOnceDedupe(keyFn, ...params) {
            return raiseOnceDedupe(event, keyFn, ...params)
        },
        iterateEvents(handler) {
            return iterateEvents(event, handler)
        },
        wait(timeout = Number.POSITIVE_INFINITY) {
            return waitForEvent(event, timeout)
        },
    }

    Object.setPrototypeOf(eventDefinition, eventTemplate)

    raise("DecorateEvent", eventDefinition)
    const definition = function sub(...params) {
        if (typeof params[0] === "function") {
            throw new Error("Test")
        }
        return eventDefinition.subEvent(...params)
    }
    Object.defineProperties(definition, {
        after: {
            get() {
                return createEvent(`after.${event}`)
            },
        },
        before: {
            get() {
                return createEvent(`before.${event}`)
            },
        },
        late: {
            get() {
                return createEvent(`late.${event}`)
            },
        },
        early: {
            get() {
                return createEvent(`early.${event}`)
            },
        },
        invoke: {
            get() {
                return redirectToEvent(event)
            },
        },
    })
    Object.assign(definition, eventDefinition)
    if (expose) {
        definition[expose.name ?? expose] = (...params) => {
            const transform = expose.transform ?? parameters
            if (transform) {
                if (typeof transform === "function") {
                    params = ensureArray(transform(params))
                } else if (typeof transform === "object") {
                    params[0] = { ...params[0], ...transform }
                }
            }
            return definition.call(...params)
        }
    }

    return definition
}

/**
 * Handles an event, returning a function to remove the handler
 * @param {string} event - the event to raise
 * @param {Function} handler - the handler to call
 * @returns {Function} a function to remove the handler
 */
function handle(event, handler) {
    if (handler?.$module?.hot) {
        handler.$module.hot.dispose(remove)
    }
    const internal = function innerHandler(event, ...params) {
        try {
            const result = handler.apply(this, params)
            if (result?.then) {
                return result.then(processResult)
            }
            return processResult(result)
        } catch (e) {
            console.error(e)
            return undefined
        }

        function processResult(result) {
            if (result === false) {
                event.preventDefault()
            }
            if (
                !!result &&
                typeof result === "object" &&
                !Array.isArray(result) &&
                !!params[0] &&
                typeof params[0] === "object"
            ) {
                Object.assign(params[0], result)
            }
        }
    }
    events.on(event, internal)
    return remove

    function remove() {
        events.off(event, internal)
    }
}

function once(event, handler) {
    const deregister = handle(event, (...params) => {
        deregister()
        handler(...params)
    })
    return deregister
}

/**
 * Executes a handler once, when an event occurs
 * @param {string} event - the event to raise
 * @param {Function} handler - the handler to call
 * @returns {Function} a function to remove the handler
 */
function when(event, handler) {
    return events.once(event, async (event, ...params) => {
        if ((await handler(...params)) === false) {
            event.preventDefault()
        }
    })
}

function iterateEvents(event, handler, finished = () => {}) {
    const removeHandler = handle(event, iteratingHandler)
    let resolvePromise
    const list = []
    const context = {
        event,
        emit(value) {
            if (resolvePromise) {
                const resolver = resolvePromise
                resolvePromise = null
                resolver({ value, done: false })
            } else {
                list.push(value)
            }
        },
    }
    const iterator = {
        return: () => {
            removeHandler()
            finished()
            return { value: null, done: true }
        },
        next() {
            return new Promise((resolve) => {
                resolvePromise = resolve
                if (list.length) {
                    setTimeout(() => {
                        context.emit(list.shift())
                    })
                }
            })
        },
    }
    return {
        [Symbol.asyncIterator]() {
            return iterator
        },
    }

    async function iteratingHandler(...params) {
        const result = await Promise.resolve(handler.call({ context, ...this }, ...params))
        if (result !== undefined) {
            context.emit(result)
        }
    }
}

events.iterateEvents = iterateEvents
events.raise = raise
events.raiseAsync = raiseAsync
events.handle = handle
events.when = when
events.waitForEvent = waitForEvent

/**
 * Raises an event after the current main loop
 * @param {string} event - the name of the event
 * @param {...*} params - the parameters to pass to the event
 */
function raiseLater(event, ...params) {
    setTimeout(() => {
        events.emit(event, ...params)
    })
}

const inProgress = new Map()

/**
 * Raises an event, deduplicated by a key and the event
 * @param {string} event - the name of the event
 * @param {Function} keyFn - a function to retrieve a key from the parameters
 * @param {...*} params - the parameters to pass to the event
 */
function raiseOnceDedupe(event, keyFn, ...params) {
    const key = `${event}:${JSON.stringify(keyFn(...params))}`
    if (inProgress.get(key)) return
    inProgress.set(
        key,
        setTimeout(() => {
            inProgress.delete(key)
            events.emit(event, ...params)
        }, 20)
    )
}

/**
 * Raises an event, after a delay, deduplicated on the event name
 * @param {number} delay - the delay in ms
 * @param {string} event - the name of the event
 * @param {...*} params - the parameters to pass to the event
 */
function raiseOnceDelay(delay, event, ...params) {
    if (inProgress.get(event)) return
    inProgress.set(
        event,
        setTimeout(() => {
            inProgress.delete(event)
            events.emit(event, ...params)
        }, delay)
    )
}

/**
 * Raises an event, deduplicated on the event name
 * @param {string} event - the name of the event
 * @param {...*} params - the parameters to pass to the event
 */
function raiseOnce(event, ...params) {
    if (inProgress.get(event)) return
    inProgress.set(
        event,
        setTimeout(() => {
            inProgress.delete(event)
            events.emit(event, ...params)
        }, 20)
    )
}

/**
 * Creates a function that raises an event passing the parameters
 * @param {string} eventName - the name of the event to raise
 * @returns {(function(...[*]): void)} a function to call the event
 */
function redirectToEvent(eventName) {
    return function redirector(...params) {
        raise(eventName, ...params)
    }
}

module.exports = {
    events,
    createEvent,
    raise,
    raiseAsync,
    handle,
    when,
    once,
    iterateEvents,
    waitForEvent,
    redirectToEvent,
    raiseOnce,
    raiseLater,
    raiseOnceDelay,
    raiseOnceDedupe,
    handleNamed: handleId,
    resetAllEvents,
    eventTemplate,
}
