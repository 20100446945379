import { Alert, Box, Divider, FormControlLabel, Radio, Typography } from "@mui/material"
import VertcialCenterGridItem from "./VertcialCenterGridItem"
import LicenseCard from "./LicenseCard"
import { useTheme } from "@mui/styles"
import { getStripePrices } from "../controller/get-stripe-prices"
import { getMyShareLinks } from "routes/shared-with-me/controller/get-my-share-links"
import { useUserMetadata } from "lib/store/user"
import { getStripeSubscription } from "plugins/stripe/controller/get-stripe-subscription"
import { DATE_FORMATS } from "lib/@constants/date-format"

const CollaborateCard = ({ selected }) => {
    const theme = useTheme()
    const collaborateColor = theme.palette.license.collaborate

    const { data } = getStripePrices.useResults.status()
    data?.getStripePrices.sort((a, b) => a.unit_amount - b.unit_amount)
    const lowestPrice = (parseFloat(data?.getStripePrices?.[0]?.unit_amount) / 100).toFixed(2)

    const links = getMyShareLinks.useResults()
    const hasLinksToView = links?.total > 0

    const meta = useUserMetadata()
    const { stripeCustomer } = meta || {}
    const { data: subscription } = getStripeSubscription.useResults.status(stripeCustomer || "")
    const { expiry } = subscription || {}
    const msExpiry = expiry * 1000
    const expired = subscription && msExpiry && msExpiry < Date.now()

    return (
        data && (
            <VertcialCenterGridItem>
                <label>
                    <FormControlLabel value="collaborate" sx={{ m: 0, display: "none" }} control={<Radio />} />
                    <LicenseCard borderColor={collaborateColor} selected={selected}>
                        <Box
                            sx={{
                                alignItems: "flex-start",
                                display: "flex",
                                flexDirection: "column",
                                gap: "16px",
                            }}
                        >
                            <Typography variant="h4">Collaborate Licence</Typography>
                            <Typography>Available from</Typography>
                            <Box
                                sx={{
                                    alignItems: "center",
                                    display: "inline-flex",
                                    gap: "10px",
                                    justifyContent: "center",
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: "#b26659",
                                        fontSize: "48px",
                                        fontWeight: "700",
                                        lineHeight: "36px",
                                    }}
                                >
                                    £{lowestPrice}
                                </Typography>
                                <Typography variant="body2" sx={{ maxWidth: "50px" }}>
                                    3 Day Access
                                </Typography>
                            </Box>
                            <Typography variant="body2" className="text-5">
                                One off payment
                            </Typography>
                            <Typography variant="body2">
                                This licence allows you to collaborate with subscribers by viewing SFG20 data shared to
                                you through a valid share link.
                            </Typography>
                            <Divider flexItem sx={{ borderStyle: "dashed" }} />
                            <Typography variant="subtitle1">Features:</Typography>
                            <Typography variant="body2">
                                View valid sharing links if you are a new user without an existing licence
                            </Typography>
                            <Typography variant="body2">
                                Access sharing links if you are an existing user, with an account that has expired
                            </Typography>
                            {!hasLinksToView && (
                                <Alert severity="warning">
                                    You do not have any active sharing links available to view
                                </Alert>
                            )}
                            {!!expired && (
                                <Alert severity="warning">
                                    Your previous license expired on {new Date(msExpiry).format(DATE_FORMATS.medium)}.
                                </Alert>
                            )}
                        </Box>
                    </LicenseCard>
                </label>
            </VertcialCenterGridItem>
        )
    )
}
export default CollaborateCard
