export function isChildOf(node, parentId) {
    while (node !== null) {
        if (node.id === parentId) {
            return true
        }
        node = node.parentNode
    }

    return false
}
