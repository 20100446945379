import { Bound } from "lib/@components/binding/Bound"
import { Box, Card } from "@mui/material"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function OneCard(props) {
    const { inCard } = useBoundContext()
    if (inCard) {
        return <Box {...props} />
    }
    return (
        <Bound inCard={true}>
            <Card {...props} />
        </Bound>
    )
}
