import { DecorateIndex, RefreshSchedules } from "event-definitions"

let treeIndex = { retrieve: () => null }

export function setTreeIndex(index) {
    index = DecorateIndex.call({ index })
    treeIndex = index
    RefreshSchedules.raiseOnceDelay(100)
}

export function getTreeIndex() {
    return treeIndex
}
