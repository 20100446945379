import React from "react"
import { Box } from "@mui/material"

export function PropertyBox({ children, spacing = 2, ...props }) {
    const count = React.Children.count(children)
    return (
        <Box display="flex" flexDirection="column" width="100%" justifyContent="stretch" {...props}>
            {React.Children.map(
                children,
                (c, i) =>
                    !!c && (
                        <Box key={i} mb={i !== count - 1 ? spacing : 0}>
                            {c}
                        </Box>
                    )
            )}
        </Box>
    )
}
