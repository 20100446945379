import { Outlet, useLocation } from "react-router-dom"
// @mui
import { Box, Stack } from "@mui/material"
// components
//
import MainFooter from "routes/@layouts/main/MainFooter"
import MainHeader from "routes/@layouts/main/MainHeader"

// ----------------------------------------------------------------------

export default function MainLayout() {
    const { pathname } = useLocation()

    const isHome = pathname === "/"

    return (
        <Stack sx={{ minHeight: 1 }}>
            <MainHeader />

            <Outlet />

            <Box sx={{ flexGrow: 1 }} />

            {!isHome && <MainFooter />}
        </Stack>
    )
}
