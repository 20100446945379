import { Button, Divider, Grid, RadioGroup, Stack, Typography } from "@mui/material"
import SubscriptionCard from "../components/SubscriptionCard"
import CollaborateCard from "../components/CollaborateCard"
import Iconify from "minimals-template/components/Iconify"
import { getStripeSubscription } from "plugins/stripe/controller/get-stripe-subscription"
import { useUserMetadata } from "lib/store/user"
import { displayIntervalLookup, pageNames } from "../paywall-constants"

function LicenceSelectPage({ setPage, selectedLicense, setSelectedLicense }) {
    const meta = useUserMetadata()
    const { stripeCustomer } = meta || {}

    const { data } = getStripeSubscription.useResults.status(stripeCustomer || "")
    const { amount, expiry, period, status, mode, portalUrl } = data || {}
    const msExpiry = expiry * 1000

    const displayPeriod = displayIntervalLookup[period]

    const licensed = data && msExpiry && msExpiry > Date.now()
    return (
        <>
            <Stack spacing={2}>
                <Typography variant="h4">Please select a licence type</Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    You do not currently have access to SFG20, you will need a licence to continue
                </Typography>
                <Divider flexItem sx={{ borderStyle: "dashed" }} />
            </Stack>
            {licensed && (
                <>
                    <Typography variant="body1" color="textSecondary">
                        <Iconify sx={{ color: "success.main", mr: 2 }} icon="mdi:tick" />
                        {`You have purchased a ${displayPeriod} license for ${(amount / 100).toLocaleString("en-gb", {
                            style: "currency",
                            currency: "GBP",
                        })}, which expires on `}
                        <span style={{ color: "rgb(56, 147, 155)" }}>{new Date(msExpiry).toLocaleString()} </span>
                        unless renewed. Monthly or yearly subscriptions should renew automatically.
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                        Status: {status}
                    </Typography>
                    {mode === "subscription" && portalUrl && (
                        <Button variant="contained" sx={{ m: 1 }} onClick={() => window.open(portalUrl, "_blank")}>
                            Manage Subscription
                        </Button>
                    )}
                </>
            )}
            <form>
                <RadioGroup name="licenseType" value={selectedLicense} onChange={licenseChange}>
                    <Grid container spacing={2} sx={{ mt: 0, mb: 6, flexDirection: "row-reverse" }}>
                        <SubscriptionCard selected={selectedLicense === "subscription"} />
                        <CollaborateCard selected={selectedLicense === "collaborate"} />
                    </Grid>
                </RadioGroup>
            </form>

            <Grid container spacing={2} sx={{ mt: 0, pt: 0, mb: 6, flexDirection: "row-reverse" }}>
                <Button
                    variant="contained"
                    startIcon={<Iconify icon="ic:round-keyboard-backspace" sx={{ transform: "scaleX(-1)" }} />}
                    sx={{
                        whiteSpace: "nowrap",
                    }}
                    onClick={() => setPage(selectedLicense === "subscription" ? pageNames.contact : pageNames.purchase)}
                >
                    Next
                </Button>
            </Grid>
        </>
    )
    function licenseChange(event) {
        const licenseType = event.target.value
        setSelectedLicense(licenseType)
    }
}

export default LicenceSelectPage
