import { useLocation } from "react-router-dom"
import { Suspense } from "react"
import LoadingScreen from "minimals-template/components/LoadingScreen"

export const Loadable = (Component) =>
    function Loadable(props) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { pathname } = useLocation()

        return (
            <Suspense fallback={<LoadingScreen description="Loadable" isDashboard={pathname.includes("/app")} />}>
                <Component {...props} />
            </Suspense>
        )
    }
