import noop from "lib/noop"
import { useEffect, useRef, useState } from "react"
import { Box, Pagination } from "@mui/material"
import { useMobile } from "lib/@components/mobile"
import { ListItemBox } from "lib/@components/ListItemBox"
import { setFromValueParam } from "lib/setFromEvent"
import { Repeat } from "lib/@components/repeat"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { createPortal } from "react-dom"
import { useReferenceState } from "lib/@hooks/use-reference"

import { defaultNumberOfRecords } from "lib/@components/pagination/default-number-of-records"
import { PageSize } from "lib/@components/pagination/page-size"
import { Frag } from "lib/@components/slot/frag"
import { PaginationPortalLoaded } from "lib/@components/pagination-portal"
import { noChange } from "lib/@hooks/useRefresh"

export function PagedRepeat({
    collection,
    page: initialPage = 1,
    onPageChange = noop,
    showBottom = true,
    showTop = false,
    showPaginator = true,
    portal,
    fallback = <Frag />,
    sx,
    color = "primary",
    variant,
    list = collection,
    pageSize: initialPageSize = 10,
    pageSizeName = "general",
    Component = Box,
    ...props
}) {
    const isMobile = useMobile()

    const [pageSize, setPageSize] = useReferenceState(`${pageSizeName}-general-page-size`, initialPageSize)
    const { paginationPortal = portal ? false : undefined } = useBoundContext()
    PaginationPortalLoaded.useRefresh(noChange)
    // eslint-disable-next-line prefer-const
    let [page, setPage] = useState(initialPage)
    const numberOfPages = Math.ceil(list.length / pageSize)
    const numberOnLastPage = list.length % pageSize
    page = Math.max(1, Math.min(page, numberOfPages))
    const lastNumberOfPages = useRef(numberOfPages)

    const paginator = showPaginator && list.length > defaultNumberOfRecords[0] && (
        <ListItemBox spacing={1}>
            <Box flex={1} />
            <PageSize setTake={setPageSize} take={pageSize} />
            <Pagination
                size="small"
                variant={variant}
                boundaryCount={isMobile ? 1 : undefined}
                siblingCount={isMobile ? 0 : undefined}
                onChange={setFromValueParam((v) => {
                    Promise.resolve(onPageChange(v)).then((isOk) => isOk !== false && setPage(v))
                })}
                color={color}
                count={numberOfPages}
                page={page}
                data-cy="paged-repeat-pagination"
            />
        </ListItemBox>
    )

    useEffect(() => {
        if (lastNumberOfPages.current !== numberOfPages) {
            setPage(1)
        } else if (page !== initialPage) onPageChange(page)
        lastNumberOfPages.current = numberOfPages
        // eslint-disable-next-line
    }, [page, onPageChange, initialPage, numberOfPages])
    return (
        <Component sx={sx}>
            {!!showTop && (
                <>
                    {paginationPortal
                        ? createPortal(paginator, paginationPortal)
                        : paginationPortal !== false
                          ? paginator
                          : null}
                </>
            )}
            {list.length ? <Repeat {...props} list={list.slice((page - 1) * pageSize, page * pageSize)} /> : fallback}
            {!!showBottom && (
                <>
                    {paginationPortal
                        ? createPortal(paginator, paginationPortal)
                        : paginationPortal === false
                          ? null
                          : (!showTop || page !== numberOfPages || numberOnLastPage > 4 || numberOnLastPage === 0) &&
                            paginator}
                </>
            )}
        </Component>
    )
}
