/**
 * Create a defer object promise - a promise
 * that has explict functions to resolve and reject it
 * @returns {Promise<any>} A promise with the decorated functions
 */
export function defer() {
    let resolved
    let rejected
    const promise = new Promise((resolve, reject) => {
        resolved = resolve
        rejected = reject
    })
    promise.resolve = (v) => resolved(v)
    promise.reject = (v) => rejected(v)
    return promise
}
