import { gql } from "@apollo/client"
import { mutate } from "lib/graphql/mutate"

export async function removeTags(basketId, referenceId) {
    await mutate(
        gql`
            mutation RemoveTags($basketId: String!, $referenceId: String!) {
                removeItemsFromBasket(id: $basketId, ids: [$referenceId])
            }
        `,
        {
            basketId,
            referenceId,
        }
    )
}
