export const tagsToIgnore = [
    { tagName: "a", placeholder: "ANCHOR" },
    { tagName: "img", placeholder: "IMAGE" },
    { tagName: "iframe", shouldRemove: true },
    { tagName: "input", shouldRemove: true },
    { tagName: "form", shouldRemove: true },
    { tagName: "button", shouldRemove: true },
]

export function ignoreTags(html, tags) {
    const ignoredTags = {}

    tags.forEach((tag) => {
        const { placeholder, tagName, shouldRemove } = tag
        const regex = new RegExp(`<${tagName}\\b([^>]*)>([^<]*)(<\\/${tagName}>)?`, "gi")
        if (shouldRemove) {
            html = html.replace(regex, "")
        } else {
            const { strippedHtml, tagArray } = storeTags(html, regex, placeholder, tagName)
            ignoredTags[placeholder] = tagArray
            html = strippedHtml
        }
    })

    return { html, ignoredTags }
}

export function insertIgnoredTags(key, ignoredTags, html) {
    switch (key) {
        case "ANCHOR":
            return insertTags(ignoredTags, html, formatURL)
        default:
            return insertTags(ignoredTags, html)
    }
}

function storeTags(html, regex, placeholder, tagName) {
    const tagArray = []
    const strippedHtml = html.replace(regex, (match, attributes, content, closingTag) => {
        const placeholderString = `__TAG_${placeholder}_${tagArray.length}_`
        const realTag = closingTag
            ? `<${tagName} ${attributes}>${content}${closingTag}`
            : `<${tagName} ${attributes} />`
        tagArray.push({ realTag, placeholderString })
        return placeholderString
    })
    return { strippedHtml, tagArray }
}

function insertTags(tags, html, formatTags = null) {
    tags.forEach((tag) => {
        if (formatTags) tag = formatTags(tag)
        const { placeholderString, realTag } = tag
        html = html.replace(placeholderString, realTag)
    })
    return html
}

function formatURL(tag) {
    const { realTag } = tag
    const hrefMatch = realTag.match(/href="([^"]*)"/)
    if (hrefMatch) {
        const url = hrefMatch[1]
        const containsDoubleSlash = /^https?:\/\//.test(url)
        if (!containsDoubleSlash) {
            tag.realTag = realTag.replace(`href="${url}"`, `href="//${url}"`)
        }
    }
    return tag
}
