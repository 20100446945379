import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"

export const getCurrentUser = api(() =>
    query(
        gql`
            query GetCurrentUser {
                getCurrentUser {
                    id
                    email
                    client
                    displayName
                    company
                    photoURL
                    jobTitle
                    skills
                    signature
                    UserName
                    metadata
                    numberOfClients
                    isOverridingClient
                }
            }
        `,
        {},
        { returns: "getCurrentUser" }
    )
)
