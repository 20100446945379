import { Box, Typography } from "@mui/material"

export function BadgeLabel({ color = "primary", sx, badgeContent, children }) {
    return (
        <Box
            className="badge-label"
            sx={{
                display: "inline-block",
                borderRadius: 1,
                px: 1,
                py: 0.5,
                alignSelf: "center",
                bgcolor: (theme) => theme.palette[color].main,
                color: (theme) => theme.palette[color].contrastText,
                ...sx,
            }}
        >
            <Typography variant="overline" component="div">
                {badgeContent}
                {children}
            </Typography>
        </Box>
    )
}
