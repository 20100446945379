import { useLocation } from "react-router-dom"
// @mui
import { styled, useTheme } from "@mui/material/styles"
import { AppBar, Box, Container, Toolbar } from "@mui/material"
// hooks
import Logo from "minimals-template/components/Logo"
import useOffSetTop from "minimals-template/components/@hooks/useOffSetTop"
import useResponsive from "minimals-template/components/@hooks/useResponsive"
// utils
import cssStyles from "minimals-template/components/utils/cssStyles"
// config
import { HEADER } from "config"
// components
//

// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    height: HEADER.MOBILE_HEIGHT,
    transition: theme.transitions.create(["height", "background-color"], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    [theme.breakpoints.up("md")]: {
        height: HEADER.MAIN_DESKTOP_HEIGHT,
    },
}))

const ToolbarShadowStyle = styled("div")(({ theme }) => ({
    left: 0,
    right: 0,
    bottom: 0,
    height: 24,
    zIndex: -1,
    margin: "auto",
    borderRadius: "50%",
    position: "absolute",
    width: `calc(100% - 48px)`,
    boxShadow: theme.customShadows.z8,
}))

// ----------------------------------------------------------------------

export default function MainHeader() {
    const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT)

    const theme = useTheme()

    useLocation()

    useResponsive("up", "md")

    return (
        <AppBar sx={{ boxShadow: 0, bgcolor: "transparent" }}>
            <ToolbarStyle
                disableGutters
                sx={{
                    ...(isOffset && {
                        ...cssStyles(theme).bgBlur(),
                        height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
                    }),
                }}
            >
                <Container
                    sx={{
                        pt: "env(safe-area-inset-top)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Logo />

                    <Box sx={{ flexGrow: 1 }} />
                </Container>
            </ToolbarStyle>

            {isOffset && <ToolbarShadowStyle />}
        </AppBar>
    )
}
