export const pageNames = {
    licenceSelect: "licenceSelect",
    contact: "contact",
    purchase: "purchase",
}

export const displayIntervalLookup = {
    day: "daily",
    "3day": "three day",
    week: "weekly",
    month: "monthly",
    year: "yearly",
}
export const durationInSeconds = {
    day: 86400,
    "3day": 259200,
    week: 604800,
    month: 2592000,
    year: 31536000,
}
