import { Alert, Button, DialogActions, DialogContent, DialogTitle } from "@mui/material"

export function AlertDialog({ message, title, ok, severity }) {
    return (
        <>
            {title && <DialogTitle>{title}</DialogTitle>}
            <DialogContent sx={{ mt: 2 }}>
                {severity ? <Alert severity={severity}>{message}</Alert> : message}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => ok(true)} color="primary" variant="contained">
                    Ok
                </Button>
            </DialogActions>
        </>
    )
}
