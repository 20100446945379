import noop from "lib/noop"
import { useEffect, useRef } from "react"
import { Box, useTheme } from "@mui/material"
import { setCurrentCursorPosition } from "lib/@components/editable/setCurrentCursorPosition"
import { getCurrentCursorPosition } from "lib/@components/editable/getCurrentCursorPosition"

export function EditableBox({
    value,
    multiline,
    readOnly,
    placeholder,
    error,

    label = placeholder,
    onChange = noop,
    ...props
}) {
    const cursor = useRef(0)
    const ref = useRef()
    const theme = useTheme()
    useEffect(() => {
        try {
            if (document.activeElement === ref.current) {
                if (cursor.current) {
                    setCurrentCursorPosition(ref.current, cursor.current)
                }
            }
        } catch (e) {
            //
        }
    }, [value])
    return (
        <Box
            ref={ref}
            tabIndex={0}
            contentEditable={!readOnly}
            onKeyDown={handleSpaceKey}
            onInput={handleInput}
            {...props}
            sx={{
                ...props.sx,
                position: "relative",
                borderColor: error ? "error.main" : props.sx?.borderColor,
                "&:focus": {
                    outline: "1px solid",
                    outlineColor: error
                        ? "error.main"
                        : readOnly
                        ? theme.palette.action.focus
                        : theme.palette.action.active,
                    borderRadius: 1,
                    pl: 0.5,
                    pr: 0.5,
                    pt: 0.5,
                    ml: -0.5,
                    mt: -0.5,
                    mr: 0.5,
                    pb: 0.5,
                    mb: 0,
                    transform: "translateY(-1px)",
                    border: "none !important",
                },
                "&:after":
                    label &&
                    (!value
                        ? {
                              content: `"${label}"`,
                              position: "absolute",
                              color: "action.focus",
                              left: 0,
                              top: 0,
                          }
                        : {
                              content: `"${label}"`,
                              position: "absolute",
                              color: "action.active",
                              left: 0,
                              fontSize: "75%",
                              top: -20,
                          }),

                "&:focus:after":
                    label &&
                    (!value
                        ? {
                              content: `"${label}"`,
                              position: "absolute",
                              color: "action.focus",
                              left: 4,
                              top: 4,
                          }
                        : {
                              content: `"${label}"`,
                              position: "absolute",
                              color: "action.active",
                              left: 4,
                              fontSize: "75%",
                              top: -16,
                          }),
            }}
            minHeight={10}
            dangerouslySetInnerHTML={{ __html: value ?? "" }}
        />
    )

    function handleSpaceKey(event) {
        if (readOnly) {
            event.preventDefault()
            event.stopPropagation()
            return
        }
        if (event.code === "Enter") {
            if (!multiline) {
                event.preventDefault()
                event.stopPropagation()
                return
            }
        }
        if (event.code !== "Space") {
            return
        }
        event.preventDefault()
        event.stopPropagation()
        document.execCommand("insertText", false, " ")
    }

    function handleInput(event) {
        cursor.current = getCurrentCursorPosition(ref.current.id)
        if (readOnly) {
            onChange(value)
        } else {
            onChange(event.target.innerText)
        }
    }
}
