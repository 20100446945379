import { generate } from "library/guid"
import { Box } from "@mui/material"
import { createEvent } from "library/local-events"

const ReactDOM = require("react-dom")

const allPortals = {}

const RefreshPortal = createEvent("RefreshPortal")

export function createPortalPair(id = generate(), Component = Box) {
    return [Portal, PortalUser]

    function Portal({ ...props }) {
        return <Component {...props} ref={attach} />
    }

    function PortalUser({ children }) {
        RefreshPortal(id).useRefresh()
        return !!allPortals[id] && ReactDOM.createPortal(children, allPortals[id])
    }

    function attach(item) {
        allPortals[id] = item ?? allPortals[id]
        if (item) {
            RefreshPortal(id).raise()
        }
    }
}
