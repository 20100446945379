import { omit } from "lib/graphql/omit"
import { getApolloClient } from "lib/graphql/client"
import { HasInvalidated } from "event-definitions"

const runningCache = {}

HasInvalidated("*").before.handleOnce(function invalidateCache() {
    const query = this.event.split(".").at(-1)
    Object.keys(runningCache).forEach((key) => {
        if (key.startsWith(query)) delete runningCache[key]
    })
})

export async function query(query, variables = {}, options = {}) {
    const parameters = { query, variables, ...omit(options, "returns") }
    const key = `${options.returns?.split(".")[0] ?? ""}.${JSON.stringify(parameters)}`
    const transform = options.transform ?? ((v) => v)
    if (runningCache[key]) {
        const { data, error } = await runningCache[key]
        if (error) {
            throw error
        }
        if (!options.returns) {
            return data
        }
        const result = Object.get(data, options.returns, true)
        return transform(result) ?? result
    }
    runningCache[key] = getApolloClient().query(parameters)
    try {
        const { data, error } = await runningCache[key]

        if (error) {
            throw error
        }
        if (!options.returns) {
            return transform(data) ?? data
        }
        const result = Object.get(data, options.returns, true)
        return transform(result) ?? result
    } finally {
        delete runningCache[key]
    }
}
