import { gql } from "@apollo/client"

export const GET_SCHEDULE = gql`
    query getSchedule($code: String!, $version: String) {
        schedule(code: $code, version: $version) {
            id
            _id
            title
            retired
            code
            unitOfMeasure
            coreTagDescriptions
            originatingScheduleId
            originatingScheduleCode
            originatingScheduleVersion
            mismatchedVersion
            nrm {
                code
                description
            }
            uniclass {
                code
                description
            }
            version
            intro {
                content
                note
            }
            metadata {
                published
                publishedBy
            }
            type {
                Name
            }
            tasks {
                id
                content
                title
                note
                isHeader
                minutes
                classification
                frequency {
                    period
                    interval
                }
                skillSet
                skill {
                    Skilling
                    SkillingCode
                }
                unitOfMeasure
            }
            legislation {
                HTML
                URL
            }
        }
    }
`
