import memoize from "memoizee"
import { Bound } from "lib/@components/binding/Bound"
import { ensureArray } from "lib/ensure-array"
import { usePlug } from "lib/@components/slot/use-plug"
import { useItems } from "lib/@components/slot/use-items"
import { plug } from "lib/@components/slot/plug"
import React from "react"
import { Frag } from "lib/@components/slot/frag"
import { Slot } from "lib/@components/slot/slot"
import { useSlot } from "lib/@components/slot/use-slot"
import { replaceAllWith } from "lib/@components/slot/replace-all-with"

/**
 *
 * @param {string} name
 * @returns {{
 *      (): any,
 *      subType: (...extension)=>any,
 *      plug: (component: JSX.Element)=>any,
 *      Slot: (props: Record<string, any>)=>JSX.Element,
 *      Plug: (props: Record<string, any>)=>JSX.Element,
 *      useItems: (items: Array<any>)=>Array<any>,
 *      useSlot: (Container: JSX.Element, props: Record<string, any>)=>JSX.Element,
 *      usePlug: (Component: JSX.Element, deps: [any])=>void
 * }}
 */
export const createSlot = memoize(function createSlot(name) {
    const slotDefinition = {
        subType: memoize((...extension) => createSlot(`${name}.${extension.join(".")}`), {
            primitive: true,
            length: false,
        }),
        Slot({ type, target, Container, onEmpty, ...props }) {
            const slot = (
                <Slot {...props} Container={Container} onEmpty={onEmpty} type={name + (type ? `.${type}` : "")} />
            )
            if (target) {
                return <Bound target={target}>{slot}</Bound>
            }
            return slot
        },
        Plug({ type, replace, children, deps = [] }) {
            children = ensureArray(children)
            for (const child of children) {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                usePlug(
                    type ? `${name}.${type}` : name,
                    replace ? replaceAllWith(child || <Frag />) : child || <Frag />,
                    deps
                )
            }
            return null
        },
        Wrapper({ type, children, ...props }) {
            const components = useItems(type ? `${name}.${type}` : name)

            for (let i = 0; i < components.length; i++) {
                const Type = components[i].type
                children = <Type key={components[i].key} {...components[i].props} {...props} children={children} />
            }
            return children
        },
        useItems(items = []) {
            return useItems(name, items)
        },
        useTypedItems(type, items = []) {
            return useItems(`${name}.${type}`, items)
        },
        plug(component) {
            return plug(name, component)
        },
        usePlug(component, deps = []) {
            return usePlug(name, component, deps)
        },
        useSlot(Container = <Frag />, props = {}) {
            return useSlot(name, Container, props)
        },
        useTypedSlot(type, Container = <Frag />, props = {}) {
            return useSlot(`${name}.${type}`, Container, props)
        },
    }
    const slotFunction = (...extension) => slotDefinition.subType(...extension)
    Object.assign(slotFunction, slotDefinition)
    Object.defineProperties(slotFunction, {
        after: {
            get() {
                return createSlot(`after.${name}`)
            },
        },
        before: {
            get() {
                return createSlot(`before.${name}`)
            },
        },
    })
    return slotFunction
})
