import { gql } from "@apollo/client"
import { mutate } from "lib/graphql/mutate"

export async function addTag(basketId, referenceId) {
    await mutate(
        gql`
            mutation AddTag($basketId: String!, $referenceId: String!) {
                addItemsToBasket(id: $basketId, items: [{ id: $referenceId, referenceId: $referenceId }])
            }
        `,
        {
            basketId,
            referenceId,
        }
    )
}
