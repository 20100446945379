// import { split } from "@apollo/client"
// import { getMainDefinition } from "@apollo/client/utilities"
// import { wsLink } from "lib/graphql/ws-link"
import { requestLink } from "lib/graphql/request-link"

export const splitLink = requestLink

// export const splitLink = split(
//     ({ query }) => {
//         const definition = getMainDefinition(query)
//         return definition.kind === "OperationDefinition" && definition.operation === "subscription"
//     },
//     wsLink,
//     requestLink
// )
