import { ApolloLink, HttpLink, Observable, split } from "@apollo/client"
import { getMainDefinition } from "@apollo/client/utilities"
import { BatchHttpLink } from "@apollo/client/link/batch-http"
import { onError } from "@apollo/client/link/error"

const UseHttpLink = window.Cypress ? HttpLink : BatchHttpLink

const createErrorHandlingLink = () =>
    onError(({ networkError }) => {
        if (networkError && networkError.message?.includes("Failed to fetch")) {
            console.error("Server is unreachable:", networkError)

            // Return null to the caller
            return new Observable((observer) => {
                observer.next({ data: null }) // Respond with null data
                observer.complete() // Complete the Observable
            })
        }
        return undefined
    })

const httpLinkMain = new UseHttpLink({
    credentials: "include",
    batchInterval: 75,
    batchMax: 20,
    uri: (operation) =>
        `${window.appProtocol || process.env.REACT_APP_PROTOCOL}://${
            window.appServerLocation || process.env.REACT_APP_SERVER
        }/graphql?o=${operation.operationName}${process.env.COMPANION ? "&companion=true" : ""}`,
})

const httpLinkApi = new UseHttpLink({
    batchInterval: 50,
    credentials: "include",
    uri: (operation) =>
        `${window.appProtocol || process.env.REACT_APP_PROTOCOL}://${
            window.appServerLocation || process.env.REACT_APP_SERVER
        }/v3.0?o=${operation.operationName}${process.env.COMPANION ? "&companion=true" : ""}`,
})

// Wrap each link with error handling
const errorHandlingLinkMain = ApolloLink.from([createErrorHandlingLink(), httpLinkMain])
const errorHandlingLinkApi = ApolloLink.from([createErrorHandlingLink(), httpLinkApi])

export const httpLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query)
        return !definition.name.value.startsWith("API_")
    },
    errorHandlingLinkMain,
    errorHandlingLinkApi
)
