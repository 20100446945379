import PropTypes from "prop-types"
import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
// @mui
import { Collapse, Link, List } from "@mui/material"
import { IsNavItemActive } from "event-definitions"
import { resolveAsFunction } from "lib/resolve-as-function"
import { getActive, isExternalLink } from "minimals-template/components/nav-section/index"
import NavItem from "minimals-template/components/nav-section/vertical/NavItem"
import { adjustPath } from "lib/routes/adjust-path"

// ----------------------------------------------------------------------

NavList.propTypes = {
    data: PropTypes.object,
    depth: PropTypes.number,
    hasChildren: PropTypes.bool,
    isCollapse: PropTypes.bool,
}

export default function NavList({ data, depth, hasChildren, isCollapse = false }) {
    const navigate = useNavigate()

    const location = useLocation()

    const { active } = IsNavItemActive.call({
        navItem: data,
        location,
        active: getActive(data.path, location.pathname, data.subPaths, location),
    })

    const [open, setOpen] = useState(active)

    const handleClickItem = () => {
        const result = data.subPaths?.onClick?.()
        if (!hasChildren && result !== false) {
            const path = adjustPath(resolveAsFunction(data.path)())

            if (path) {
                navigate(path)
            }
        }
        setOpen(!open)
    }

    return data.content ? (
        <data.content.type
            data={data}
            depth={depth}
            open={open}
            active={active}
            isCollapse={isCollapse}
            onClick={handleClickItem}
            {...data.content.props}
        />
    ) : (
        <>
            {isExternalLink(data.path) ? (
                <Link href={data.path} target="_blank" rel="noopener" underline="none">
                    <NavItem
                        className="nav-item external"
                        item={data}
                        depth={depth}
                        open={open}
                        active={active}
                        isCollapse={isCollapse}
                    />
                </Link>
            ) : (
                <NavItem
                    className="nav-item internal"
                    item={data}
                    depth={depth}
                    open={open}
                    active={active}
                    isCollapse={isCollapse}
                    onClick={handleClickItem}
                />
            )}

            {!isCollapse && hasChildren && (
                <Collapse in={open} unmountOnExit>
                    <List component="div" disablePadding>
                        <NavSubList data={data.children} depth={depth} />
                    </List>
                </Collapse>
            )}
        </>
    )
}

// ----------------------------------------------------------------------

NavSubList.propTypes = {
    data: PropTypes.array,
    depth: PropTypes.number,
}

function NavSubList({ data, depth }) {
    return (
        <>
            {data.map((list) => (
                <NavList key={list.title + list.path} data={list} depth={depth + 1} hasChildren={!!list.children} />
            ))}
        </>
    )
}
