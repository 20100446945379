import Color from "color"
import primaryPalette from "./palette-styles/primary-palette.scss"
import greyPalette from "./palette-styles/grey-palette.scss"

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
    return `linear-gradient(to bottom, ${color1}, ${color2})`
}

// SETUP COLORS
const PRIMARY = {
    lighter: primaryPalette.primary_lighter,
    light: primaryPalette.primary_light,
    main: primaryPalette.primary,
    dark: primaryPalette.primary_dark,
    darker: primaryPalette.primary_darker,
}
const SECONDARY = {
    lighter: lighten("#9C6138", 0.4),
    light: lighten("#9C6138", 0.2),
    main: "#9C6138",
    dark: lighten("#9C6138", -0.2),
    darker: lighten("#9C6138", -0.4),
}
const UNLICENSED = {
    lighter: lighten("#9C6138", 0.4),
    light: lighten("#9C6138", 0.2),
    main: "#9C6138",
    dark: lighten("#9C6138", -0.2),
    darker: lighten("#9C6138", -0.4),
}
const INFO = {
    lighter: "#D1E9FF",
    light: "#74CAFF",
    main: "#1890FF",
    dark: "#1165B3",
    darker: "#0A3A66",
}
const SUCCESS = {
    lighter: "#CCEEDD",
    light: "#66CD99",
    main: "#00AB55",
    dark: "#00783B",
    darker: "#004422",
}
const WARNING = {
    lighter: "#FFF3CD",
    light: "#FFDA6A",
    main: "#FFC107",
    dark: "#B38705",
    darker: "#664D03",
}
const ERROR = {
    lighter: "#FFDAD9",
    light: "#FF918E",
    main: "#FF4842",
    dark: "#B3322E",
    darker: "#661D1A",
}
const MY_STUFF = {
    lighter: "#FFEDE8",
    light: "#FFC8BB",
    main: "#FFA48D",
    dark: "#B37363",
    darker: "#664238",
}

const GREY = {
    0: greyPalette.grey000,
    100: greyPalette.grey100,
    200: greyPalette.grey200,
    300: greyPalette.grey300,
    400: greyPalette.grey400,
    500: greyPalette.grey500,
    600: greyPalette.grey600,
    700: greyPalette.grey700,
    800: greyPalette.grey800,
    900: greyPalette.grey900,
    500_8: greyPalette.grey500_8,
    500_12: greyPalette.grey500_12,
    500_16: greyPalette.grey500_16,
    500_24: greyPalette.grey500_24,
    500_32: greyPalette.grey500_32,
    500_48: greyPalette.grey500_48,
    500_56: greyPalette.grey500_56,
    500_80: greyPalette.grey500_80,
}

const GRADIENTS = {
    primary: createGradient(PRIMARY.light, PRIMARY.main),
    secondary: createGradient(SECONDARY.light, SECONDARY.main),
    myStuff: createGradient(MY_STUFF.light, MY_STUFF.main),
    info: createGradient(INFO.light, INFO.main),
    success: createGradient(SUCCESS.light, SUCCESS.main),
    warning: createGradient(WARNING.light, WARNING.main),
    error: createGradient(ERROR.light, ERROR.main),
}

const CHART_COLORS = {
    violet: ["#826AF9", "#9E86FF", "#D0AEFF", "#F7D2FF"],
    blue: ["#2D99FF", "#83CFFF", "#A5F3FF", "#CCFAFF"],
    green: ["#2CD9C5", "#60F1C8", "#A4F7CC", "#C0F2DC"],
    yellow: ["#FFE700", "#FFEF5A", "#FFF7AE", "#FFF3D6"],
    red: ["#FF6C40", "#FF8F6D", "#FFBD98", "#FFF2D4"],
}

const COMMON = {
    common: { black: "#000", white: "#fff" },
    primary: { ...PRIMARY, contrastText: "#fff" },
    secondary: { ...SECONDARY, contrastText: "#fff" },
    info: { ...INFO, contrastText: "#fff" },
    success: { ...SUCCESS, contrastText: GREY[800] },
    warning: { ...WARNING, contrastText: GREY[800] },
    error: { ...ERROR, contrastText: "#fff" },
    grey: GREY,
    gradients: GRADIENTS,
    chart: CHART_COLORS,
    myStuff: MY_STUFF,
    unlicensed: UNLICENSED,
    divider: GREY[500_24],
    license: { collaborate: "#B26659", subscription: PRIMARY.main },
    action: {
        hover: GREY[500_8],
        selected: GREY[500_16],
        disabled: GREY[500_80],
        disabledBackground: GREY[500_24],
        focus: GREY[500_24],
        hoverOpacity: 0.08,
        disabledOpacity: 0.48,
    },
}

const palette = {
    light: {
        ...COMMON,
        mode: "light",
        text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
        background: { paper: "#ffffff", default: "#ffffff", neutral: GREY[200] },
        action: { active: GREY[600], ...COMMON.action },
    },
    dark: {
        ...COMMON,
        primary: { ...lighten(PRIMARY, 0.05), contrastText: "#ffffff" },
        mode: "dark",
        text: { primary: GREY[200], secondary: GREY[500], disabled: GREY[600] },
        background: { paper: GREY[800], default: GREY[900], neutral: GREY[500_16] },
        action: { active: GREY[500], ...COMMON.action },
    },
}

function lighten(colorDescriptor, amount) {
    if (typeof colorDescriptor === "string") {
        return Color(colorDescriptor).lighten(amount).hex()
    }
    const output = {}
    for (const [name, value] of Object.entries(colorDescriptor)) {
        output[name] = lighten(value, amount)
    }
    return output
}

export default palette
