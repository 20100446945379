// @mui
import { styled } from "@mui/material/styles"
import { CardContent, Typography } from "@mui/material"
import { omit } from "lib/omit"
import { OneCard } from "lib/@components/one-card"
import { NotOnMobile } from "lib/@components/mobile"

// ----------------------------------------------------------------------

const RootStyle = styled(OneCard)(({ theme }) => ({
    boxShadow: "none",
    textAlign: "center",
    backgroundColor: theme.palette.primary.lighter,
    [theme.breakpoints.up("md")]: {
        display: "flex",
        textAlign: "left",
        alignItems: "center",
        justifyContent: "space-between",
    },
}))

export default function AppWelcome({ title, description, action, img, ...other }) {
    return (
        <RootStyle {...omit(other, "addFolder", "toAdd", "folderName", "addSchedules")}>
            <CardContent
                sx={{
                    p: { md: 2 },
                    pl: { md: 5 },
                    color: "grey.800",
                }}
            >
                <Typography component="div" gutterBottom variant="h4" sx={{ whiteSpace: "pre-line" }}>
                    {title}
                </Typography>

                <Typography variant="body2" component="div" sx={{ pb: { xs: 3, xl: 5 }, maxWidth: 480, mx: "auto" }}>
                    {description}
                </Typography>

                {action && action}
            </CardContent>
            <NotOnMobile>{img && img}</NotOnMobile>
        </RootStyle>
    )
}
