import { useEffect, useState } from "react"

export function Delayed({ time = 10000, children }) {
    const [visible, setVisible] = useState(false)
    useEffect(() => {
        const id = setTimeout(() => setVisible(true), time)
        return () => clearTimeout(id)
    }, [time])
    return visible ? children : null
}
