/**
 * Returns an equivalence test for two values, based on content
 * @param {any} a
 * @param {any} b
 * @returns {boolean}
 */
import { normalizedStringify } from "library/normalized-stringify"

export function isEqual(a, b) {
    if (a instanceof Date) {
        a = a.toISOString()
    }
    if (b instanceof Date) {
        b = b.toISOString()
    }
    if (typeof a !== typeof b) return false
    if (typeof a === "object") {
        return normalizedStringify(a) === normalizedStringify(b)
    }
    return a === b
}
