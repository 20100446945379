import { ApolloClient, ApolloLink } from "@apollo/client"
import { CurrentClientChanged, UserAuthenticated } from "event-definitions"
//import { reconnectSocket } from "lib/graphql/reconnect-socket"
import { createCache } from "lib/graphql/cache"
import { links } from "lib/graphql/links"
import { addDebugItem } from "debug-window"

let client = createApolloClient()

function createApolloClient() {
    return new ApolloClient({
        link: ApolloLink.from(links),
        cache: createCache(),
        defaultOptions: {
            query: {
                fetchPolicy: "cache-first",
            },
        },
    })
}

export function getApolloClient() {
    return client
}

CurrentClientChanged.after.handleOnce(() => {
    addDebugItem("Current client changed")
    // reconnectSocket()
})
UserAuthenticated.after.handleOnce(() => {
    addDebugItem("User authenticated")
    // reconnectSocket()
})
