// ----------------------------------------------------------------------

function path(root, sublink) {
    return `${root}${sublink}`
}

const ROOTS_AUTH = ""

// ----------------------------------------------------------------------

export const PATH = {
    TOS: process.env.REACT_APP_TOS || "https://www.sfg20.co.uk/t-c-s-for-website-use",
    PRIVACY_POLICY: process.env.REACT_APP_PRIVACY_POLICY || "https://www.sfg20.co.uk/privacy-policy",
    CONTACT_US: process.env.REACT_APP_CONTACT || "https://www.sfg20.co.uk/contact-1",
}

export const PATH_AUTH = {
    root: ROOTS_AUTH,
    welcome: path(ROOTS_AUTH, "/"),
    login: path(ROOTS_AUTH, "/login"),
    logout: path(ROOTS_AUTH, "/logout"),
    register: path(ROOTS_AUTH, "/register"),
    loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
    registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
    verify: path(ROOTS_AUTH, "/verify"),
    resetPassword: path(ROOTS_AUTH, "/reset-password"),
    newPassword: path(ROOTS_AUTH, "/new-password"),
}

export const PATH_DASHBOARD = {
    root: !process.env.COMPANION ? "/app" : "/sfg20",
}

export const PATH_DOCS = "https://docs-minimals.vercel.app/introduction"
