import { Button, Divider, Grid, Stack, Typography } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { ContactForm } from "../components/ContactForm"
import { useSave } from "lib/@hooks/use-save"
import { Bound } from "lib/@components/binding/Bound"
import { useRef } from "react"
import { useYupContext } from "lib/@components/binding/use-yup-wrapper"
import { successSnackbar } from "lib/snackbar/success-snackbar"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import { createCustomerSupportEmail } from "routes/contact-us/controller/create-support-email"
import { useUserMetadata } from "lib/store/user"
import { pageNames } from "../paywall-constants"

function ContactPage({ setPage }) {
    const { displayName, email } = useUserMetadata()
    const formContents = {
        subject: "Interest in a SFG20 Facilities-iQ Subscription",
        message: `Hello,<br />Please contact me to discuss a SFG20 Facilities-iQ Subscription. <br />Regards,<br />${displayName}<br />(${email})`,
    }
    const errors = useRef({})
    const [yupContext, validate] = useYupContext()
    const change = useSave({ save, target: formContents })
    return (
        <>
            <Stack spacing={2}>
                <Typography variant="h4">Contact us to discuss a Subscription</Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    Get in touch with one of our experts today to discover how SFG20 can help your teams facilities
                    management needs.
                </Typography>
                <Divider flexItem sx={{ borderStyle: "dashed" }} />
            </Stack>
            <Bound target={formContents} errors={errors.current} yupContext={yupContext}>
                <form onSubmit={handleContactFormSubmit} id="contact-form">
                    <ContactForm />
                </form>
            </Bound>
            <Grid container spacing={2} sx={{ mt: 2, mb: 6, flexDirection: "row-reverse", gap: 2 }}>
                <Button
                    variant="contained"
                    form="contact-form"
                    type="submit"
                    startIcon={<Iconify icon="ic:round-send" />}
                    sx={{
                        whiteSpace: "nowrap",
                    }}
                >
                    Send
                </Button>
                <Button
                    variant="contained"
                    startIcon={<Iconify icon="ic:round-keyboard-backspace" />}
                    sx={{
                        whiteSpace: "nowrap",
                    }}
                    onClick={() => setPage(pageNames.licenceSelect)}
                >
                    Back
                </Button>
            </Grid>
        </>
    )

    function handleContactFormSubmit(event) {
        event.preventDefault()
        change()
    }

    async function save(profile) {
        try {
            if (validate()) {
                await sendSupportEmail(profile)
            }
        } catch (e) {
            errorSnackbar("Failed to save profile")
        }
    }
    async function sendSupportEmail(formData) {
        const retVal = await createCustomerSupportEmail({ ...formData, attachments: [] })
        if (retVal) {
            successSnackbar("Support request sent")
        } else {
            errorSnackbar("Support request failed")
        }
    }
}

export default ContactPage
