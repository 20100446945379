import React, { useState } from "react"
import { Box, Typography } from "@mui/material"
import { createPortal } from "react-dom"

export function SlotLabel({ __type }) {
    const [ref, setRef] = useState()
    const rect = ref ? ref.parentElement.getBoundingClientRect() : undefined

    return (
        <>
            <Box ref={attachToParent} />
            {!!rect &&
                createPortal(
                    <Box
                        sx={{
                            position: "absolute",
                            left: rect.x,
                            top: rect.y,
                            width: rect.width,
                            height: rect.height,
                            outline: "2px solid #f50a",

                            zIndex: 10000000,
                        }}
                    >
                        <Typography variant="caption" sx={{ px: 2, py: 0.3, background: "#fff9", borderRadius: 1 }}>
                            {__type}
                        </Typography>
                    </Box>,
                    document.body
                )}
        </>
    )

    function attachToParent(parent) {
        setRef(parent)
    }
}
