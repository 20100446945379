import { generate } from "library/guid"
import React from "react"

export function conditionalComponent(predicate, component) {
    processCondition.$id = component.type?.name ?? component.name ?? generate()
    return processCondition

    function processCondition(props) {
        if (predicate(props)) {
            const Type = component.type
            return <Type {...component.props} {...props} />
        }
        return undefined
    }
}
