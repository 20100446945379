import { server } from "lib/app-server"

export async function serverVersion() {
    const response = await server("/version")
    if (response.ok) {
        return response.json()
    }
    return {
        version: "Not returned by server",
    }
}
