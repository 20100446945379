import noop from "lib/noop"
import { useItems } from "lib/@components/slot/use-items"
import { getSlotContentElementsWithWrapper } from "lib/@components/slot/get-slot-content-elements-with-wrapper"
import React, { useMemo } from "react"
import { nextTick } from "lib/next-tick"
import { Frag } from "lib/@components/slot/frag"
import { usePropsObject } from "lib/@hooks/use-props-object"

const defaultEmptyChildren = Object.seal(Object.freeze([]))

/**
 * Creates a slot with a known `type` that "plugs" may be added into.
 * The plugs may be individually wrapped with a `<Container/>` and all other properties are
 * passed to the plug instance.
 * @type {React.FunctionComponent<React.PropsWithoutRef<{type: String, Container: React.ReactElement}>>}
 */
export function Slot({ type, predicate, onEmpty = noop, children = [], wrapper, Container, ...props }) {
    const Type = Container?.type

    const containerProps = Container?.props
    let items = useItems(
        type,
        useMemo(
            () =>
                (Array.isArray(children) && children.length) || (!Array.isArray(children) && !!children)
                    ? [
                          <Frag key="slot-children" clean>
                              {children}
                          </Frag>,
                      ]
                    : defaultEmptyChildren,
            [children]
        )
    )
    if (predicate) {
        items = items.filter(predicate)
    }
    const memoProps = usePropsObject(props)
    const contents = useMemo(
        () => getSlotContentElementsWithWrapper(items, memoProps, containerProps, Type),
        [items, memoProps, containerProps, Type]
    )
    if (!items.length) {
        nextTick(() => onEmpty(true))
    } else {
        nextTick(() => onEmpty(false))
    }
    return wrapper ? <wrapper.type {...wrapper.props}>{contents}</wrapper.type> : contents
}
