import { useState } from "react"
import Iconify from "minimals-template/components/Iconify"
import IconButton from "@mui/material/IconButton"
import noop from "lib/noop"
import { withSecure } from "lib/authorization/secure"
import { warningSnackbar } from "lib/snackbar/warning-snackbar"
import { TextField } from "@mui/material"

export const LockableTextField = ({
    canUnlock = noop,

    warningMessage = "You can now edit this field",
    ...props
}) => {
    const [isLocked, setIsLocked] = useState(true)

    const handleLockToggle = () => {
        if (canUnlock()) {
            setIsLocked((p) => !p)
        }
    }
    if (!isLocked) {
        warningSnackbar(warningMessage)
    }
    return (
        <TextField
            {...props}
            InputProps={{
                endAdornment: (
                    <IconButton onClick={handleLockToggle}>
                        <Iconify icon={isLocked ? "material-symbols:lock" : "material-symbols:lock-open-right"} />
                    </IconButton>
                ),
                readOnly: isLocked,
            }}
            disabled={isLocked}
        />
    )
}

export const SecureLockableTextField = withSecure(LockableTextField)
