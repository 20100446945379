import useSettings from "minimals-template/components/@hooks/useSettings"
import { Container } from "@mui/material"
import { useMobile } from "lib/@components/mobile"
import useIsXsScreen from "minimals-template/components/@hooks/useIsXsScreen"

export function StretchContainer({ tightWidth, ...props }) {
    const { themeStretch } = useSettings()
    const isMobile = useMobile()
    const isXsScreen = useIsXsScreen()

    return (
        <Container
            {...props}
            maxWidth={themeStretch ? false : tightWidth ?? "lg"}
            sx={{
                ...props.sx,
                overflowX: "hidden",
                height: 1,
                mx: isXsScreen ? "auto" : undefined,
                pl: isMobile ? 0 : undefined,
                pr: isMobile ? 0 : undefined,
            }}
        />
    )
}
