import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"

export const getAdoptClientRestriction = api(async function getAdoptClientRestriction(clientId) {
    return query(
        gql`
            query GetAdoptClientRestriction($clientId: String!) {
                getAdoptClientRestriction(clientId: $clientId) {
                    _id
                    start
                    end
                    reason
                    lastSessionCreatedBy
                    beingAdopted
                }
            }
        `,
        { clientId },
        { returns: "getAdoptClientRestriction" }
    )
})
