import useCollapseDrawer from "minimals-template/components/@hooks/useCollapseDrawer"
import { useCallback, useRef, useState } from "react"
import { Bound } from "lib/@components/binding/Bound"
import { Box } from "@mui/material"
import NavbarVertical from "routes/@layouts/app/navbar/NavbarVertical"
import { AfterMainStyle, AfterRoute, BeforeMainStyle, BeforeRoute } from "slot-definitions"
import { MainStyle } from "routes/@layouts/app/main-style"
import { ErrorBoundary } from "ErrorBoundary"
import { Outlet } from "react-router-dom"
import { Keyboard } from "@capacitor/keyboard"
import { createEvent } from "library/local-events"
import { createPortalPair } from "lib/portals"
import { AppDashboardHeader } from "routes/@layouts/app/header/app-dashboard-header"
import { isMobileApp } from "routes/@layouts/app/header/is-mobile-app"

// Add event listener for keyboard show

const AvailableHeight = createEvent("AvailableHeight")
let currentAvailableHeight = window.innerHeight
if (isMobileApp()) {
    Keyboard.setAccessoryBarVisible({ isVisible: true }).catch(console.error)
    Keyboard.addListener("keyboardWillShow", (info) => {
        const windowHeight = window.innerHeight
        const { keyboardHeight } = info
        const availableSpace = windowHeight - keyboardHeight
        currentAvailableHeight = availableSpace

        // Set the height of your div
        AvailableHeight.raise(availableSpace, true)
    })

    // Add event listener for keyboard hide
    Keyboard.addListener("keyboardWillHide", () => {
        // Reset the height of your div
        currentAvailableHeight = window.innerHeight
        AvailableHeight.raise(currentAvailableHeight, false)
    })
}

export const [ToolbarPortal, ToolbarUser] = createPortalPair("ToolbarMain")

export function AppLayout() {
    const { collapseClick } = useCollapseDrawer()
    const ref = useRef()
    console.log("In app layout")
    const [open, setOpen] = useState(false)
    const openSidebar = useCallback(() => setOpen(true), [])
    const closeSidebar = useCallback(() => setOpen(false), [])
    /* on or off */
    AvailableHeight.useRefresh()

    return (
        <Bound openSidebar={openSidebar} closeSidebar={openSidebar}>
            <Box position="absolute" height={0} overflow="visible" top={0} left={0} right={0}>
                <ToolbarPortal />
            </Box>
            <Box
                ref={ref}
                sx={{
                    flex: 1,
                    display: "flex",
                    height: "100%",
                    overflow: "auto",
                }}
                onFocus={scrollInput}
            >
                <AppDashboardHeader onOpenSidebar={openSidebar} />
                <NavbarVertical isOpenSidebar={open} onCloseSidebar={closeSidebar} />
                <BeforeMainStyle.Slot />
                <MainStyle collapseClick={collapseClick}>
                    <BeforeRoute.Slot />
                    <ErrorBoundary>
                        <Outlet />
                    </ErrorBoundary>
                    <AfterRoute.Slot />
                </MainStyle>
                <AfterMainStyle.Slot />
            </Box>
        </Bound>
    )

    function scrollInput({ target }) {
        target.scrollIntoView({ block: "center", behavior: "smooth" })
    }
}

AvailableHeight.handle((_, keyboardVisible) => {
    setTimeout(() => {
        const active = document.activeElement
        if (active && keyboardVisible) {
            active.scrollIntoView({ block: "center", behavior: "smooth" })
        }
    }, 300)
})
