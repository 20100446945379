import PropTypes from "prop-types"
// @mui
import { alpha, styled } from "@mui/material/styles"
import { Box } from "@mui/material"

// ----------------------------------------------------------------------
// scroll bar
// import "simplebar/src/simplebar.css"

const RootStyle = styled("div")(() => ({
    flexGrow: 1,
    height: "100%",
    overflow: "hidden",
}))

const SimpleBarStyle = styled(Box)(({ theme }) => ({
    overflow: "auto",
    maxHeight: "100%",
    "& .simplebar-scrollbar": {
        "&:before": {
            backgroundColor: alpha(theme.palette.grey[600], 0.48),
        },
        "&.simplebar-visible:before": {
            opacity: 1,
        },
    },
    "& .simplebar-track.simplebar-vertical": {
        width: 10,
    },
    "& .simplebar-track.simplebar-horizontal .simplebar-scrollbar": {
        height: 6,
    },
    "& .simplebar-mask": {
        zIndex: "inherit",
    },
}))

// ----------------------------------------------------------------------

Scrollbar.propTypes = {
    children: PropTypes.node.isRequired,
    sx: PropTypes.object,
}

export default function Scrollbar({ children, sx, ...other }) {
    const userAgent = typeof navigator === "undefined" ? "SSR" : navigator.userAgent

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)

    if (isMobile) {
        return (
            <Box sx={{ overflowX: "auto", ...sx }} {...other}>
                {children}
            </Box>
        )
    }

    return (
        <RootStyle>
            <SimpleBarStyle timeout={500} sx={sx} {...other}>
                {children}
            </SimpleBarStyle>
        </RootStyle>
    )
}
