import PropTypes from "prop-types"
// @mui
import { Box } from "@mui/material"

import { ReactComponent as LogoImage } from "routes/@assets/PRIMARY LOGO.svg"
import { navigate } from "lib/routes/navigate"

// ----------------------------------------------------------------------

Logo.propTypes = {
    sx: PropTypes.object,
}

export default function Logo({ sx = { width: 80 }, href }) {
    const logo = (
        <Box sx={{ ...sx }} onClick={go}>
            <LogoImage width={sx.width} height={sx.height} />
        </Box>
    )

    return logo

    function go() {
        if (href) {
            navigate(href)
        }
    }
}
