import React from "react"

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false, error: null, errorInfo: null }
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error }
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ error, errorInfo })
    }

    render() {
        if (this.state.hasError) {
            if (window.Cypress) {
                return (
                    <div>
                        <h1>Something went wrong.</h1>
                        <details style={{ whiteSpace: "pre-wrap" }}>
                            {this.state.error?.toString()}
                            <br />
                            {this.state.errorInfo?.componentStack}
                        </details>
                    </div>
                )
            }
        }
        return this.props.children
    }
}
