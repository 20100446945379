const Events = require("library/events")
const { generate } = require("library/guid")

const { emit } = Events.prototype
const { emitAsync } = Events.prototype

class HookedEvents extends Events {
    constructor(props = {}) {
        props.maxListeners = 1000000000
        props.wildcard = props.wildcard !== false
        super(props)
    }

    async emitAsync(...parms) {
        const context = {
            cancel: false,
            preventDefault() {
                context.cancel = true
            },
        }
        const delim = this.delimiter || ":"
        const eventName = parms[0]
        parms.splice(1, 0, context)
        parms[0] = `before${delim}${eventName}`
        await emitAsync.apply(this, parms)
        if (context.cancel) return false
        parms[0] = eventName
        await emitAsync.apply(this, parms)
        if (context.cancel) return false
        parms[0] = `after${delim}${eventName}`
        await emitAsync.apply(this, parms)
        if (context.cancel) return false
        return true
    }

    async emitAsyncExtended(...parms) {
        const context = {
            cancel: false,
            preventDefault() {
                context.cancel = true
            },
        }
        const delim = this.delimiter || ":"
        const eventName = parms[0]
        parms.splice(1, 0, context)
        parms[0] = `early${delim}${eventName}`
        await emitAsync.apply(this, parms)
        if (context.cancel) return false
        parms[0] = `before${delim}${eventName}`
        await emitAsync.apply(this, parms)

        if (context.cancel) return false
        parms[0] = eventName
        await emitAsync.apply(this, parms)
        if (context.cancel) return false
        parms[0] = `after${delim}${eventName}`
        await emitAsync.apply(this, parms)
        if (context.cancel) return false
        parms[0] = `late${delim}${eventName}`
        await emitAsync.apply(this, parms)
        return !context.cancel
    }

    emit(...parms) {
        const context = {
            id: generate(),
            parameters: parms,
            cancel: false,
            preventDefault() {
                context.cancel = true
            },
        }
        const delim = this.delimiter || ":"
        const eventName = parms[0]
        parms.splice(1, 0, context)
        parms[0] = `before${delim}${eventName}`
        emit.apply(this, parms)
        if (context.cancel) return false
        parms[0] = eventName
        emit.apply(this, parms)
        if (context.cancel) return false
        parms[0] = `after${delim}${eventName}`
        emit.apply(this, parms)
        return !context.cancel
    }

    emitExtended(...parms) {
        const context = {
            id: generate(),
            parameters: parms,
            cancel: false,
            preventDefault() {
                context.cancel = true
            },
        }
        const delim = this.delimiter || ":"
        const eventName = parms[0]
        parms.splice(1, 0, context)
        parms[0] = `early${delim}${eventName}`
        emit.apply(this, parms)
        if (context.cancel) return false
        parms[0] = `before${delim}${eventName}`
        emit.apply(this, parms)
        if (context.cancel) return false
        parms[0] = eventName
        emit.apply(this, parms)
        if (context.cancel) return false
        parms[0] = `after${delim}${eventName}`
        emit.apply(this, parms)
        if (context.cancel) return false
        parms[0] = `late${delim}${eventName}`
        emit.apply(this, parms)
        return !context.cancel
    }

    bindEvent(event, async) {
        return (...parameters) => {
            if (async) {
                emitAsync(event, ...parameters)
            } else {
                emit(event, ...parameters)
            }
        }
    }

    return(event, fn) {
        if (!fn) {
            return (fn) => {
                if (typeof fn !== "function") {
                    const v = fn
                    fn = () => v
                }
                this.on(event, eventUsingParameter(fn))
            }
        }
        this.on(event, eventUsingParameter(fn))
        return undefined
    }

    returnAsync(event, fn) {
        if (!fn) {
            return (fn) => {
                if (typeof fn !== "function") {
                    const v = fn
                    fn = () => Promise.resolve(v)
                }
                this.on(event, asyncEventUsingParameter(fn))
            }
        }
        this.on(event, asyncEventUsingParameter(fn))
        return undefined
    }

    modify(event, modify, ...params) {
        if (!modify) {
            return (modify) => modifyValueUsingEvent(event, modify, this, ...params)
        }
        return modifyValueUsingEvent(event, modify, this, ...params)
    }

    async modifyAsync(event, modify, ...params) {
        if (!modify) {
            return async (modify) => modifyValueUsingEventAsync(event, modify, this, ...params)
        }
        return modifyValueUsingEventAsync(event, modify, this, ...params)
    }

    onAll(events, fn) {
        if (typeof events === "string")
            events = events
                .split(" ")
                .map((e) => e.trim())
                .filter((f) => !!f)
        for (const type of events) {
            this.on(type, fn)
        }
    }

    offAll(events, fn) {
        if (typeof events === "string")
            events = events
                .split(" ")
                .map((e) => e.trim())
                .filter((f) => !!f)
        for (const type of events) {
            this.off(type, fn)
        }
    }

    use(handler) {
        for (const [event, fn] of methods(handler)) {
            this.on(clean(event), fn)
        }
    }

    discard(handler) {
        for (const [event, fn] of methods(handler)) {
            this.off(clean(event), fn)
        }
    }
}

function clean(name) {
    return name.replace(/_/g, ".").replace(/\$/g, "*")
}

function methods(klass) {
    const properties = []
    for (const item of Object.getOwnPropertyNames(klass)) {
        if (typeof klass[item] === "function") {
            properties.push([item, klass[item]])
        }
    }
    return properties
}

function processResult(fn, resultObject, result) {
    if (fn.length >= 1) {
        resultObject.parameters = result || resultObject.parameters
    } else if (result) {
        if (Array.isArray(resultObject.parameters)) {
            if (Array.isArray(result)) {
                // eslint-disable-next-line prefer-spread
                resultObject.parameters.push.apply(resultObject.parameters, result)
            } else {
                resultObject.parameters.push(result)
            }
        } else if (typeof resultObject.parameters === "object" && !!resultObject.parameters) {
            Object.assign(resultObject.parameters, result)
        } else {
            resultObject.parameters = result || resultObject.parameters
        }
    }
    resultObject.updated = (resultObject.updated || 0) + 1
}

function eventUsingParameter(fn) {
    return function handle(event, resultObject) {
        const result = fn(resultObject.parameters, resultObject.updated)
        processResult(fn, resultObject, result)
    }
}

function asyncEventUsingParameter(fn) {
    return async function asyncHandle(event, resultObject) {
        const result = await fn(resultObject.parameters, resultObject.updated)
        processResult(fn, resultObject, result)
    }
}

function modifyValueUsingEvent(event, parameters, eventEmitter, ...params) {
    const resultObject = { parameters }
    eventEmitter.emit(event, resultObject, ...params)
    return resultObject.parameters
}

async function modifyValueUsingEventAsync(event, parameters, eventEmitter, ...params) {
    const resultObject = { parameters }
    await eventEmitter.emitAsync(event, resultObject, ...params)
    return resultObject.parameters
}

module.exports = HookedEvents
