const { nanoid } = require("nanoid")
const { v5 } = require("uuid")

const NAMESPACE = "1d671a64-4dd5-491e-93b0-da01ff1f3341"

function generate(size = 14) {
    let id = nanoid(size)
    if (/^[^a-zA-Z$_]/g.test(id)) {
        id = "_" + id
    }
    return nanoid(size).replace(/-/g, "__")
}

function deriveGuidFrom(...data) {
    return v5(JSON.stringify(data), NAMESPACE)
}

module.exports = { generate, deriveGuidFrom }
