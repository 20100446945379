import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export const getNewStripeSession = api(async function getNewStripeSession(priceId, recurring, userId) {
    return query(
        gql`
            query GetNewStripeSession($priceId: String!, $recurring: Boolean!, $userId: String!) {
                getNewStripeSession(priceId: $priceId, recurring: $recurring, userId: $userId)
            }
        `,
        { priceId, recurring, userId },
        { returns: "getNewStripeSession" }
    )
})
