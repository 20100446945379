import { FormControlLabel, MenuItem, Select, Typography } from "@mui/material"
import { setFromEvent } from "lib/setFromEvent"
import { defaultNumberOfRecords } from "lib/@components/pagination/default-number-of-records"

export function PageSize({ take, setTake }) {
    return (
        <FormControlLabel
            label={<Typography variant="body2">Rows per page: </Typography>}
            labelPlacement="start"
            sx={{ pb: 1 }}
            control={
                <Select
                    sx={{ mr: 2, ml: 2, fontSize: 13 }}
                    variant="standard"
                    data-cy="pagination-rows-per-page"
                    labelId="rows-per-page-label"
                    value={take}
                    onChange={setFromEvent(setTake)}
                >
                    {defaultNumberOfRecords.map(rows)}
                </Select>
            }
        />
    )

    function rows(rowCountValue) {
        return (
            <MenuItem key={rowCountValue} value={rowCountValue}>
                {rowCountValue}
            </MenuItem>
        )
    }
}
