import { yupResolver } from "@hookform/resolvers/yup"
import { LoadingButton } from "@mui/lab"
import { Box, Link, Stack, Typography } from "@mui/material"
import { convertToLowercaseEmail } from "lib/case-helper"
import { RouterLink } from "lib/routes/router-link"
import useAuth from "minimals-template/components/@hooks/useAuth"
import { FormProvider, RHFTextField } from "minimals-template/components/hook-form"

import AuthTemplate from "minimals-template/pages/auth/AuthTemplate"
import { useForm } from "react-hook-form"

import { PATH_AUTH } from "routes/paths"

import * as yup from "yup"
import PrivacyAndTerms from "./PrivacyAndTerms"

import logo from "routes/@assets/PRIMARY LOGO _ LOCKUP _ REVERSED@3x.png"
import { useSearchParam } from "lib/@hooks/use-search-param"
import { useNavigate, useSearchParams } from "react-router-dom"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import { infoSnackbar } from "lib/snackbar/info-snackbar"

const schema = yup.object().shape({
    email: yup.string().required().email(),
})

export default function PasswordlessLogin() {
    const { login } = useAuth()
    const [searchParams] = useSearchParams()
    const [requiredClient] = useSearchParam("requiredClient")

    let email = searchParams.get("email")
    email = email ? decodeURIComponent(email) : ""

    const navigate = useNavigate()
    const defaultValues = {
        email: email || "",
    }

    const methods = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur",
        resolver: yupResolver(schema),
        defaultValues,
    })

    const { handleSubmit } = methods

    const onSubmit = async (data) => {
        let { email } = data
        try {
            // searchParams.delete("email")
            // searchParams.delete("requiredClient")
            await login(email, requiredClient, window.location.href)
        } catch (error) {
            if (error?.status === 401) {
                navigate(
                    `/register?email=${encodeURIComponent(
                        email
                    )}&requiredClient=${requiredClient}&${searchParams.toString()}`
                )
                infoSnackbar("Welcome! We couldn't find your email address, do you need to register?")
            } else {
                errorSnackbar("We were unable to send you a login link.")
            }
        }
    }

    return (
        <AuthTemplate
            pageTitle={"Login"}
            pageImage={logo}
            headerComponent={
                <Typography variant="body2" align="center" sx={{ mt: { md: -2 } }}>
                    Don’t have an account?{" "}
                    <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                        Get started
                    </Link>
                </Typography>
            }
        >
            <Stack>
                <Typography variant="h4">Sign in to SFG20</Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    Please enter your email below and we will send you a passcode to sign in.
                </Typography>
                <Box sx={{ mt: 2 }}>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={2}>
                            <RHFTextField
                                transformOut={convertToLowercaseEmail}
                                name="email"
                                label="* Email address"
                                defaultValue={defaultValues.email}
                            />

                            <LoadingButton fullWidth size="large" type="submit" variant="contained">
                                Send Link
                            </LoadingButton>
                        </Stack>
                    </FormProvider>
                </Box>
                <PrivacyAndTerms />
            </Stack>
        </AuthTemplate>
    )
}
