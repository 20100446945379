import { createContext, useCallback, useEffect, useMemo, useRef, useState } from "react"
// hooks
import useResponsive from "minimals-template/components/@hooks/useResponsive"
import { createEvent } from "library/local-events"
import { useReferenceState } from "lib/@hooks/use-reference"

// ----------------------------------------------------------------------

const initialState = {
    isCollapse: false,
    collapseClick: false,
    collapseHover: false,
    onToggleCollapse: () => {},
    onCloseCollapse: () => {},
    onHoverEnter: () => {},
    onHoverLeave: () => {},
}

const CollapseDrawerContext = createContext(initialState)

export const CollapseRefresh = createEvent("CollapseRefresh")

// ----------------------------------------------------------------------

function CollapseDrawerProvider({ children }) {
    const isDesktop = useResponsive("up", "lg")
    const [startupCollapse, setStartupCollapse] = useReferenceState("collapse")

    const [collapse] = useState({
        click: startupCollapse,
        hover: false,
    })

    useEffect(() => {
        if (!isDesktop) {
            collapse.click = false
            collapse.hover = false
            update()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDesktop])

    const handleToggleCollapse = useCallback(() => {
        collapse.click = !collapse.click
        setStartupCollapse(collapse.click)
        collapse.hover = false
        update()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const lastClick = useRef(collapse.click)

    const handleCloseCollapse = useCallback(() => {
        collapse.click = true
        collapse.hover = false
        update()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleHoverEnter = useCallback(() => {
        if (collapse.click) {
            collapse.hover = true
            update()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleHoverLeave = useCallback(() => {
        collapse.hover = false
        update()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const context = useMemo(
        () => ({
            isCollapse: collapse.click && !collapse.hover,
            collapseClick: collapse.click,
            collapseHover: collapse.hover,
            onToggleCollapse: handleToggleCollapse,
            onCloseCollapse: handleCloseCollapse,
            onHoverEnter: handleHoverEnter,
            onHoverLeave: handleHoverLeave,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )
    useEffect(update, [collapse.click, collapse.hover, context])
    return <CollapseDrawerContext.Provider value={context}>{children}</CollapseDrawerContext.Provider>

    function update() {
        if (collapse.click !== lastClick.current || collapse.click) {
            context.isCollapse = collapse.click && !collapse.hover
            context.collapseClick = collapse.click
            context.collapseHover = collapse.click
            lastClick.current = collapse.click
            CollapseRefresh.raiseOnce(context)
        }
    }
}

export { CollapseDrawerProvider, CollapseDrawerContext }
