import { formatDistance, formatDistanceStrict } from "date-fns"

export function formatDuration(s) {
    if (!s) return ""
    return formatDistance(0, s * 1000, { includeSeconds: true })
}

export function formatDurationStrict(s) {
    if (!s) return ""
    return formatDistanceStrict(0, s * 1000, { includeSeconds: true })
}
