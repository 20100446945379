import { authLink } from "lib/graphql/auth-link"
import { httpLink } from "lib/graphql/http-link"
import { addDebugItem } from "debug-window"
import { ApolloLink } from "@apollo/client"

const logging = new ApolloLink((operation, forward) =>
    forward(operation).map((data) => {
        addDebugItem(
            // `GQL: ${operation.operationName} (${JSON.stringify(operation.variables).slice(0, 60)}) = ${
            //     typeof data === "object" ? JSON.stringify(data).slice(0, 40) : data.toString().slice(0, 25)
            // }`
            `GQL: ${operation.operationName}`
        )
        return data
    })
)
export const requestLink = ApolloLink.from([logging, authLink.concat(httpLink)])
