import { useTranslation } from "react-i18next"
import useSettings from "minimals-template/components/@hooks/useSettings"
// config
import { allLangs, defaultLang } from "config"

// ----------------------------------------------------------------------

export default function useLocales() {
    const { i18n, t: translate } = useTranslation()

    const { onChangeDirectionByLang } = useSettings()

    const langStorage = localStorage.getItem("i18nextLng")

    const currentLang = allLangs.find((_lang) => _lang.value === langStorage) || defaultLang

    const handleChangeLanguage = async (newlang) => {
        await i18n.changeLanguage(newlang)
        onChangeDirectionByLang(newlang)
    }

    return {
        onChangeLang: handleChangeLanguage,
        translate: (text, options) => {
            if (typeof text === "object") return text
            return translate(text, options)
        },
        currentLang,
        allLangs,
    }
}
