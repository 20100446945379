import { Box, Card } from "@mui/material"
import { useTheme } from "@mui/styles"

const LicenseCard = ({ borderColor, children, selected = false }) => {
    const theme = useTheme()
    const selectColor = theme.palette.license.subscription

    return (
        <Card
            sx={{
                borderRadius: "16px",
                textAlign: "left",
                outline: selected ? `5px solid  ${selectColor}` : "initial",
                outlineOffset: selected ? "-5px" : "initial",
                borderTop: "none",
                height: "100%",
                boxShadow: (theme) => theme.customShadows.lighterDialog,
            }}
        >
            <div
                style={{
                    backgroundColor: borderColor,
                    borderRadius: "16px 16px 0px 0px",
                    height: "14px",
                    position: "relative",
                    width: "100%",
                }}
            />
            <Box
                sx={{
                    p: 3,
                }}
            >
                {children}
            </Box>
        </Card>
    )
}

export default LicenseCard
