import { forwardRef, useEffect, useRef } from "react"
import { Box } from "@mui/material"

export const BlockKeyboard = forwardRef(function BlockKeyboard({ children, sx, all, ...props }, outerRef) {
    const ref = useRef()
    useEffect(() => {
        if (typeof outerRef === "function") {
            outerRef(ref.current)
        } else if (outerRef) {
            outerRef.current = ref.current
        }
    }, [outerRef])
    return (
        <Box
            {...props}
            sx={{ ...props.sx, display: "flex", ...sx }}
            ref={ref}
            onKeyUp={blockIfNotChild}
            onKeyDown={blockIfNotChild}
            onKeyDownCapture={blockAll}
            onKeyUpCapture={blockAll}
        >
            {children}
        </Box>
    )

    function blockAll(event) {
        if (all) {
            event.preventDefault()
            event.stopPropagation()
        }
    }

    function blockIfNotChild(event) {
        if (all) {
            event.preventDefault()
            event.stopPropagation()
            return
        }
        if (!ref.current.contains(event.target)) {
            event.preventDefault()
        }
        event.stopPropagation()
    }
})

export const BlockClicks = forwardRef(function BlockClicks({ children, sx, all, noCapture, ...props }, outerRef) {
    const ref = useRef()
    useEffect(() => {
        if (typeof outerRef === "function") {
            outerRef(ref.current)
        } else if (outerRef) {
            outerRef.current = ref.current
        }
    }, [outerRef, ref])
    if (noCapture) {
        return (
            <Box
                {...props}
                sx={{ ...props.sx, display: "flex", ...sx }}
                ref={ref}
                onMouseDown={blockIfNotChild}
                onMouseUp={blockIfNotChild}
                onPointerDown={blockIfNotChild}
                onPointerUp={blockIfNotChild}
                onClick={blockIfNotChild}
                onDoubleClick={blockIfNotChild}
                onKeyUp={blockIfNotChild}
                onKeyDown={blockIfNotChild}
            >
                {children}
            </Box>
        )
    }
    return (
        <Box
            {...props}
            sx={{ ...props.sx, display: "flex", ...sx }}
            ref={ref}
            onPointerDownCapture={blockAll}
            onPointerUpCapture={blockAll}
            onDoubleClickCapture={blockAll}
            onClickCapture={blockAll}
            onMouseDown={blockIfNotChild}
            onMouseUp={blockIfNotChild}
            onPointerDown={blockIfNotChild}
            onPointerUp={blockIfNotChild}
            onClick={blockIfNotChild}
            onDoubleClick={blockIfNotChild}
            onKeyUp={blockIfNotChild}
            onKeyDown={blockIfNotChild}
            onKeyDownCapture={blockAll}
            onKeyUpCapture={blockAll}
        >
            {children}
        </Box>
    )

    function blockAll(event) {
        if (all) {
            event.preventDefault()
            event.stopPropagation()
        }
    }

    function blockIfNotChild(event) {
        if (all) {
            event.preventDefault()
            event.stopPropagation()
            return
        }
        if (!ref.current.contains(event.target)) {
            event.preventDefault()
        }
        event.stopPropagation()
    }
})
