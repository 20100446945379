import { Breadcrumbs } from "@mui/material"
import { ErrorBoundary } from "ErrorBoundary"
import { useMobile } from "lib/@components/mobile"
import Iconify from "minimals-template/components/Iconify"

export function StandardBreadcrumbs({ children, sx }) {
    const isMobile = useMobile()
    return (
        <ErrorBoundary>
            <Breadcrumbs
                data-cy="standard-breadcrumbs"
                maxItems={isMobile ? 2 : 8}
                sx={{
                    ml: 2,
                    mr: 1,
                    fontSize: 13,
                    "& li[aria-hidden]": {
                        ml: 1,
                        mr: 1,
                    },
                    ...sx,
                }}
                separator={<Iconify icon="ic:baseline-circle" sx={{ height: 4, width: 4, color: "grey.500" }} />}
            >
                {children}
            </Breadcrumbs>
        </ErrorBoundary>
    )
}
