import { ignoreTags, insertIgnoredTags, tagsToIgnore } from "./ignoreTags"

export function color(color) {
    return `style='color:${color}'`
}
function normalizeHtml(htmlString) {
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlString, "text/html")
    // You can add more manipulations here if needed
    // For example, you can remove empty text nodes, standardize attribute quotes, etc.

    const serializer = new XMLSerializer()
    return serializer.serializeToString(doc)
}
export function createHTMLComparisonArray(html) {
    html = normalizeHtml(html)

    const { ignoredTags, html: strippedHtml } = ignoreTags(html, tagsToIgnore)

    html = strippedHtml
        .replaceAll("&nbsp;", " ")
        .replace(/([!?.:;,])(?=[^\s])/g, "$1 ")
        .replace(/\s+/gi, " ")

    Object.keys(ignoredTags).forEach((key) => {
        html = insertIgnoredTags(key, ignoredTags[key], html)
    })

    const output = []
    const div = document.createElement("div")
    div.innerHTML = html
    recurseAdd(div)

    return output

    function recurseAdd(element) {
        if (element instanceof Text) {
            output.push(...(element.nodeValue ?? "").split(" "))
        } else {
            output.push(makeStartTag(element))
            for (const child of element.childNodes) {
                recurseAdd(child)
            }
            output.push(makeEndTag(element))
        }
    }
}

function makeStartTag(element) {
    return `<${element.tagName} ${getAttributesFor(element)}>`
}

function getAttributesFor(element) {
    if (!element) return ""
    const output = []
    try {
        for (const attr of [...element.attributes].sort()) {
            output.push(`${attr.name}="${attr.value.replaceAll('"', "&quot;").replaceAll("'", "&apos;")}"`)
        }
        return output.join(" ")
    } catch (e) {
        return ""
    }
}

function makeEndTag(element) {
    return `</${element.tagName}>`
}

function whenNotATag(content, skip) {
    return (c) => {
        if (c.match(/<[^>]+>/g)) {
            if (skip) return c
            return `${c.slice(0, -1)} class="text-difference">`
        }
        return content.replace(/INSERTHERE/g, c)
    }
}

export function assembleBuffer(buffer, content, skip) {
    return buffer.map(whenNotATag(content, skip)).join(" ")
}
