import { mutate } from "lib/graphql/mutate"
import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"

export const relinquishAdoptedClient = api(async function () {
    return mutate(
        gql`
            mutation relinquishAdoptedClient {
                relinquishAdoptedClient {
                    status
                    message
                }
            }
        `,
        {},
        { returns: "relinquishAdoptedClient" }
    )
})
