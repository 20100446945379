export const EDITABLE_STYLE = {
    borderRadius: 1,
    border: "1px solid",
    borderColor: (theme) => theme.palette.divider,
    transition: "border-color 0.1s linear",
    "&:hover": {
        borderColor: (theme) => theme.palette.action.active,
    },
    "&:focus-within": {
        borderColor: "primary.main",
        outlineWidth: 1.5,
        outlineStyle: "solid",
        outlineColor: (theme) => theme.palette.primary.main,
    },
    "& *:focus-visible": {
        outline: "none",
    },
    pt: 1,
    pb: 1,
    pl: 1,
    pr: 1,
}
