import { api } from "lib/graphql/api"
import { mutate } from "lib/graphql/mutate"
import { gql } from "@apollo/client"

export const setActiveUserClient = api(async function setActiveUserClient(clientId) {
    return mutate(
        gql`
            mutation SetActiveUserClient($clientId: String!) {
                setActiveUserClient(clientId: $clientId)
            }
        `,
        { clientId },
        { returns: "setActiveUserClient" }
    )
})

export const resetActiveUserClient = api(async function resetActiveUserClient() {
    return mutate(
        gql`
            mutation ResetActiveUserClient {
                resetActiveUserClient
            }
        `,
        {},
        { returns: "resetActiveUserClient" }
    )
})
