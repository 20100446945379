import { Box } from "@mui/material"
import AppWelcome from "minimals-template/pages/sections/@dashboard/general/app/AppWelcome"
import Iconify from "minimals-template/components/Iconify"
import { CreateTagWithItems } from "./create-tag/create-tag-with-items"
import { ListItemBox } from "lib/@components/ListItemBox"

export function TagInfo() {
    return (
        <Box flexGrow={1} mb={3} p={2}>
            <AppWelcome
                title={"Tags"}
                img={
                    <Box position="relative" overflow="hidden" flexGrow={1} width={150} height={160}>
                        <Box position="absolute" right={20} zIndex={200} opacity={0.5}>
                            <Iconify
                                sx={{
                                    width: 90,
                                    height: 90,
                                    opacity: 0.3,
                                    mr: 3,
                                    transform: "rotate(15deg) scale(1.7) translateY(25px)",
                                    color: "primary.dark",
                                }}
                                icon="bi:tags-fill"
                            />
                        </Box>
                    </Box>
                }
                description={
                    <>
                        <Box>
                            You can set tags to help you identify and find schedules. Tags appear in all of the list
                            views. You can also make selections by combining tags to quickly include or exclude groups
                            of schedules from the current selection.
                        </Box>
                        <Box mt={1}>Tags are visible to all users in your organisation.</Box>
                        <Box mt={1}>
                            You can set tags on an individual schedule or you can add or remove the currently selected
                            schedules to batch up your work.
                        </Box>
                    </>
                }
                action={
                    <ListItemBox spacing={1}>
                        <CreateTagWithItems />
                    </ListItemBox>
                }
            />
        </Box>
    )
}
