import { Bound } from "lib/@components/binding/Bound"
import noop from "lib/noop"
import { startTransition, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { debounce } from "lib/debounce"

export function Refresh({ children, ...props }) {
    const refresh = useRefresh()
    return (
        <Bound refresh={refresh} {...props}>
            {children}
        </Bound>
    )
}

export function inTransition() {
    return inTransition
}

export function noChange() {
    return noChange
}

export function useRefresh(...functions) {
    const context = useBoundContext()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const changeIfNecessary = useCallback(context.onChange, [])
    if (context && context.onChange && !functions.includes(noChange)) {
        if (!functions.includes(context.onChange)) {
            functions.push(changeIfNecessary)
        }
    }
    const returnId = useRef(0)
    const [id, refresh] = useState(0)
    const mounted = useRef(true)
    useEffect(() => {
        mounted.current = true
        return () => {
            mounted.current = false
        }
    }, [])
    const refreshFunction = useMemo(
        () => {
            const refresher = (...params) => {
                const shouldChange = !params.includes(noChange)
                for (const fn of functions) {
                    if (!shouldChange && fn === changeIfNecessary) continue
                    if (fn) {
                        fn(...params)
                    }
                }
                if (functions.includes(inTransition) && mounted.current) {
                    startTransition(() => {
                        refresh((i) => i + 1)
                    })
                    returnId.current += 1
                } else if (mounted.current) {
                    returnId.current += 1
                    refresh((i) => i + 1)
                }
                return noop
            }
            refresher.debounced = debounce(refresher, 100)
            return refresher
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [functions.length, functions.map((f) => f?.name ?? "unknown").join(".")]
    )
    refreshFunction.id = returnId.current + id
    refreshFunction.functions = functions
    refreshFunction.later = (...params) => setTimeout(() => refreshFunction(...params))
    refreshFunction.inTransition = (...params) => startTransition(() => refreshFunction(...params))
    return refreshFunction
}
